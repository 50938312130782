import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import Pagination from '../../../components/pagination/Pagination';
import ConfirmationDialog from '../../../components/confirmation/ConfirmationDialog';
import { axiosInstance, createCancelTokenSource, DepenseNature, DepenseRubrique } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
import useDebounce from '../../../hooks/useDebounce';

const Natures = () => {
    const { authData } = useContext(AuthContext);
    const [rubriques, setRubriques] = useState([]);
    const [natures, setNatures] = useState([]);
    const [filtredRubriques, setFiltredRubriques] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [idRubriqueDelete, setIdRubriqueDelete] = useState('');


    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredRubriques].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredRubriques(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = rubriques.slice(startIndex, endIndex);

        setFiltredRubriques(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };


    //Ajouter une sous-catégorie
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        getNatures();
    };

    const formik1 = useFormik({
        initialValues: {
            idNature: '',
            libelle: '',
            codeComptable: '',
            statut: 'actif'
        },
        validationSchema: Yup.object({
            idNature: Yup.string()
                .required('Champ obligatoire'),
            libelle: Yup.string()
                .required('Champ obligatoire'),
            codeComptable: Yup.string()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idNature: values.idNature,
                    libelle: values.libelle,
                    statut: values.statut,
                    codeComptable: values.codeComptable,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(values)
                const response = await axiosInstance.post(DepenseRubrique, postData);
                CustomToast("La nature a été créée avec succès", 'success');
                getRubriques(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une rubrique avec le même nom existe déjà.") {
                    CustomToast("Une nature avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier une sous-catégorie
    const showHideUpdateModal = (val, idrubrique, idNature, libelle, statut, codeComptable) => {
        setShowUpdateModal(val);
        //formik2.resetForm();
        if (val) {
            getNatures();
            formik2.setValues({
                idrubrique: idrubrique,
                idNatureUpdate: idNature,
                libelleUpdate: libelle,
                codeComptableUpdate: codeComptable,
                statutUpdate: statut || 'actif',
            });
        }
    };

    const formik2 = useFormik({
        initialValues: {
            idRubrique: '',
            idNatureUpdate: '',
            libelleUpdate: '',
            codeComptableUpdate: '',
            statutUpdate: 'actif'
        },
        validationSchema: Yup.object({
            idNatureUpdate: Yup.string()
                .required('Champ obligatoire'),
            libelleUpdate: Yup.string()
                .required('Champ obligatoire'),
            codeComptableUpdate: Yup.string()
                .required('Champ obligatoire'),
            statutUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idrubrique: values.idRubrique,
                    idNature: values.idNatureUpdate,
                    libelle: values.libelleUpdate,
                    statut: values.statutUpdate,
                    codeComptable: values.codeComptableUpdate,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(postData);
                const response = await axiosInstance.put(DepenseRubrique, postData);
                CustomToast("La nature de dépense a été modifiée avec succès", 'success');
                getRubriques(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une rubrique avec le même nom existe déjà.") {
                    CustomToast("Une nature avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "La rubrique à mettre à jour n'existe pas.") {
                    CustomToast("La nature à mettre à jour n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer une sous-catégorie
    const handleConfirmDelete = (idRubrique) => {
        //console.log(idRubrique)
        setIdRubriqueDelete(idRubrique);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            //console.log(idRubriqueDelete)
            const response = await axiosInstance.delete(`${DepenseRubrique}/${idRubriqueDelete}`);
            setIdRubriqueDelete('');
            CustomToast("La nature de dépenses a été supprimée avec succès", 'success');
            getRubriques(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 409 && error.response?.data === "Rubrique utilisée") {
                CustomToast("nature utilisée en cours d'utilisation.", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("nature introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getRubriques = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${DepenseRubrique}/GetListeRubriquesDepenses?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setRubriques(resp.data);
            setFiltredRubriques(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getNatures = async () => {
        try {
            const response = await axiosInstance.get(`${DepenseNature}`);
            const resp = await response.data;
            setNatures(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getRubriques(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getRubriques(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getRubriques(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);
    return (
        <>
            <div className="container affectation-colis-section">
                <div className="affectation-sec wrap-all">
                    <Link to="#" className="affectation-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>Ajouter une nature
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>Voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <input
                                type="search"
                                value={search}
                                placeholder="Rechercher ..."
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredRubriques?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>Rubrique</th>
                                    <th>Nature</th>
                                    <th>Code comptable</th>
                                    <th>Statut</th>
                                    <th>Modifier</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredRubriques?.map((row) => (
                                    <tr key={row.idRubrique}>
                                        <td>{row.libelleNature}</td>
                                        <td>{row.libelleRubrique}</td>
                                        <td>{row.codeComptable}</td>
                                        <td>{
                                            row.statutRubrique === "actif" ? <>Actif</> :
                                                row.statutRubrique === "inactif" ? <>Inactif</> :
                                                    null
                                        }</td>
                                        <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idRubrique, row.idNature, row.libelleRubrique, row.statutRubrique)}>
                                            <span className="material-symbols-outlined">edit
                                            </span></td>
                                        <td className="txt-center danger" onClick={() => handleConfirmDelete(row.idRubrique)}>
                                            <span className="material-symbols-outlined">delete
                                            </span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {filtredRubriques?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>

            {showAddModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label >Rubrique<span className='requiredField'>*</span></label>
                                        <select
                                            id="idNature"
                                            name="idNature"
                                            value={formik1.values.idNature}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.idNature && formik1.errors.idNature) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {natures.map((nature) => (
                                                <option key={nature.idNature} value={nature.idNature}>{nature.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idNature && formik1.errors.idNature ? (
                                            <div className="invalid-feedback">{formik1.errors.idNature}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelle"
                                            name="libelle"
                                            style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.libelle}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Code comptable<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="codeComptable"
                                            name="codeComptable"
                                            style={(formik1.touched.codeComptable && formik1.errors.codeComptable) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.codeComptable}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.codeComptable && formik1.errors.codeComptable ? (
                                            <div className="invalid-feedback">{formik1.errors.codeComptable}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {showUpdateModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label >Rubrique<span className='requiredField'>*</span></label>
                                        <select
                                            id="idNatureUpdate"
                                            name="idNatureUpdate"
                                            value={formik2.values.idNatureUpdate}
                                            onChange={formik2.handleChange}
                                            style={(formik2.touched.idNatureUpdate && formik2.errors.idNatureUpdate) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {natures.map((nature) => (
                                                <option key={nature.idNature} value={nature.idNature}>{nature.libelle}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idNatureUpdate && formik2.errors.idNatureUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idNatureUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelleUpdate"
                                            name="libelleUpdate"
                                            style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.libelleUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Code comptable<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="codeComptableUpdate"
                                            name="codeComptableUpdate"
                                            style={(formik2.touched.codeComptableUpdate && formik2.errors.codeComptableUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.codeComptableUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.codeComptableUpdate && formik2.errors.codeComptableUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.codeComptableUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer cette nature de dépenses ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer la nature de dépense"
            />

        </>
    );
}

export default Natures;
