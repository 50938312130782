import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { CustomToast } from '../Toast/CustomToast';
import { AuthContext } from '../../contexts/AuthContext';

const Layout = () => {
    const { authData, logout } = useContext(AuthContext);
    const [menuActif, setMenuActif] = useState({ menu: '', sousMenu: '' });

    const hasRole = useCallback((roles) => {
        return roles.includes(authData?.role);
    }, [authData]); 

    const updateSubMenuState = useCallback((menu, sousMenu) => {
        if (menu === sousMenu && menuActif.menu === menu) {
            setMenuActif({ menu: '', sousMenu: '' });
        } else {
            setMenuActif({ menu, sousMenu });
        }
        localStorage.setItem('menuShow', JSON.stringify({ menu, sousMenu }));
    }, [menuActif]);

    const handleLogOut = useCallback(() => {
        localStorage.removeItem('authData');
        localStorage.removeItem('menuShow');
        CustomToast(`Déconnexion réussie`, 'success');
    }, []);

    useEffect(() => {
        setMenuActif(JSON.parse(localStorage.getItem("menuShow") || '{"menu": "", "sousMenu": ""}'));
    }, []);

    const menuItems = useMemo(() => [
        {
            roles: ["SuperAdmin", "Admin"],
            menu: 'dashboard',
            label: 'Dashboard',
            icon: 'dashboard',
            subMenus: [],
            className: 'overview'
        },
        {
            roles: ["SuperAdmin", "Admin", "Manager", "ResponsableRegional", "DirecteurRegional", "ChefStation", "Financier", "Caissier", "Employe", "AgentSuivi", "Approbateur", "AssistanteDirection"],
            menu: 'cashManagement',
            label: 'Cash management',
            icon: 'card_travel',
            subMenus: [
                { sousMenu: 'depenses', label: 'Dépenses', icon: 'receipt_long', link: '/depenses', className: 'depense', roles: ["SuperAdmin", "Admin", "Manager", "ResponsableRegional", "DirecteurRegional", "ChefStation", "Financier", "Caissier", "AgentSuivi", "Approbateur", "AssistanteDirection"] },
                { sousMenu: 'recoltes', label: 'Décharges', icon: 'paid', link: '/decharges', className: 'decharge', roles: ["SuperAdmin", "Admin",  "ChefStation", "Financier", "Caissier", "AgentSuivi", "Approbateur", "AssistanteDirection"] },
                { sousMenu: 'transferts', label: 'Transferts de cash', icon: 'move_down', link: '/transferts', className: 'decharge', roles: ["SuperAdmin", "Admin", "Caissier", "ChefStation", "ResponsableRegional", "DirecteurRegional", "AgentSuivi"] }
            ],
            className: 'cash-management'
        },
        {
            roles: ["SuperAdmin", "Admin"],
            menu: 'rapportCaisse',
            label: 'Rapports de caisse',
            icon: 'browse_activity',
            subMenus: [
                { sousMenu: 'brq', label: 'BRQ', icon: 'receipt_long', link: '#', className: 'Matériels', roles: ["SuperAdmin", "Admin","Caissier"] },
                { sousMenu: 'disponibiliteCaisse', label: 'Disponabilité caisse', icon: 'paid', link: '#', className: 'dispo-caisse', roles: ["SuperAdmin", "Admin", "Caissier"] },
                { sousMenu: 'chiffreAffaires', label: 'Chiffre d\'affaire', icon: 'local_atm', link: '#', className: 'chiffre-affaire', roles: ["SuperAdmin", "Admin", "Caissier"] }
            ],
            className: 'rapport-caisse'
        },
        {
            roles: ["SuperAdmin", "Admin", "DirecteurRegional"],
            menu: 'parametrage',
            label: 'Paramétrage',
            icon: 'settings',
            subMenus: [
                { sousMenu: 'typebureaucaisse', label: 'Type de bureau', icon: 'format_list_bulleted', link: '/typecaisses', className: 'categ-depens', roles: ["SuperAdmin", "Admin"] },
                { sousMenu: 'bureaucaisse', label: 'Bureau de caisse', icon: 'person', link: '/caisses', className: 'categ-depens', roles: ["SuperAdmin", "Admin"] },
                { sousMenu: 'utilisateurs', label: 'Utilisateurs', icon: 'group', link: '/utilisateurs', className: 'categ-depens', roles: ["SuperAdmin", "Admin", "DirecteurRegional"] },
                { sousMenu: 'rubriques', label: 'Rubriques', icon: 'category', link: '/rubriques', className: 'categ-depens', roles: ["SuperAdmin", "Admin"] },
                { sousMenu: 'natures', label: 'Natures', icon: 'sort', link: '/natures', className: 'sous-catg-dep', roles: ["SuperAdmin", "Admin"] },
                { sousMenu: 'agences', label: 'Agences', icon: 'assignment_turned_in', link: '/agences', className: 'agence-sous', roles: ["SuperAdmin", "Admin"] },
                { sousMenu: 'entreprises', label: 'Entreprises', icon: 'assured_workload', link: '/entreprises', className: 'entrepri-sous', roles: ["SuperAdmin", "Admin"] },
                { sousMenu: 'fonctions', label: 'Fonctions', icon: 'analytics', link: '/fonctions', className: 'fonction-sous', roles: ["SuperAdmin", "Admin"] }
            ],
            className: 'parametrage'
        }
    ], []);

    return (
        <>
            <div className="left">
                <ul>
                    <li className="menu-heading"><img src="img/yalidine-logo.png" alt="Yalidine Logo" /></li>
                    {menuItems.map((item) => (
                        hasRole(item.roles) && (
                            <React.Fragment key={item.menu}>
                                <li className={`${item.className} ${menuActif.menu === item.menu && menuActif.sousMenu === item.menu ? 'active' : ''}`} onClick={() => { updateSubMenuState(item.menu, item.menu); }}>
                                    <Link to="#">
                                        <span className="material-symbols-outlined">{item.icon}</span> {item.label}
                                        {item.subMenus.length > 0 && (
                                            menuActif.menu === item.menu ? (
                                                <span className="material-symbols-outlined down-arrow-2" style={{ width: '10px', paddingRight: '8px' }}>expand_more</span>
                                            ) : (
                                                <span className="material-symbols-outlined right-arrow-2" style={{ width: '10px', paddingRight: '8px' }}>chevron_right</span>
                                            )
                                        )}
                                    </Link>
                                </li>
                                {item.subMenus.map((subMenu) => (
                                    hasRole(subMenu.roles) && menuActif.menu === item.menu && (
                                        <li key={subMenu.sousMenu} className={`${subMenu.className} ${menuActif.sousMenu === subMenu.sousMenu ? 'active' : ''}`} onClick={() => { updateSubMenuState(item.menu, subMenu.sousMenu); }}>
                                            <Link to={subMenu.link}>
                                                <span className="material-symbols-outlined">{subMenu.icon}</span> {subMenu.label}
                                            </Link>
                                        </li>
                                    )
                                ))}
                            </React.Fragment>
                        )
                    ))}
                    <li className="Services" onClick={handleLogOut}>
                        <Link to="/login" replace>
                            <span className="material-symbols-outlined">logout</span>
                            Se déconnecter
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="right">
                <div className="tab-content">
                    <div id="overview">
                        <div className="header">
                            <div className="row-wrap">
                                <h4>{authData?.nom} {authData?.prenom}</h4>
                                {/* <div className="row">
                                    <Link to="/profil"><span className="material-symbols-outlined">person</span></Link>
                                </div> */}
                            </div>
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Layout;
