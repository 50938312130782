import React, { useEffect, useState, useContext } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../utils/Utils';
import { addDays, format } from 'date-fns';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import Pagination from '../../components/pagination/Pagination';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import bwipjs from 'bwip-js';
// import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import { axiosInstance, createCancelTokenSource, Decharge, Entreprise, DepenseNature, DepenseRubrique } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';

const Decharges = () => {
    const { authData } = useContext(AuthContext);
    const [decharges, setDecharges] = useState([]);
    // const [decharge, setDecharge] = useState(null);
    // const [agences, setAgences] = useState([]);
    // const [entreprises, setEntreprises] = useState([]);
    // const [employes, setEmployes] = useState([]);
    // const [categories, setCategories] = useState([]);
    // const [sousCategories, setSousCategories] = useState([]);
    // const [filtredDepenses, setFiltredDepenses] = useState([]);
    const [filtredDecharges, setFiltredDecharges] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    // const [idDecharge, setIdDecharge] = useState('');

    // const [showAddModal, setShowAddModal] = useState(false);
    // const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    // const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    // const [confirmDialogOpenImprimerDecharge, setConfirmDialogOpenImprimerDecharge] = useState(false);

    const [search, setSearch] = useState('');
    // const [enteredAgenceText, setEnteredAgenceText] = useState('');
    // const [enteredEmployeText, setEnteredEmployeText] = useState('');
    const debouncedSearchText = useDebounce(search, 300);
    // const debouncedAgenceText = useDebounce(enteredAgenceText, 300);
    // const debouncedEmployeText = useDebounce(enteredEmployeText, 300);

    const [naturesFilter, setNaturesFilter] = useState([]);
    const [rubriquesFilter, setRubriquesFilter] = useState([]);
    const [entreprisesFilter, setEntreprisesFilter] = useState([]);

    // const [filters, setFilters] = useState({
    //     idEntrepriseFilter: '',
    //     idNature: '',
    //     idRubrique: '',
    //     dateDebut: format(addDays(new Date(), -7), 'yyyy-MM-dd'),
    //     dateFin: format(addDays(new Date(), 0), 'yyyy-MM-dd')
    // });

    const [filters, setFilters] = useState({
        idEntrepriseFilter: '',
        idNature: '',
        idRubrique: '',
        dateDebut: '',
        dateFin: ''
    });

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredDecharges].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredDecharges(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = decharges.slice(startIndex, endIndex);

        setFiltredDecharges(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Afficher filtres

    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getDecharges(1, pageSize); // Fetch inspections with updated filters
        showHideFiltre(false);
    };

    const showHideFiltre = (val) => {
        setShowFilters(val);
        getEntreprisesFilter();
        getNaturesFiltre();
    };

    //Ajouter decharge

    // const showHideAddModal = (val) => {
    //     setShowAddModal(val);
    //     formik1.resetForm();
    //     getCategories();
    //     getAgences('');
    //     getEmployes('');
    //     getEntreprises();
    // };

    // const formik1 = useFormik({
    //     initialValues: {
    //         reference: '',
    //         idAgence: null,
    //         idEntreprise: '',
    //         idCategorie: '',
    //         idSousCategorie: '',
    //         montant: '',
    //         dateBesoin: '',
    //         idUtilisateurBeneficiare: null
    //     },
    //     validationSchema: Yup.object({
    //         idAgence: Yup.object()
    //             .nullable()
    //             .required('Champ obligatoire'),
    //         idUtilisateurBeneficiare: Yup.object()
    //             .nullable()
    //             .required('Champ obligatoire'),
    //         montant: Yup.number()
    //             .required("Champ obligatoire")
    //             .min(0, "Le montant ne peut pas être négative"),
    //         dateBesoin: Yup.string()
    //             .required('Champ obligatoire'),
    //         idCategorie: Yup.string()
    //             .required('Champ obligatoire'),
    //         idSousCategorie: Yup.string()
    //             .required('Champ obligatoire'),
    //         idEntreprise: Yup.string()
    //             .required('Champ obligatoire'),
    //     }),
    //     onSubmit: async (values) => {
    //         try {
    //             const postData = {
    //                 reference: values.reference,
    //                 idAgence: values.idAgence.idAgence,
    //                 idEntreprise: values.idEntreprise,
    //                 idCategorie: values.idCategorie,
    //                 idSousCategorie: values.idSousCategorie,
    //                 montant: values.montant,
    //                 dateBesoin: values.dateBesoin,
    //                 idUtilisateurBeneficiare: values.idUtilisateurBeneficiare.idUtilisateur,
    //                 idUtilisateurApprouve: authData.idUtilisateur
    //             };
    //             //console.log(postData);
    //             const response = await axiosInstance.post(Decharge, postData);
    //             CustomToast("La décharge a été créée avec succès", 'success');
    //             getDecharges(pageNumber, pageSize);
    //             setShowAddModal(false);
    //             //afficher pop-up de confirmation pour imprimer la decharge en utilisant
    //             setConfirmDialogOpenImprimerDecharge(true);
    //             setDecharge(response.data)
    //         } catch (error) {
    //             // Handle error
    //             if (!error?.response) {
    //                 CustomToast("Aucune réponse du serveur", 'error');
    //             } else if (error.response?.status === 401) {
    //                 CustomToast("Non autorisé", 'error');
    //             } else {
    //                 CustomToast("Demande échoué", 'error');
    //             }
    //             console.log(error);
    //         }
    //     }
    // });

    //Imprimer decharge
    const fetchPdfTemplate = async (entreprise) => {
        const decharges = {
            'YALIDINE': 'assets/decharges/DECHARGE_YALIDINE.pdf',
            'VARBIOF': 'assets/decharges/DECHARGE_VARBIOF.pdf',
            'EASY AND SPEED': 'assets/decharges/DECHARGE_EASY_SPEED.pdf',
            'GUEPEX': 'assets/decharges/DECHARGE_GUEPEX.pdf',
            'OURQUILANE': 'assets/decharges/DECHARGE_OURQUILANE.pdf',
            'SPEED MAIL': 'assets/decharges/DECHARGE_SPEED_MAIL.pdf',
            'YALITEC': 'assets/decharges/DECHARGE_YALITEC.pdf',
            'ZIMOU EXPRESS': 'assets/decharges/DECHARGE_ZIMOU.pdf',
        };
    
        const decharge = decharges[entreprise] || decharges['YALIDINE'];
        
        const existingPdfBytes = await fetch(decharge).then(res => res.arrayBuffer());
        return existingPdfBytes;
    };

    const generateBarcode = async (text) => {
        const canvas = document.createElement('canvas');
        canvas.id = 'myCanvas'; // Définir l'ID du canvas
        document.body.appendChild(canvas); // Ajouter le canvas au DOM

        // Générer le code-barres sur le canvas
        await bwipjs.toCanvas(canvas, {
            bcid: 'code128',
            text: text,
            scale: 3,
            height: 8,
            paddingheight: 2,
            includetext: true,
            textxalign: 'center',
        });

        // Convertir le canvas en URL de données
        const dataUrl = canvas.toDataURL('image/png');

        // Supprimer le canvas du DOM
        document.body.removeChild(canvas);

        return dataUrl;
    };

    const imprimerDecharge = async (utilisateurBeneficiare, libelleAgence, montant, wilayaAgence, numValidation, reference, motif, utilisateurApprouve, dateValiderFinancier, dateCreate, entreprise) => {
        // Charger le template PDF
        const arrayBuffer = await fetchPdfTemplate(entreprise);

        // Charger le PDF
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        //const { width, height } = firstPage.getSize();

        //console.log("W=" ,width," h=",height)

        // Générer le code-barres
        const barcodeDataURL = await generateBarcode(numValidation);

        // Charger le code-barres sous forme d'image dans un objet Uint8Array
        const response = await fetch(barcodeDataURL);
        const barcodeBytes = new Uint8Array(await response.arrayBuffer());

        // Insérer le code-barres dans la première page
        const barcodeImage = await pdfDoc.embedPng(barcodeBytes);
        firstPage.drawImage(barcodeImage, {
            x: 180, // Position x du code-barres sur la page
            y: 670, // Position y du code-barres sur la page
            width: barcodeImage.width * 0.6, // Largeur du code-barres
            height: barcodeImage.height * 0.6, // Hauteur du code-barres
        });

        if (reference) {
            firstPage.drawText(reference, {
                x: 94,
                y: 754,
                size: 10.5,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (wilayaAgence) {
            const boldText = wilayaAgence + ' le: ';
            firstPage.drawText(boldText, {
                x: 400,
                y: 736,
                size: 12,
                font: helveticaFontBold,
                color: rgb(0, 0, 0),
                bold: true,
            });

            const boldTextWidth = helveticaFontBold.widthOfTextAtSize(boldText, 12);
            firstPage.drawText(format(new Date(dateValiderFinancier), "dd/MM/yyyy HH:mm:ss"), {
                x: 400 + boldTextWidth,
                y: 736,
                size: 10.5,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (utilisateurBeneficiare) {
            firstPage.drawText(utilisateurBeneficiare, {
                x: 120,
                y: 597,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (libelleAgence) {
            firstPage.drawText(libelleAgence, {
                x: 95,
                y: 568.5,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (montant) {
            firstPage.drawText(formatCurrency(montant).replace(/\u202F/g, ' ') + ' ' + authData?.unite_moneitaire, {
                x: 92,
                y: 510.5,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (motif) {
            firstPage.drawText(motif, {
                x: 72,
                y: 482,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (dateCreate) {
            firstPage.drawText(format(new Date(dateCreate), "dd/MM/yyyy HH:mm:ss"), {
                x: 160,
                y: 452,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (utilisateurApprouve) {
            firstPage.drawText(utilisateurApprouve, {
                x: 125,
                y: 424,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (numValidation) {
            firstPage.drawText(numValidation, {
                x: 120,
                y: 396,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        // Enregistrer le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');
    };

    // const handleCancelImprimerDecharge = () => {
    //     setConfirmDialogOpenImprimerDecharge(false);
    // };

    // const imprimerDecharge2 = async (data) => {
    //     //console.log(data)
    //     // Charger le template PDF
    //     const arrayBuffer = await fetchPdfTemplate();

    //     // Charger le PDF
    //     const pdfDoc = await PDFDocument.load(arrayBuffer);
    //     const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    //     const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

    //     const pages = pdfDoc.getPages()
    //     const firstPage = pages[0]
    //     //const { width, height } = firstPage.getSize();

    //     //console.log("W=" ,width," h=",height)

    //     // Générer le code-barres
    //     const barcodeDataURL = await generateBarcode(data.numValidation);

    //     // Charger le code-barres sous forme d'image dans un objet Uint8Array
    //     const response = await fetch(barcodeDataURL);
    //     const barcodeBytes = new Uint8Array(await response.arrayBuffer());

    //     // Insérer le code-barres dans la première page
    //     const barcodeImage = await pdfDoc.embedPng(barcodeBytes);
    //     firstPage.drawImage(barcodeImage, {
    //         x: 180, // Position x du code-barres sur la page
    //         y: 670, // Position y du code-barres sur la page
    //         width: barcodeImage.width * 0.44, // Largeur du code-barres
    //         height: barcodeImage.height * 0.5, // Hauteur du code-barres
    //     });

    //     if (data.reference) {
    //         firstPage.drawText(data.reference, {
    //             x: 105,
    //             y: 736,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     if (data.wilayaAgence) {
    //         const boldText = data.wilayaAgence + ' le: ';
    //         firstPage.drawText(boldText, {
    //             x: 400,
    //             y: 736,
    //             size: 12,
    //             font: helveticaFontBold,
    //             color: rgb(0, 0, 0),
    //             bold: true,
    //         });

    //         const boldTextWidth = helveticaFontBold.widthOfTextAtSize(boldText, 12);
    //         firstPage.drawText(format(new Date(data.dateCreate), "dd/MM/yyyy HH:mm:ss"), {
    //             x: 400 + boldTextWidth,
    //             y: 736,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     if (data.utilisateurBeneficiare) {
    //         firstPage.drawText(data.utilisateurBeneficiare, {
    //             x: 156,
    //             y: 601.5,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     if (data.libelleAgence) {
    //         firstPage.drawText(data.libelleAgence, {
    //             x: 134,
    //             y: 582.5,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     if (data.montant) {
    //         firstPage.drawText(formatCurrency(data.montant).replace(/\u202F/g, ' ') + ' ' + authData?.unite_moneitaire, {
    //             x: 130,
    //             y: 521.5,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     if (data.libelleCategorie) {
    //         firstPage.drawText(data.libelleCategorie + " " + data.libelleSousCategorie, {
    //             x: 114,
    //             y: 503,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     if (data.dateDemande) {
    //         firstPage.drawText(format(new Date(data.dateDemande), "dd/MM/yyyy HH:mm:ss"), {
    //             x: 190,
    //             y: 483.5,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     if (data.utilisateurApprouve) {
    //         firstPage.drawText(data.utilisateurApprouve, {
    //             x: 156,
    //             y: 464,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     if (data.numValidation) {
    //         firstPage.drawText(data.numValidation, {
    //             x: 156,
    //             y: 438,
    //             size: 10.5,
    //             font: helveticaFont,
    //             color: rgb(0, 0, 0),
    //         });
    //     }

    //     // Enregistrer le PDF dans un nouveau tableau de bytes
    //     const modifiedPdfBytes = await pdfDoc.save();

    //     // Créer un objet Blob à partir des bytes du PDF
    //     const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

    //     // Créer une URL de données pour le Blob
    //     const pdfUrl = URL.createObjectURL(blob);

    //     // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
    //     window.open(pdfUrl, '_blank');
    // };

    // const handleImprimerDecharge = async () => {
    //     try {
    //         await imprimerDecharge2(decharge);
    //         setConfirmDialogOpenImprimerDecharge(false);
    //         CustomToast("La décharge a été imprimée avec succès", 'success');
    //         setDecharge(null);
    //     }
    //     catch (error) {
    //         // Handle error
    //         if (!error?.response) {
    //             CustomToast("Aucune réponse du serveur", 'error');
    //         } else if (error.response?.status === 401) {
    //             CustomToast("Non autorisé", 'error');
    //         } else {
    //             CustomToast("Demande échoué", 'error');
    //         }
    //         console.log(error);
    //     }
    // }

    //Load data

    // const getDecharges = async (page, newPerPage) => {
    //     try {
    //         setLoading(true);

    //         const queryParams = new URLSearchParams({
    //             pageNumber: page,
    //             pageSize: newPerPage
    //         });

    //         if ((authData?.idUtilisateur == null || authData?.idUtilisateur == "") || (authData?.idEntreprise == null || authData?.idEntreprise == "") || (authData?.idAgence == null || authData?.idAgence == "") || (authData?.role == null || authData?.role == "")) {
    //             return;
    //         }

    //         queryParams.append("idUtilisateur", authData?.idUtilisateur);
    //         queryParams.append("idEntreprise", authData?.idEntreprise);
    //         queryParams.append("idAgence", authData?.idAgence);
    //         queryParams.append("role", authData?.role);

    //         if (debouncedSearchText != null) {
    //             queryParams.append("text", debouncedSearchText);
    //         }

    //         if (filters.idEntrepriseFilter !== '') {
    //             queryParams.append("idEntrepriseFilter", filters.idEntrepriseFilter);
    //         }

    //         if (filters.idCategorie !== '') {
    //             queryParams.append("idCategorie", filters.idCategorie);
    //         }

    //         if (filters.idSousCategorie !== '') {
    //             queryParams.append("idSousCategorie", filters.idSousCategorie);
    //         }

    //         if (filters.dateDebut !== '') {
    //             queryParams.append("dateDebut", filters.dateDebut);
    //         }

    //         if (filters.dateFin !== '') {
    //             queryParams.append("dateFin", filters.dateFin);
    //         }


    //         const response = await axiosInstance.get(`${Decharge}/GetListeDecharges?${queryParams}`);
    //         const resp = await response.data;
    //         setDecharges(resp.data);
    //         setFiltredDecharges(resp.data);
    //         setPageNumber(resp.pageNumber);
    //         setPageSize(resp.pageSize);
    //         setTotalItems(resp.totalItems);
    //         setTotalPages(resp.totalPages);
    //         setLoading(false);
    //     }
    //     catch (error) {
    //         // Handle error
    //         if (!error?.response) {
    //             CustomToast("Aucune réponse du serveur", 'error');
    //         } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé.") {
    //             CustomToast("L'utilisateur n'est pas autorisé.", 'error');
    //         } else if (error.response?.status === 401) {
    //             CustomToast("Non autorisé", 'error');
    //         } else {
    //             CustomToast("Demande échoué", 'error');
    //         }
    //         console.log(error);
    //         setLoading(false);
    //     }
    // }

    const getDecharges = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);

        try {
            if ((authData?.idUtilisateur == null || authData?.idUtilisateur == "") || (authData?.idEntreprise == null || authData?.idEntreprise == "") || (authData?.idAgence == null || authData?.idAgence == "") || (authData?.role == null || authData?.role == "")) {
                return;
            }

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage,
                idUtilisateur: authData?.idUtilisateur,
                idEntreprise: authData?.idEntreprise,
                idAgence: authData?.idAgence,
                role: authData?.role,
                text: debouncedSearchText,
                ...filters
            });

            if (authData?.role == "Caissier" && (authData?.idCaisse != null && authData?.idCaisse != "")) {
                queryParams.append("idCaisse", authData?.idCaisse);
            }

            const response = await axiosInstance.get(`${Decharge}/GetListeDecharges?${queryParams}`,{ cancelToken });
            const resp = await response.data;

            setDecharges(resp.data);
            setFiltredDecharges(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
        } catch (error) {
            if (!error?.response) {
                //CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé.") {
                CustomToast("L'utilisateur n'est pas autorisé.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échouée", 'error');
            }
            console.log(error);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    };

    const getNaturesFiltre = async () => {
        try {
            const response = await axiosInstance.get(`${DepenseNature}`);
            const resp = await response.data;
            setNaturesFilter(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    const getRubriquesFiltre = async (idNature) => {
        try {
            const response = await axiosInstance.get(`${DepenseRubrique}/GetRubriquesDepenseByIdNature/${idNature}`);
            const resp = await response.data;
            setRubriquesFilter(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    const getEntreprisesFilter = async () => {
        try {
            const response = await axiosInstance.get(`${Entreprise}`);
            const resp = await response.data;
            setEntreprisesFilter(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    // const getCategories = async () => {
    //     try {
    //         const response = await axiosInstance.get(`${DepenseNature}`);
    //         const resp = await response.data;
    //         setCategories(resp)
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    // const getSousCategories = async (idCategorie) => {
    //     try {
    //         const response = await axiosInstance.get(`${DepenseRubrique}/GetSousCategorieDepenseByIdCategorie/${idCategorie}`);
    //         const resp = await response.data;
    //         setSousCategories(resp)
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    // const getAgences = async (agence) => {
    //     try {
    //         const queryParams = new URLSearchParams({
    //             pageNumber: 1,
    //             pageSize: 10
    //         });

    //         if (agence !== '') {
    //             queryParams.append("text", agence);
    //         }

    //         const response = await axiosInstance.get(`${Agence}/GetListeAgencesAutocomplete?${queryParams}`);
    //         const resp = await response.data;
    //         setAgences(resp.data);

    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    // const getEmployes = async (employe) => {
    //     try {
    //         const queryParams = new URLSearchParams({
    //             pageNumber: 1,
    //             pageSize: 10
    //         });

    //         if (employe !== '') {
    //             queryParams.append("text", employe);
    //         }

    //         const response = await axiosInstance.get(`${Utilisateur}/GetUtilisateursAutocomplete?${queryParams}`);
    //         const resp = await response.data;
    //         setEmployes(resp.data);

    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    // const getEntreprises = async () => {
    //     try {
    //         const response = await axiosInstance.get(`${Entreprise}`);
    //         const resp = await response.data;
    //         setEntreprises(resp)
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    useEffect(() => {
        if (filters.idNature !== "") {
            getRubriquesFiltre(filters.idNature);
        }
        else {
            setRubriquesFilter([]);
        }
    }, [authData, filters.idNature])

    // useEffect(() => {
    //     //Load communes
    //     if (formik1.values.idCategorie !== "") {
    //         getSousCategories(formik1.values.idCategorie);
    //     }
    //     else {
    //         setSousCategories([]);
    //     }
    // }, [authData, formik1.values.idCategorie])

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getDecharges(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getDecharges(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     if (showAddModal || showUpdateModal) {
    //         getEmployes(debouncedEmployeText);
    //     }
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [debouncedEmployeText]);

    // useEffect(() => {
    //     if (showAddModal || showUpdateModal) {
    //         getAgences(debouncedAgenceText);
    //     }
    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [debouncedAgenceText]);

    return (
        <>
            <div className="container appeler-section" >
                <div className="affectation-sec wrap-all">
                    {/* {(authData?.role === "SuperAdmin" || authData?.role === "Financier") &&
                        <Link to="#" className="affectation-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Créer une décharge
                            </div>
                        </Link>} */}
                    <div className="group-elements without-btn">
                        <div className="voir-elements">
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <input
                                type="search"
                                value={search}
                                placeholder="Rechercher ..."
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="apps__button--wrapper">
                                <div className="filter-sw apps__button" onClick={() => showHideFiltre(!showFilters)}>
                                    <span className="material-symbols-outlined">tune</span>
                                    <p>Filter</p>
                                </div>
                                {showFilters && <div className="dropdown__wrapper dropdown__wrapper--fade-in">
                                    <form onSubmit={handleSearch}>
                                        <div className="col">
                                            <div className="row">
                                                <p>Entreprise</p>
                                                <select
                                                    id="idEntrepriseFilter"
                                                    name="idEntrepriseFilter"
                                                    value={filters.idEntrepriseFilter || ''}
                                                    onChange={(e) => setFilters({ ...filters, idEntrepriseFilter: e.target.value || '' })}
                                                >
                                                    <option value="">Séléctionnez ...</option>
                                                    {entreprisesFilter.map((entreprise) => (
                                                        <option key={entreprise.idEntreprise} value={entreprise.idEntreprise}>{entreprise.libelle}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="row">
                                                <p>Nature</p>
                                                <select
                                                    id="idNature"
                                                    name="idNature"
                                                    value={filters.idNature || ''}
                                                    onChange={(e) => setFilters({ ...filters, idNature: e.target.value || '' })}
                                                >
                                                    <option value="">Séléctionnez ...</option>
                                                    {naturesFilter.map((nature) => (
                                                        <option key={nature.idNature} value={nature.idNature}>{nature.libelle}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="row">
                                                <p>Rubrique</p>
                                                <select
                                                    id="idRubrique"
                                                    name="idRubrique"
                                                    value={filters.idRubrique || ''}
                                                    onChange={(e) => setFilters({ ...filters, idRubrique: e.target.value || '' })}
                                                >
                                                    <option value="">Séléctionnez ...</option>
                                                    {rubriquesFilter.map((rubrique) => (
                                                        <option key={rubrique.idRubrique} value={rubrique.idRubrique}>{rubrique.libelle}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="row">
                                                <p style={{ opacity: 0 }}>Date</p>
                                                <input
                                                    type="date"
                                                    value={filters.dateDebut}
                                                    onChange={(e) => setFilters({ ...filters, dateDebut: e.target.value })}
                                                />
                                                <input
                                                    type="date"
                                                    value={filters.dateFin}
                                                    onChange={(e) => setFilters({ ...filters, dateFin: e.target.value })}
                                                />
                                            </div>
                                            <div className="row">
                                                <button className="submit-btn">Filtrer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {filtredDecharges?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>Bénéficiaire</th>
                                    <th>Entreprise</th>
                                    <th>Agence</th>
                                    <th>Date de demande</th>
                                    <th>Approuvée par</th>
                                    <th>Date de validation</th>
                                    <th>Montant</th>
                                    <th>Référnce</th>
                                    <th>N° validation</th>
                                    <th>Imprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredDecharges?.map((row) => (
                                    <tr key={row.idDecharge}>
                                        <td>{row.utilisateurBeneficiare}</td>
                                        <td>{row.libelleEntreprise}</td>
                                        <td>{row.libelleAgence}</td>
                                        <td>{format(new Date(row.dateDemande), "dd/MM/yyyy")}</td>
                                        <td>{row.utilisateurApprouve}</td>
                                        <td>{format(new Date(row.dateValiderFinancier), "dd/MM/yyyy HH:mm")}</td>
                                        <td style={{ textAlign: "right" }}>{formatCurrency(row.montant)}</td>
                                        <td style={{ textAlign: "right" }}>{row.reference}</td>
                                        <td style={{ textAlign: "right" }}>{row.numValidation}</td>
                                        <td className="txt-center" onClick={() => imprimerDecharge(row.utilisateurBeneficiare, row.libelleAgence, row.montant, row.wilayaAgence, row.numValidation, row.reference, row.motif, row.utilisateurApprouve, row.dateValiderFinancier, row.dateDemande, row.libelleEntreprise)}>
                                            <span className="material-symbols-outlined danger">print
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {filtredDecharges?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}

                </div>
            </div>

            {/* {showAddModal && <div className="popcard-add">
                <div className="popcard-add_inside depense-w">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Bénéficiaire<span className='requiredField'>*</span></label>
                                        <Select
                                            id="idUtilisateurBeneficiare"
                                            name="idUtilisateurBeneficiare"
                                            value={formik1.values?.idUtilisateurBeneficiare}
                                            onChange={(option) => { formik1.setFieldValue('idUtilisateurBeneficiare', option); }}
                                            options={employes}
                                            getOptionLabel={(option) => option.nom.toUpperCase() + " " + option.prenom.toUpperCase()}
                                            getOptionValue={(option) => option.idUtilisateur}
                                            onInputChange={(inputValue) => {
                                                // Update the entered text
                                                setEnteredEmployeText(inputValue);
                                            }}
                                            isClearable
                                            placeholder=""
                                            //components={{IndicatorsContainer:() => null}}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: '19px',
                                                    outline: '0!important',
                                                    borderRadius: '0.5rem',
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem',
                                                    margin: '0',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    alignItems: 'start',
                                                    fontSize: '1rem'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '0.5rem',
                                                    zIndex: 99990,
                                                    marginTop: 0,
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                }),
                                            }}
                                        />
                                        {formik1.touched.idUtilisateurBeneficiare && formik1.errors.idUtilisateurBeneficiare ? (
                                            <div className="invalid-feedback">{formik1.errors.idUtilisateurBeneficiare}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Entreprise<span className='requiredField'>*</span></label>
                                        <select
                                            id="idEntreprise"
                                            name="idEntreprise"
                                            value={formik1.values.idEntreprise}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idEntreprise && formik1.errors.idEntreprise) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {entreprises.map((entreprise) => (
                                                <option key={entreprise.idEntreprise} value={entreprise.idEntreprise}>{entreprise.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idEntreprise && formik1.errors.idEntreprise ? (
                                            <div className="invalid-feedback">{formik1.errors.idEntreprise}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Agence<span className='requiredField'>*</span></label>
                                        <Select
                                            id="idAgence"
                                            name="idAgence"
                                            value={formik1.values?.idAgence}
                                            onChange={(option) => { formik1.setFieldValue('idAgence', option); }}
                                            options={agences}
                                            getOptionLabel={(option) => option.libelle}
                                            getOptionValue={(option) => option.idAgence}
                                            onInputChange={(inputValue) => {
                                                // Update the entered text
                                                setEnteredAgenceText(inputValue);
                                            }}
                                            isClearable
                                            placeholder=""
                                            //components={{IndicatorsContainer:() => null}}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: '19px',
                                                    outline: '0!important',
                                                    borderRadius: '0.5rem',
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem',
                                                    margin: '0',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    alignItems: 'start',
                                                    fontSize: '1rem'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '0.5rem',
                                                    zIndex: 99990,
                                                    marginTop: 0,
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                }),
                                            }}
                                        />
                                        {formik1.touched.idAgence && formik1.errors.idAgence ? (
                                            <div className="invalid-feedback">{formik1.errors.idAgence}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Date de besoin<span className='requiredField'>*</span></label>
                                        <input
                                            type="date"
                                            id="dateBesoin"
                                            name="dateBesoin"
                                            style={(formik1.touched.dateBesoin && formik1.errors.dateBesoin) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.dateBesoin}
                                        />
                                        {formik1.touched.dateBesoin && formik1.errors.dateBesoin ? (
                                            <div className="invalid-feedback">{formik1.errors.dateBesoin}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Montant<span className='requiredField'>*</span></label>
                                        <input
                                            type="number"
                                            id="montant"
                                            name="montant"
                                            style={(formik1.touched.montant && formik1.errors.montant) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montant}
                                        />
                                        {formik1.touched.montant && formik1.errors.montant ? (
                                            <div className="invalid-feedback">{formik1.errors.montant}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Catégorie<span className='requiredField'>*</span></label>
                                        <select
                                            id="idCategorie"
                                            name="idCategorie"
                                            value={formik1.values.idCategorie}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idCategorie && formik1.errors.idCategorie) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {categories.map((categorie) => (
                                                <option key={categorie.idCategorie} value={categorie.idCategorie}>{categorie.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idCategorie && formik1.errors.idCategorie ? (
                                            <div className="invalid-feedback">{formik1.errors.idCategorie}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Sous-catégorie<span className='requiredField'>*</span></label>
                                        <select
                                            id="idSousCategorie"
                                            name="idSousCategorie"
                                            value={formik1.values.idSousCategorie}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idSousCategorie && formik1.errors.idSousCategorie) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {sousCategories.map((categorie) => (
                                                <option key={categorie.idSousCategorie} value={categorie.idSousCategorie}>{categorie.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idSousCategorie && formik1.errors.idSousCategorie ? (
                                            <div className="invalid-feedback">{formik1.errors.idSousCategorie}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>} */}

            {/* <ConfirmationDialog
                open={confirmDialogOpenImprimerDecharge}
                onClose={handleCancelImprimerDecharge}
                onConfirm={handleImprimerDecharge}
                titleMessage="Imprimer la décharge"
                bodyMessage=""
                btnCancelText="Annuler"
                btnConfirmText="Imprimer"
            /> */}
        </>
    );
}

export default Decharges;
