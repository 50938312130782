import React from 'react';
import { useNavigate } from "react-router-dom"

const NotFound = () => {
    const navigate = useNavigate();

    //const goBack = () => { navigate("/empty", { replace: true }); }
    const goBack = () => { navigate("/login", { replace: true }); }

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="display-1 fw-bold">403</h1>
                <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                <p className="lead">
                    The page you’re looking for doesn’t exist.
                  </p>
                <button onClick={goBack} className="btn btn-primary">Go Home</button>
            </div>
        </div>
    );
}

export default NotFound;
