import React from 'react';
import { useNavigate } from "react-router-dom"
const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => { navigate("/login", { replace: true }); }
    //const goBack = () => { navigate(-1); }

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">Opps!</span> Unauthorized.</p>
                <p className="lead">
                    The current page is unavailable or you do not have permission to access.
                </p>
                <button onClick={goBack} className="btn btn-primary">Go Home</button>
            </div>
        </div>
    );
}

export default Unauthorized;
