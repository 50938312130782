import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import Pagination from '../../../components/pagination/Pagination';
import ConfirmationDialog from '../../../components/confirmation/ConfirmationDialog';
import { axiosInstance, createCancelTokenSource, CaisseType } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
import useDebounce from '../../../hooks/useDebounce';

const TypeCaisses = () => {
    const { authData } = useContext(AuthContext);
    const [types, setTypes] = useState([]);
    const [filtredTypes, setFiltredTypes] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [idTypeCaisseDelete, setIdTypeCaisseDelete] = useState('');


    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredTypes].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredTypes(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = types.slice(startIndex, endIndex);

        setFiltredTypes(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };


    //Ajouter une type
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
    };

    const formik1 = useFormik({
        initialValues: {
            libelle: '',
            description: '',
            statut: 'actif'
        },
        validationSchema: Yup.object({
            libelle: Yup.string()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelle: values.libelle,
                    description: values.description,
                    statut: values.statut,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(values)
                const response = await axiosInstance.post(CaisseType, postData);
                CustomToast("Le type de caisse a été créé avec succès", 'success');
                getTypes(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un type avec le même nom existe déjà.") {
                    CustomToast("Un type de caisse avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier un type
    const showHideUpdateModal = (val, idTypeCaisse, libelle, description, statut) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        if (val) {
            formik2.setValues({
                idTypeCaisse: idTypeCaisse,
                libelleUpdate: libelle,
                descriptionUpdate: description,
                statutUpdate: statut || 'actif',
            });
        }
    };

    const formik2 = useFormik({
        initialValues: {
            idTypeCaisse: '',
            libelleUpdate: '',
            descriptionUpdate: '',
            statutUpdate: 'actif'
        },
        validationSchema: Yup.object({
            libelleUpdate: Yup.string()
                .required('Champ obligatoire'),
            statutUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idTypeCaisse: values.idTypeCaisse,
                    libelle: values.libelleUpdate,
                    description: values.descriptionUpdate,
                    statut: values.statutUpdate,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(postData);
                const response = await axiosInstance.put(CaisseType, postData);
                CustomToast("Le type de caisse a été modifié avec succès", 'success');
                getTypes(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un type avec le même nom existe déjà.") {
                    CustomToast("Un type de caisse avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer un type de caisse
    const handleConfirmDelete = (idTypeCaisse) => {
        setIdTypeCaisseDelete(idTypeCaisse);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${CaisseType}/${idTypeCaisseDelete}`);
            setIdTypeCaisseDelete('');
            CustomToast("Le type de caisse a été supprimé avec succès", 'success');
            getTypes(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 409 && error.response?.data === "Type utilisé") {
                CustomToast("Type de caisse en cours d'utilisation.", 'error');
            }  else if (error.response?.status === 404) {
                CustomToast("Service introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getTypes = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${CaisseType}/GetListeTypesCaisse?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setTypes(resp.data);
            setFiltredTypes(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getTypes(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getTypes(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getTypes(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);
    return (
        <>
            <div className="container type-desk-section">
                <div>
                    <div className="affectation-sec wrap-all">
                        <div>
                            <Link to="#" className="utilisateur-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                                <div className="add-new" id="add-vehc">
                                    <span className="material-symbols-outlined">add</span>
                                    Ajouter un type de bureau
                                </div>
                            </Link>
                        </div>
                        <div className="group-elements">
                            <div className="voir-elements">
                                {/* <p>Voir</p> */}
                                <select
                                    name="pageSize"
                                    id="pageSize"
                                    onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                                >
                                    <option value="10" defaultValue={true}>10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </div>
                            <div className="search-element">
                                <input
                                    type="search"
                                    value={search}
                                    placeholder="Rechercher ..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        {filtredTypes?.length > 0 && <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Description</th>
                                        <th>Statut</th>
                                        <th>Modifier</th>
                                        <th>Supprimer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredTypes?.map((row) => (
                                        <tr key={row.idTypeCaisse}>
                                            <td>{row.libelle}</td>
                                            <td>{row.description}</td>
                                            <td>{
                                                row.statut === "actif" ? <>Actif</> :
                                                    row.statut === "inactif" ? <>Inactif</> :
                                                        null
                                            }</td>
                                            <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idTypeCaisse, row.libelle, row.description, row.statut)}>
                                                <span className="material-symbols-outlined">edit
                                                </span></td>
                                            <td className="txt-center danger" onClick={() => handleConfirmDelete(row.idTypeCaisse)}>
                                                <span className="material-symbols-outlined">delete
                                                </span></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        {filtredTypes?.length > 0 && <Pagination
                            pageNumber={pageNumber}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                            pageSize={pageSize}
                            totalItems={totalItems}
                        />}
                    </div>
                </div>
            </div>

            {showAddModal && <div class="popcard-add">
                <div class="popcard-add_inside">
                    <Link class="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 class="popcard-add_inside-title"></h2>
                    <div class="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div class="wrap-info">
                                <div class="wrap-row">
                                    <div class="wrap-one-full">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelle"
                                            name="libelle"
                                            style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.libelle}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div class="wrap-row">
                                    <div class="wrap-one-full">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="wrap-row">
                                    <div class="wrap-one-full">
                                        <label>Description</label>
                                        <textarea type="text"
                                            id="description"
                                            name="description"
                                            placeholder=""
                                            defaultValue={formik1.values.description}
                                            onChange={formik1.handleChange}
                                            cols={30}
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="popcard-operation-pickup-details_inside-zone">
                                <div class="buttons">
                                    <input type="submit" value="Enregistrer" class="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {showUpdateModal && <div class="popcard-add">
                <div class="popcard-add_inside">
                    <Link class="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideUpdateModal(false,'', '', '','')}>&#10006;</Link>
                    <h2 class="popcard-add_inside-title"></h2>
                    <div class="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div class="wrap-info">
                                <div class="wrap-row">
                                    <div class="wrap-one-full">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelleUpdate"
                                            name="libelleUpdate"
                                            style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.libelleUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div class="wrap-row">
                                    <div class="wrap-one-full">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="wrap-row">
                                    <div class="wrap-one-full">
                                        <label>Description</label>
                                        <textarea type="text"
                                            id="descriptionUpdate"
                                            name="descriptionUpdate"
                                            placeholder=""
                                            defaultValue={formik2.values.descriptionUpdate}
                                            onChange={formik2.handleChange}
                                            cols={30}
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="popcard-operation-pickup-details_inside-zone">
                                <div class="buttons">
                                    <input type="submit" value="Enregistrer" class="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer ce type de caisse ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer le type de caisse"
            />

        </>
    );
}

export default TypeCaisses;
