import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import { axiosInstance, Utilisateur } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';

const Profil = () => {
    const { authData } = useContext(AuthContext);

    const formik1 = useFormik({
        initialValues: {
            username: authData?.username||'',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string().test('username-validation', 'Invalid username', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }
                if (value.length < 8 || value.length > 255) {
                    return this.createError({ message: 'Le champ doit contenir entre 8 et 255 caractères' });
                }

                return true;
            }),
            password: Yup.string().test('password-validation', 'Invalid password', function (value) {

                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }
                if (value.length < 7 || value.length > 16) {
                    return this.createError({ message: 'Le champ doit contenir entre 7 et 16 caractères' });
                }

                return true;
            })
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idUtilisateur: authData.idUtilisateur,
                    username: values.username,
                    password: values.password
                };
                //console.log(postData);
                const response = await axiosInstance.put(`${Utilisateur}/UpdateProfil`, postData);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un compte avec le même nom d'utilisateur existe déjà.") {
                    CustomToast("Un utilisateur avec le même nom d'utilisateur existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Le compte à mettre à jour n'existe pas.") {
                    CustomToast("Le compte à mettre à jour n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    return (
        <div className="container guichet-section">
            <div className="affectation-sec wrap-all">
                <form onSubmit={formik1.handleSubmit} >
                    <div className="wrap-info">
                        <div className="wrap-row">
                            <div className="wrap-one-half">
                                <label>Username <span className='requiredField'>*</span></label>
                                <input type="text"
                                    id="username"
                                    name="username"
                                    placeholder=""
                                    autoComplete="false"
                                    defaultValue={formik1.values.username}
                                    onChange={formik1.handleChange}
                                    style={(formik1.touched.username && formik1.errors.username) ? { borderColor: "red" } : null}
                                />
                                {formik1.touched.username && formik1.errors.username ? (
                                    <div className="invalid-feedback">{formik1.errors.username}</div>
                                ) : null}
                            </div>

                            <div className="wrap-one-half">
                                <label>Mot de passe<span className='requiredField'>*</span></label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    style={(formik1.touched.password && formik1.errors.password) ? { borderColor: "red" } : null}
                                    placeholder=""
                                    defaultValue={formik1.values.password}
                                    onChange={formik1.handleChange}
                                />
                                {formik1.touched.password && formik1.errors.password ? (
                                    <div className="invalid-feedback">{formik1.errors.password}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="popcard-operation-pickup-details_inside-zone">
                        <div className="buttons">
                            <input type="submit" value="Enregistrer" className="submit-btn" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Profil;
