import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwtDecode from 'jwt-decode';

const PrivateRoute = ({ allowedRoles }) => {
    const location = useLocation();
    const authData = JSON.parse(localStorage.getItem("authData"));
    const roles = [authData?.role] || [];
    // console.log(allowedRoles)
    // console.log(roles)
    // console.log(authData?.token)
    // const decodedToken = authData?.token ? jwtDecode(authData?.token) : '';
    // console.log(decodedToken)
    //console.log("decodedToken")
    // return (
    //     roles?.find(role => allowedRoles?.includes(role))
    //         ? <Outlet />
    //         : authData?.token
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/login" state={{ from: location }} replace />
    // );
    return (
        ((roles?.find(role => allowedRoles?.includes(role))) && authData?.token)
            ? <Outlet />
            : (!authData?.token)
                ? <Navigate to="/login" state={{ from: location }} replace />
                : <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
}

export default PrivateRoute;