import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToast } from '../../../components/Toast/CustomToast';
import Pagination from '../../../components/pagination/Pagination';
import { axiosInstance, createCancelTokenSource, Fonction } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
import useDebounce from '../../../hooks/useDebounce';

const Fonctions = () => {
    const { authData } = useContext(AuthContext);
    const [fonctions, setFonctions] = useState([]);
    const [filtredFonctions, setFiltredFonctions] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    
    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredFonctions].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredFonctions(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = fonctions.slice(startIndex, endIndex);

        setFiltredFonctions(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Load data

    const getFonctions = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${Fonction}/GetFonctionsListe?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setFonctions(resp.data);
            setFiltredFonctions(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getFonctions(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getFonctions(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    return (
        <div className="container fonction-section">
            <div className="affectation-sec wrap-all">
                <a href="#ajouter-sous-categorie" className="affectation-add color-primary-2 decoration-none" style={{ opacity: 0 }}>
                    <div className="add-new" id="add-vehc">
                        <span className="material-symbols-outlined">add</span>
                        ajouter Sous-catégories
                    </div>
                </a>
                <div className="group-elements">
                    <div className="voir-elements">
                        {/* <p>Voir</p> */}
                        <select
                            name="pageSize"
                            id="pageSize"
                            onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                        >
                            <option value="10" defaultValue={true}>10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                    <div className="search-element">
                        <input
                            type="search"
                            value={search}
                            placeholder="Rechercher ..."
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                {filtredFonctions?.length > 0 && <div className="table-wrap">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtredFonctions?.map((row) => (
                                <tr key={row.idFonction}>
                                    <td>{row.libelle}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}

                {filtredFonctions?.length > 0 && <Pagination
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageSize={pageSize}
                    totalItems={totalItems}
                />}
            </div>

        </div>
    );
}

export default Fonctions;
