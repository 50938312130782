import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

//const API_KEY = process.env.REACT_APP_API_KEY;

const BASE_URL_API = BASE_URL+'api/';

const IMAGE_URL = BASE_URL+'images/';

// Create an instance of Axios with the base URL
export const axiosInstance = axios.create({
    baseURL: BASE_URL_API,
  });

// Add request interceptor
axiosInstance.interceptors.request.use((config) => {
  // Add JWT token to the request header from localStorage
  const authData = JSON.parse(localStorage.getItem('authData'));
  const jwtToken = authData ? authData.token : null;
  if (jwtToken) {
    config.headers['Authorization'] = `Bearer ${jwtToken}`;
  }

  // Add API key to the request header
  //config.headers['X-Api-Key'] = API_KEY;
  //Implement api key expiration

  return config;
});

export {BASE_URL};

//API endpoints

export const Agence=BASE_URL_API+'Agence';
export const Authentification=BASE_URL_API+'Authentification';
export const Caisse=BASE_URL_API+'Caisse';
export const CaisseType=BASE_URL_API+'CaisseType';
export const Commune=BASE_URL_API+'Commune';
export const DepenseNature=BASE_URL_API+'DepenseNature';
export const DepenseRubrique=BASE_URL_API+'DepenseRubrique';
export const Depense=BASE_URL_API+'Depense';
export const Decharge=BASE_URL_API+'Decharge';
export const Transfert=BASE_URL_API+'Transfert';
export const Entreprise=BASE_URL_API+'Entreprise';
export const Fonction=BASE_URL_API+'Fonction';
export const Utilisateur=BASE_URL_API+'Utilisateur';
export const Wilaya=BASE_URL_API+'Wilaya';

//To display file ressources
export const DepenseFile=IMAGE_URL+'depenses/';
export const SignatureFile=IMAGE_URL+'signatures/';

//WebSockets-SignalR
export const AppHub=BASE_URL+'appHub';

export const getJwtToken=()=> {
  // Implement your logic to retrieve the JWT token
  const authData = JSON.parse(localStorage.getItem('authData'));
  return (authData ? authData.token : null);
}

export const createCancelTokenSource = () => axios.CancelToken.source();

// export const DashboardHub=BASE_URL+'dashboardHub';