import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { formatCurrency } from '../../utils/Utils';
import { addDays, format, parseISO, isValid } from 'date-fns';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import bwipjs from 'bwip-js';
import { CustomToast } from '../../components/Toast/CustomToast';
import Pagination from '../../components/pagination/Pagination';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import { ExportToExcel } from '../../components/ExportToExcel/ExportToExcel';
import { axiosInstance, createCancelTokenSource, Caisse, Agence, Entreprise, Depense, DepenseNature, DepenseRubrique, DepenseFile, Decharge } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';
import { NumericFormat } from "react-number-format";

const Depenses = () => {
    const { authData } = useContext(AuthContext);
    const [depenses, setDepenses] = useState([]);
    const [agences, setAgences] = useState([]);
    const [entreprises, setEntreprises] = useState([]);
    const [natures, setNatures] = useState([]);
    const [caisses, setCaisses] = useState([]);
    const [filtredDepenses, setFiltredDepenses] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogOpenValiderDepense, setConfirmDialogOpenValiderDepense] = useState(false);
    const [confirmDialogOpenValiderDepenseDecaissementValiderNonValider, setConfirmDialogOpenValiderDepenseDecaissementValiderNonValider] = useState(false);
    const [confirmDialogOpenRefuserDepense, setConfirmDialogOpenRefuserDepense] = useState(false);
    const [confirmDialogOpenRefuserDepenseDecaissementValiderNonValider, setConfirmDialogOpenRefuserDepenseDecaissementValiderNonValider] = useState(false);
    const [confirmDialogOpenAnnuler, setConfirmDialogOpenAnnuler] = useState(false);
    const [confirmDialogOpenImprimerDecharge, setConfirmDialogOpenImprimerDecharge] = useState(false);

    const [idDepenseDelete, setIdDepenseDelete] = useState('');
    const [idDepenseValider, setIdDepenseValider] = useState('');
    const [idDepenseAnnuler, setIdDepenseAnnuler] = useState('');


    const [search, setSearch] = useState('');
    const [enteredAgenceText, setEnteredAgenceText] = useState('');
    const [enteredCaisseText, setEnteredCaisseText] = useState('');
    const debouncedSearchText = useDebounce(search, 300);
    const debouncedAgenceText = useDebounce(enteredAgenceText, 300);
    const debouncedCaisseText = useDebounce(enteredCaisseText, 300);

    const [naturesFilter, setNaturesFilter] = useState([]);


    // const [filters, setFilters] = useState({
    //     demandeur: '',
    //     statut: '',
    //     idNature: '',
    //     idRubrique: '',
    //     dateDebut: format(addDays(new Date(), -7), 'yyyy-MM-dd'),
    //     dateFin: format(addDays(new Date(), 0), 'yyyy-MM-dd')
    // });

    const [filters, setFilters] = useState({
        demandeur: '',
        statut: '',
        idNature: '',
        idRubrique: '',
        dateDebut: '',
        dateFin: ''
    });

    const pathImageDepenseRef = useRef(null);
    const [pathImageDepense, setPathImageDepense] = useState(null);

    const pathImageDepenseRefUpdate = useRef(null);
    const [pathImageDepenseUpdate, setPathImageDepenseUpdate] = useState(null);
    const [pathImageDepenseName, setPathImageDepenseName] = useState(null);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredDepenses].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredDepenses(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = depenses.slice(startIndex, endIndex);

        setFiltredDepenses(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const statutLabels = {
        CreerChefDeStation: 'Créée par le chef de station',
        CreerResponsable: 'Créée par le responsable',
        CreerResponsableRegionnal: 'Créée par le responsable régional',
        CreerAssistantDirection: "Créée par l'assistant(e) de direction",
        ValiderDirecteurRegionnal: 'Validée par le directeur régional',
        RefuserDirecteurRegionnal: 'Refusée par le directeur régional',
        // ValiderGerant: 'Validée par le gérant',
        // RefuserGerant: 'Refusée par le gérant',
        // ValiderFinancier: 'Validée par le financier',
        // RefuserFinancier: 'Refusée par le financier',
        ValiderApprobateur: "Validée par l'approbateur",
        RefuserApprobateur: "Refusée par l'approbateur",
        Decaisser: 'Décaissée',
        DecaissementValider: 'Décaissement validé',
        DecaissementNonValider: 'Décaissement non validé',
        DemandeAnnulee: 'Demande annulée',
    };

    const statutClasses = {
        CreerChefDeStation: 'statut statut-dc-fnc',
        CreerResponsable: 'statut statut-dc-fnc',
        CreerResponsableRegionnal: 'statut statut-dc-fnc',
        CreerAssistantDirection: 'statut statut-dc-fnc',
        ValiderDirecteurRegionnal: 'statut statut-chef',
        RefuserDirecteurRegionnal: 'statut statut-rf-gr',
        // ValiderGerant: 'statut statut-chef',
        // RefuserGerant: 'statut statut-rf-gr',
        // ValiderFinancier: 'statut statut-chef',
        // RefuserFinancier: 'statut statut-rf-gr',
        ValiderApprobateur: 'statut statut-chef',
        RefuserApprobateur: 'statut statut-rf-gr',
        Decaisser: 'statut statut-dc-caissier',
        DecaissementValider: 'statut statut-dc-caissier',
        DecaissementNonValider: 'statut statut-rf-gr',
        DemandeAnnulee: 'statut statut-rf-gr',
    };

    // const statutClasses = {
    //     CreerChefDeStation: 'statut statut-chef',
    //     CreerResponsable: 'statut statut-responsbl',
    //     CreerResponsableRegionnal: 'statut statut-resp-gnr',
    //     ValiderDirecteurRegionnal: 'statut statut-v-drc-gnr',
    //     RefuserDirecteurRegionnal: 'statut statut-rf-drc-gnr',
    //     ValiderGerant: 'statut statut-v-gr',
    //     RefuserGerant: 'statut statut-rf-gr',
    //     ValiderFinancier: 'statut statut-dc-fnc',
    //     RefuserFinancier: 'statut statut-rf-gr',
    //     Decaisser: 'statut statut-dc-caissier',
    // };

    //Afficher filtres

    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getDepenses(1, pageSize); // Fetch inspections with updated filters
        showHideFiltre(false);
    };

    const showHideFiltre = (val) => {
        setShowFilters(val);
        if (val) {
            getNaturesFiltre();
            // setRubriquesFilter([]);
        }

    };

    //Fichiers
    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    const openFileNewTab = (pdfUrl) => {
        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');
    };

    //Ajouter une dépense
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        setPathImageDepense(null);
        getNatures();
        getAgences('');
        getEntreprises();
        //getCaisses('');
        setCaisses([]);
    };

    const handlePathImageDepenseChange = (e) => {
        setPathImageDepense(e.target.files[0]);
    };

    const formik1 = useFormik({
        initialValues: {
            idAgence: '',
            idEntreprise: '',
            //idNature: '',
            idRubrique: '',
            montantTTC: '',
            montantHT: '',
            montantTVA: '',
            montantDroitsTimbre: '',
            idCaisseDecaissement: null,
            dateBesoin: '',
            description: '',
            modePaiement: '',
            numeroTransactionFinanciere: '',
            typeJustificatif: '',
            justificationFile: '',
        },
        validationSchema: Yup.object({
            idCaisseDecaissement: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            idAgence: Yup.string()
                .required('Champ obligatoire'),
            idEntreprise: Yup.string()
                .required('Champ obligatoire'),
            // idNature: Yup.string()
            //     .required('Champ obligatoire'),
            idRubrique: Yup.string()
                .required('Champ obligatoire'),
            montantTTC: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le montant ne peut pas être négative"),
            dateBesoin: Yup.string()
                .required('Champ obligatoire'),
            modePaiement: Yup.string()
                .required('Champ obligatoire'),
            typeJustificatif: Yup.string()
                .required('Champ obligatoire'),
            // numeroTransactionFinanciere: Yup.string().test('numeroTransactionFinanciere-validation', 'Invalid numeroTransactionFinanciere', function (value) {
            //     const modePaiement = this.resolve(Yup.ref('modePaiement'));

            //     if (modePaiement && modePaiement === "cheque") {
            //         if (!value) {
            //             return this.createError({ message: 'Champ obligatoire' });
            //         }
            //     }

            //     return true;
            // }),
        }),
        onSubmit: async (values) => {
            try {
                // const postData = {
                //     idAgence: values.idAgence,
                //     idEntreprise: values.idEntreprise,
                //     idNature: values.idNature,
                //     idRubrique: values.idRubrique,
                //     dateBesoin: values.dateBesoin,
                //     description: values.description,
                //     idCaisseDecaissement: values.idCaisseDecaissement.idCaisse,
                //     modePaiement: values.modePaiement,
                //     numeroTransactionFinanciere: values.numeroTransactionFinanciere,
                //     typeJustificatif: values.typeJustificatif,
                //     montantTTC: values.montantTTC,
                //     montantHT: values.montantHT,
                //     montantTVA: values.montantTVA,
                //     montantDroitsTimbre: values.montantDroitsTimbre,
                //     justificationFile: pathImageDepense,
                //     idUtilisateurCrud: authData.idUtilisateur
                // };
                // console.log(postData);
                const formData = new FormData();
                //formData.append('idAgence', values.idAgence.idAgence);
                formData.append('idAgence', values.idAgence);
                formData.append('idEntreprise', values.idEntreprise);
                // formData.append('idNature', values.idNature);
                formData.append('idRubrique', values.idRubrique);
                formData.append('dateBesoin', values.dateBesoin);
                formData.append('description', values.description);
                formData.append('idCaisseDecaissement', values.idCaisseDecaissement.idCaisse);
                formData.append('modePaiement', values.modePaiement);
                if (values.modePaiement === "cheque" || values.modePaiement === "virement") {
                    formData.append('numeroTransactionFinanciere', values.numeroTransactionFinanciere);
                }
                formData.append('typeJustificatif', values.typeJustificatif);
                formData.append('montantTTC', values.montantTTC.toString().replace('.', ','));

                if (values.typeJustificatif === "facture") {
                    formData.append('montantHT', values.montantHT.toString().replace('.', ','));
                    formData.append('montantTVA', values.montantTVA.toString().replace('.', ','));
                    if (values.modePaiement === "espece") {
                        formData.append('montantDroitsTimbre', values.montantDroitsTimbre.toString().replace('.', ','));
                    }
                }

                formData.append('justificationFile', pathImageDepense);
                //formData.append('role', authData.role);
                formData.append('idUtilisateurCrud', authData.idUtilisateur);

                // console.log(formData);

                await axiosInstance.post(Depense, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                CustomToast("La dépense a été créée avec succès", 'success');
                getDepenses(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Utilisateur n'existe pas.") {
                    CustomToast("Utilisateur n'existe pas.", 'error');
                } else if (error.response?.status === 401 && error.response?.data === "Utilisateur n'est pas autorisé.") {
                    CustomToast("Utilisateur n'est pas autorisé.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier une dépense
    // const showHideUpdateModal = async(val, idDepence, idAgence, idEntreprise, idNature, idRubrique, montantTTC, montantHT, montantTVA, montantDroitsTimbre, modePaiement, numeroTransactionFinanciere, typeJustificatif, idCaisseDecaissement, dateBesoin, description, justificationFile) => {
    //     setShowUpdateModal(val);
    //     formik2.resetForm();
    //     if (val) {
    //         getNatures();
    //         getAgencesUpdate();
    //         getEntreprises();
    //         //getCaisses('');
    //         setPathImageDepenseUpdate(null);
    //         getRubriques(idNature);
    //         // getCaisseById(idCaisseDecaissement);
    //         //console.log(justificationFile)
    //         //getAgenceById(idAgence);
    //         formik2.setValues({
    //             idDepence: idDepence,
    //             idAgenceUpdate: idAgence || '',
    //             idEntrepriseUpdate: idEntreprise || '',
    //             idNatureUpdate: idNature || '',
    //             idRubriqueUpdate: idRubrique || '',
    //             montantTTCUpdate: montantTTC || '',
    //             montantHTUpdate: montantHT || '',
    //             montantTVAUpdate: montantTVA || '',
    //             montantDroitsTimbreUpdate: montantDroitsTimbre || '',
    //             modePaiementUpdate: modePaiement || '',
    //             numeroTransactionFinanciereUpdate: numeroTransactionFinanciere || '',
    //             typeJustificatifUpdate: typeJustificatif || '',
    //             //idCaisseDecaissementUpdate: idCaisseDecaissement || null,
    //             dateBesoinUpdate: format(parseISO(dateBesoin), 'yyyy-MM-dd') || '',
    //             descriptionUpdate: description || '',
    //             justificationFileUpdate: justificationFile || '',
    //         });

    //         ///console.log(format(parseISO(dateBesoin), 'yyyy-MM-dd'));

    //         setPathImageDepenseName(justificationFile || '');

    //         if (idCaisseDecaissement) {
    //             await getCaisseById(idCaisseDecaissement);
    //         }

    //     }
    // };

    const [loadingDepense, setLoadingDepense] = useState(false);
    const showHideUpdateModal = async (val, idDepence, idNature, justificationFile, idAgenceCreation) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        setPathImageDepenseUpdate(null);
        if (val) {
            setLoadingDepense(true);
            await getDepenseById(idDepence);
            getNatures();
            getAgencesUpdate(idAgenceCreation);
            getEntreprises();
            //getRubriques(idNature);
            setPathImageDepenseName(justificationFile || '');
            setLoadingDepense(false);
        }
    };

    const handlePathImageDepenseChangeUpdate = (e) => {
        setPathImageDepenseUpdate(e.target.files[0]);
        setPathImageDepenseName('');
    };

    const formik2 = useFormik({
        initialValues: {
            idDepence: '',
            idAgenceUpdate: '',
            idEntrepriseUpdate: '',
            // idNatureUpdate: '',
            idRubriqueUpdate: '',
            montantTTCUpdate: '',
            montantHTUpdate: '',
            montantTVAUpdate: '',
            montantDroitsTimbreUpdate: '',
            idCaisseDecaissementUpdate: null,
            dateBesoinUpdate: '',
            descriptionUpdate: '',
            modePaiementUpdate: '',
            numeroTransactionFinanciereUpdate: '',
            typeJustificatifUpdate: '',
            justificationFileUpdate: '',
        },
        validationSchema: Yup.object({
            idCaisseDecaissementUpdate: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            idAgenceUpdate: Yup.string()
                .required('Champ obligatoire'),
            idEntrepriseUpdate: Yup.string()
                .required('Champ obligatoire'),
            // idNatureUpdate: Yup.string()
            //     .required('Champ obligatoire'),
            idRubriqueUpdate: Yup.string()
                .required('Champ obligatoire'),
            montantTTCUpdate: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le montant ne peut pas être négative"),
            dateBesoinUpdate: Yup.string()
                .required('Champ obligatoire'),
            modePaiementUpdate: Yup.string()
                .required('Champ obligatoire'),
            typeJustificatifUpdate: Yup.string()
                .required('Champ obligatoire'),
            // numeroTransactionFinanciereUpdate: Yup.string().test('numeroTransactionFinanciereUpdate-validation', 'Invalid numeroTransactionFinanciereUpdate', function (value) {
            //     const modePaiement = this.resolve(Yup.ref('modePaiementUpdate'));

            //     if (modePaiement && modePaiement === "cheque") {
            //         if (!value) {
            //             return this.createError({ message: 'Champ obligatoire' });
            //         }
            //     }

            //     return true;
            // }),
        }),
        onSubmit: async (values) => {
            try {
                // const postData = {
                //     idDepence: values.idDepence,
                //     idAgence: values.idAgenceUpdate,
                //     idEntreprise: values.idEntrepriseUpdate,
                //     idNature: values.idNatureUpdate,
                //     idRubrique: values.idRubriqueUpdate,
                //     dateBesoin: values.dateBesoinUpdate,
                //     description: values.descriptionUpdate,
                //     idCaisseDecaissement: values.idCaisseDecaissementUpdate.idCaisse,
                //     modePaiement: values.modePaiementUpdate,
                //     numeroTransactionFinanciere: values.numeroTransactionFinanciereUpdate,
                //     typeJustificatif: values.typeJustificatifUpdate,
                //     montantTTC: values.montantTTCUpdate,
                //     montantHT: values.montantHTUpdate,
                //     montantTVA: values.montantTVAUpdate,
                //     montantDroitsTimbre: values.montantDroitsTimbreUpdate,
                //     justificationFile: pathImageDepenseUpdate,
                //     justificationFileName: pathImageDepenseName,
                //     idUtilisateurCrud: authData.idUtilisateur
                // };
                // console.log(postData);
                const formData = new FormData();
                formData.append('idDepence', values.idDepence);
                formData.append('idAgence', values.idAgenceUpdate);
                formData.append('idEntreprise', values.idEntrepriseUpdate);
                // formData.append('idNature', values.idNatureUpdate);
                formData.append('idRubrique', values.idRubriqueUpdate);
                formData.append('dateBesoin', values.dateBesoinUpdate);
                formData.append('description', values.descriptionUpdate);
                formData.append('idCaisseDecaissement', values.idCaisseDecaissementUpdate.idCaisse);
                formData.append('modePaiement', values.modePaiementUpdate);
                if (values.modePaiementUpdate === "cheque" || values.modePaiementUpdate === "virement") {
                    formData.append('numeroTransactionFinanciere', values.numeroTransactionFinanciereUpdate);
                }
                formData.append('typeJustificatif', values.typeJustificatifUpdate);
                formData.append('montantTTC', values.montantTTCUpdate.toString().replace('.', ','));

                if (values.typeJustificatifUpdate === "facture") {
                    formData.append('montantHT', values.montantHTUpdate.toString().replace('.', ','));
                    formData.append('montantTVA', values.montantTVAUpdate.toString().replace('.', ','));
                    if (values.modePaiementUpdate === "espece") {
                        formData.append('montantDroitsTimbre', values.montantDroitsTimbreUpdate.toString().replace('.', ','));
                    }
                }

                formData.append('justificationFile', pathImageDepenseUpdate);
                formData.append('justificationFileName', pathImageDepenseName);
                formData.append('idUtilisateurCrud', authData.idUtilisateur);

                await axiosInstance.put(Depense, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                CustomToast("Dépense a été modifiée avec succès", 'success');
                getDepenses(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
                    CustomToast("Dépense n'existe pas.", 'error');
                } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.") {
                    CustomToast("L'utilisateur n'est pas autorisé à modifier une dépense validée ou refusée.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer une dépense
    const handleConfirmDelete = (idDepense) => {
        setIdDepenseDelete(idDepense);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Depense}/${idDepenseDelete}`);
            setIdDepenseDelete('');
            CustomToast("La dépense a été supprimée avec succès", 'success');
            getDepenses(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
                CustomToast("Dépense n'existe pas.", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "Utilisateur n'est pas autorisé.") {
                CustomToast("L'utilisateur n'est pas autorisé à supprimer une dépense validée ou refusée.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Valider une depense
    const handleConfirmValiderDepense = (idDepense) => {
        setIdDepenseValider(idDepense);
        setConfirmDialogOpenValiderDepense(true);
    };

    const handleValiderDepense = async () => {
        try {
            let statut;
            if (authData.role === "DirecteurRegional") {
                statut = "ValiderDirecteurRegionnal";
            } else if (authData.role === "Approbateur") {
                statut = "ValiderApprobateur";
            } else if (authData.role === "Caissier") {
                statut = "Decaisser";
            } else if (authData.role === "ChefStation") {
                statut = "Decaisser";
            } else if (authData.role === "AssistanteDirection") {
                statut = "Decaisser";
            } else {
                CustomToast("L'utilisateur n'est pas autorisé à valider ou refuser la dépense.", 'info');
                return;
            }
            const postData = {
                idDepence: idDepenseValider,
                statut: statut,
                idAgenceDecaissement: authData.idAgence,
                idUtilisateurCrud: authData.idUtilisateur
            };
            const response = await axiosInstance.put(`${Depense}/UpdateStatutDepense`, postData);
            setConfirmDialogOpenValiderDepense(false);
            CustomToast("La dépense a été validée avec succès", 'success');
            getDepenses(1, pageSize);
            if (statut === "Decaisser") {
                //afficher pop-up de confirmation pour imprimer la decharge en utilisant setIdDepenseValider('');
                setConfirmDialogOpenImprimerDecharge(true);
            } else {
                setIdDepenseValider('');
            }
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Utilisateur n'existe pas.") {
                CustomToast("Utilisateur n'existe pas.", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
                CustomToast("Dépense n'existe pas.", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.") {
                CustomToast("L'utilisateur n'est pas autorisé à modifier une dépense validée ou refusée.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelValiderDepense = () => {
        setConfirmDialogOpenValiderDepense(false);
    };

    // //Valider depense decaissée
    const handleConfirmValiderDepenseDecaissementValiderNonValider = (idDepense) => {
        setIdDepenseValider(idDepense);
        setConfirmDialogOpenValiderDepenseDecaissementValiderNonValider(true);
    };

    const handleValiderDepenseDecaissementValiderNonValider = async () => {
        try {
            let statut;
            if (authData.role === "Financier") {
                statut = "DecaissementValider";
            } else {
                CustomToast("L'utilisateur n'est pas autorisé à valider ou refuser le décaissement.", 'info');
                return;
            }
            const postData = {
                idDepence: idDepenseValider,
                statut: statut,
                idAgenceDecaissement: authData.idAgence,
                idUtilisateurCrud: authData.idUtilisateur
            };
            const response = await axiosInstance.put(`${Depense}/UpdateStatutDepense`, postData);
            setConfirmDialogOpenValiderDepenseDecaissementValiderNonValider(false);
            CustomToast("La dépense a été validée avec succès", 'success');
            getDepenses(1, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Utilisateur n'existe pas.") {
                CustomToast("Utilisateur n'existe pas.", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
                CustomToast("Dépense n'existe pas.", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.") {
                CustomToast("L'utilisateur n'est pas autorisé à modifier une dépense validée ou refusée.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelValiderDepenseDecaissementValiderNonValider = () => {
        setConfirmDialogOpenValiderDepenseDecaissementValiderNonValider(false);
    };

    //Refuser une depense
    const formik3 = useFormik({
        initialValues: {
            idDepence: '',
            statut: '',
            motifRefus: ''
        },
        validationSchema: Yup.object({
            motifRefus: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                let statut;
                if (authData.role === "DirecteurRegional") {
                    statut = "RefuserDirecteurRegionnal";
                } else if (authData.role === "Approbateur") {
                    statut = "RefuserApprobateur";
                } else {
                    CustomToast("L'utilisateur n'est pas autorisé à valider ou refuser la dépense.", 'info');
                    return;
                }
                const postData = {
                    idDepence: idDepenseValider,
                    statut: statut,
                    motifRefus: values.motifRefus,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                const response = await axiosInstance.put(`${Depense}/UpdateStatutDepense`, postData);
                setIdDepenseValider('');
                setConfirmDialogOpenRefuserDepense(false);
                CustomToast("La dépense a été refusée avec succès", 'success');
                getDepenses(1, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
                    CustomToast("Dépense n'existe pas.", 'error');
                } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.") {
                    CustomToast("L'utilisateur n'est pas autorisé à modifier une dépense validée ou refusée.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const handleConfirmRefuserDepense = (idDepense) => {
        setIdDepenseValider(idDepense);
        setConfirmDialogOpenRefuserDepense(true);
        formik3.resetForm();
    };

    // const handleRefuserDepense = async () => {
    //     try {
    //         let statut;
    //         if (authData.role === "Admin") {
    //             statut = "RefuserGerant";
    //         } else if (authData.role === "DirecteurRegional") {
    //             statut = "RefuserDirecteurRegionnal";
    //         } else if (authData.role === "Financier") {
    //             statut = "RefuserFinancier";
    //         } else {
    //             CustomToast("L'utilisateur n'est pas autorisé à valider ou refuser la dépense.", 'info');
    //             return;
    //         }
    //         const postData = {
    //             idDepence: idDepenseValider,
    //             statut: statut,
    //             motifRefus: statut,
    //             idUtilisateurCrud: authData.idUtilisateur
    //         };
    //         const response = await axiosInstance.put(`${Depense}/UpdateStatutDepense`, postData);
    //         setIdDepenseValider('');
    //         setConfirmDialogOpenRefuserDepense(false);
    //         CustomToast("La dépense a été refusée avec succès", 'success');
    //         getDepenses(1, pageSize);
    //     }
    //     catch (error) {
    //         // Handle error
    //         if (!error?.response) {
    //             CustomToast("Aucune réponse du serveur", 'error');
    //         } else if (error.response?.status === 404 && error.response?.data === "Utilisateur n'existe pas.") {
    //             CustomToast("Utilisateur n'existe pas.", 'error');
    //         } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
    //             CustomToast("Dépense n'existe pas.", 'error');
    //         } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.") {
    //             CustomToast("L'utilisateur n'est pas autorisé à modifier une dépense validée ou refusée.", 'error');
    //         } else if (error.response?.status === 401) {
    //             CustomToast("Non autorisé", 'error');
    //         } else {
    //             CustomToast("Demande échoué", 'error');
    //         }
    //         console.log(error);
    //     }
    // }

    const handleCancelRefuserDepense = () => {
        setConfirmDialogOpenRefuserDepense(false);
    };

    //Refuser un décaissement
    const formik4 = useFormik({
        initialValues: {
            idDepence: '',
            statut: '',
            motifRefusFinancier: ''
        },
        validationSchema: Yup.object({
            motifRefusFinancier: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                let statut;
                if (authData.role === "Financier") {
                    statut = "DecaissementNonValider";
                } else {
                    CustomToast("L'utilisateur n'est pas autorisé à valider ou refuser le décaissement.", 'info');
                    return;
                }
                const postData = {
                    idDepence: idDepenseValider,
                    statut: statut,
                    motifRefus: values.motifRefusFinancier,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                const response = await axiosInstance.put(`${Depense}/UpdateStatutDepense`, postData);
                setIdDepenseValider('');
                setConfirmDialogOpenRefuserDepenseDecaissementValiderNonValider(false);
                CustomToast("Le décaissement a été refusée avec succès", 'success');
                getDepenses(1, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
                    CustomToast("Dépense n'existe pas.", 'error');
                } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.") {
                    CustomToast("L'utilisateur n'est pas autorisé à modifier une dépense validée ou refusée.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const handleConfirmRefuserDepenseDecaissementValiderNonValider = (idDepense) => {
        setIdDepenseValider(idDepense);
        setConfirmDialogOpenRefuserDepenseDecaissementValiderNonValider(true);
        formik4.resetForm();
    };

    const handleCancelRefuserDepenseDecaissementValiderNonValider = () => {
        setConfirmDialogOpenRefuserDepenseDecaissementValiderNonValider(false);
    };

    //Annuler demande
    const formik5 = useFormik({
        initialValues: {
            idDepence: '',
            motifAnnulation: ''
        },
        validationSchema: Yup.object({
            motifAnnulation: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const roleCancel = ["SuperAdmin", "Admin", "Manager", "ResponsableRegional", "ChefStation", "AssistanteDirection"];
                if (!roleCancel.includes(authData.role)) {
                    CustomToast("L'utilisateur n'est pas autorisé à annuler la demande de dépense.", 'info');
                    return;
                }
                const postData = {
                    idDepence: idDepenseAnnuler,
                    motifAnnulation: values.motifAnnulation,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                const response = await axiosInstance.put(`${Depense}/AnnulerDepense`, postData);
                setIdDepenseAnnuler('');
                setConfirmDialogOpenAnnuler(false);
                CustomToast("La demande a été annulée avec succès", 'success');
                getDepenses(1, pageSize);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
                    CustomToast("Dépense n'existe pas.", 'error');
                } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.") {
                    CustomToast("L'utilisateur n'est pas autorisé à modifier une dépense validée ou refusée.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    const handleConfirmAnnulerDemande = (idDepense) => {
        setIdDepenseAnnuler(idDepense);
        setConfirmDialogOpenAnnuler(true);
        formik5.resetForm();
    };

    const handleCancelAnnulerDemande = () => {
        setConfirmDialogOpenAnnuler(false);
    };

    //Imprimer decharge   
    const handleCancelImprimerDecharge = () => {
        setConfirmDialogOpenImprimerDecharge(false);
    };

    const fetchPdfTemplate = async (entreprise) => {

        const decharges = {
            YALIDINE: 'assets/decharges/DECHARGE_YALIDINE.pdf',
            VARBIOF: 'assets/decharges/DECHARGE_VARBIOF.pdf',
            'EASY AND SPEED': 'assets/decharges/DECHARGE_EASY_SPEED.pdf',
            GUEPEX: 'assets/decharges/DECHARGE_GUEPEX.pdf',
            OURQUILANE: 'assets/decharges/DECHARGE_OURQUILANE.pdf',
            'SPEED MAIL': 'assets/decharges/DECHARGE_SPEED_MAIL.pdf',
            YALITEC: 'assets/decharges/DECHARGE_YALITEC.pdf',
            'ZIMOU EXPRESS': 'assets/decharges/DECHARGE_ZIMOU.pdf',
        };

        const decharge = decharges[entreprise] || decharges['YALIDINE'];

        const existingPdfBytes = await fetch(decharge).then(res => res.arrayBuffer());
        return existingPdfBytes;
    };

    const generateBarcode = async (text) => {
        const canvas = document.createElement('canvas');
        canvas.id = 'myCanvas'; // Définir l'ID du canvas
        document.body.appendChild(canvas); // Ajouter le canvas au DOM

        // Générer le code-barres sur le canvas
        await bwipjs.toCanvas(canvas, {
            bcid: 'code128',
            text: text,
            scale: 3,
            height: 8,
            paddingheight: 2,
            includetext: true,
            textxalign: 'center',
        });

        // Convertir le canvas en URL de données
        const dataUrl = canvas.toDataURL('image/png');

        // Supprimer le canvas du DOM
        document.body.removeChild(canvas);

        return dataUrl;
    };

    const imprimerDecharge = async (data) => {
        //console.log(data)
        // Charger le template PDF
        const arrayBuffer = await fetchPdfTemplate(data.libelleEntreprise);

        // Charger le PDF
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        //const { width, height } = firstPage.getSize();

        //console.log("W=" ,width," h=",height)

        // Générer le code-barres
        const barcodeDataURL = await generateBarcode(data.numValidation);

        // Charger le code-barres sous forme d'image dans un objet Uint8Array
        const response = await fetch(barcodeDataURL);
        const barcodeBytes = new Uint8Array(await response.arrayBuffer());

        // Insérer le code-barres dans la première page
        const barcodeImage = await pdfDoc.embedPng(barcodeBytes);
        firstPage.drawImage(barcodeImage, {
            x: 180, // Position x du code-barres sur la page
            y: 670, // Position y du code-barres sur la page
            width: barcodeImage.width * 0.6, // Largeur du code-barres
            height: barcodeImage.height * 0.6, // Hauteur du code-barres
        });

        if (data.reference) {
            firstPage.drawText(data.reference, {
                x: 94,
                y: 754,
                size: 10.5,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (data.wilayaAgence) {
            const boldText = data.wilayaAgence + ' le: ';
            firstPage.drawText(boldText, {
                x: 400,
                y: 736,
                size: 12,
                font: helveticaFontBold,
                color: rgb(0, 0, 0),
                bold: true,
            });

            const boldTextWidth = helveticaFontBold.widthOfTextAtSize(boldText, 12);
            firstPage.drawText(format(new Date(data.dateCreate), "dd/MM/yyyy HH:mm:ss"), {
                x: 400 + boldTextWidth,
                y: 736,
                size: 10.5,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (data.utilisateurBeneficiare) {
            firstPage.drawText(data.utilisateurBeneficiare, {
                x: 120,
                y: 597,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (data.libelleAgence) {
            firstPage.drawText(data.libelleAgence, {
                x: 95,
                y: 568.5,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (data.montant) {
            firstPage.drawText(formatCurrency(data.montant).replace(/\u202F/g, ' ') + ' ' + authData?.unite_moneitaire, {
                x: 92,
                y: 510.5,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (data.libelleNature) {
            firstPage.drawText(data.libelleNature + " " + data.libelleRubrique, {
                x: 72,
                y: 482,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (data.dateDemande) {
            firstPage.drawText(format(new Date(data.dateDemande), "dd/MM/yyyy HH:mm:ss"), {
                x: 160,
                y: 452,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (data.utilisateurApprouve) {
            firstPage.drawText(data.utilisateurApprouve, {
                x: 125,
                y: 424,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        if (data.numValidation) {
            firstPage.drawText(data.numValidation, {
                x: 120,
                y: 396,
                size: 12,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            });
        }

        // Enregistrer le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');
    };

    const handleImprimerDecharge = async () => {
        try {
            const response = await axiosInstance.get(`${Decharge}/GetDechargeByIdDepense/${idDepenseValider}`);
            await imprimerDecharge(response.data);
            setIdDepenseValider('');
            setConfirmDialogOpenImprimerDecharge(false);
            CustomToast("La décharge a été imprimée avec succès", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Utilisateur n'existe pas.") {
                CustomToast("Utilisateur n'existe pas.", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Dépense n'existe pas.") {
                CustomToast("Dépense n'existe pas.", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.") {
                CustomToast("L'utilisateur n'est pas autorisé à modifier une dépense validée ou refusée.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Exporter Excel
    const handleExport = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if ((authData?.idUtilisateur == null || authData?.idUtilisateur == "") || (authData?.idEntreprise == null || authData?.idEntreprise == "") || (authData?.idAgence == null || authData?.idAgence == "") || (authData?.role == null || authData?.role == "")) {
                return;
            }

            if ((authData?.role == "Caissier" || authData?.role == "ChefStation" || authData?.role == "AssistanteDirection") && (authData?.idCaisse != null && authData?.idCaisse != "")) {
                queryParams.append("idCaisse", authData?.idCaisse);
            }

            if (authData?.role === "ResponsableRegional" || authData?.role === "DirecteurRegional") {
                authData?.idAgencesRegion.forEach(id => queryParams.append('idAgencesRegion', id));
                authData?.idCaissesRegion.forEach(id => queryParams.append('idCaissesRegion', id));
            }

            queryParams.append("idUtilisateur", authData?.idUtilisateur);
            queryParams.append("idEntreprise", authData?.idEntreprise);
            queryParams.append("idAgence", authData?.idAgence);
            queryParams.append("role", authData?.role);


            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            // if (filters.demandeur !== '') {
            //     queryParams.append("demandeur", filters.demandeur);
            // }

            if (filters.idNature !== '') {
                queryParams.append("idNature", filters.idNature);
            }

            if (filters.idRubrique !== '') {
                queryParams.append("idRubrique", filters.idRubrique);
            }

            if (filters.dateDebut !== '') {
                queryParams.append("dateDebut", filters.dateDebut);
            }

            if (filters.dateFin !== '') {
                queryParams.append("dateFin", filters.dateFin);
            }


            const response = await axiosInstance.get(`${Depense}/GetListeDepensesExcel?${queryParams}`);
            const resp = await response.data;

            const Heading = [
                [
                    "Agence",
                    "Entreprise",
                    "Nature",
                    "Code comptable",
                    "Rubrique",
                    "Caisse",
                    "Date de besoin",
                    "Mode de paiement",
                    "N° chèque",
                    "Type de justificatif",
                    "Montant TTC",
                    "Montant HT",
                    "Montant TVA",
                    "Timbre",
                    "Dernier statut",
                    "Créé par",
                    "Date de création",
                    "Directeur régional",
                    "Date de validation-Refus (Directeur)",
                    "Motif refus (Directeur)",
                    "Approbateur",
                    "Date de validation-Refus (Approbateur)",
                    "Motif refus (Approbateur)",
                    "Décaissée par",
                    "Date de décaisssement",
                    "Annuler par",
                    "Date annulation",
                    "Motif annulation",
                    "Décaissement Validé-Refusé par (Financier)",
                    "Date de validation-Refus de décaissement (Financier)",
                    "Motif refus (Financier)",
                ]
            ];

            let fileName = "Depenses-" + format(new Date(), "yyyyMMddHHmmss");
            const fileExtension = ".xlsx";
            const blob = ExportToExcel(resp, fileName, fileExtension, "Depenses", Heading);

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            CustomToast(<><strong>Terminé : Exportation xlsx de liste des dépenses</strong><br></br><a href={url} download={fileName + fileExtension}>Cliquez ici si le téléchargement ne démarre pas</a></>, 'success', 60000);

            // Clean up the URL object after the download
            //window.URL.revokeObjectURL(url);         

        }
        catch (error) {
            console.log(error);
        }
    }

    //Load data

    // const getDepenses = async (page, newPerPage) => {
    //     try {
    //         setLoading(true);

    //         const queryParams = new URLSearchParams({
    //             pageNumber: page,
    //             pageSize: newPerPage
    //         });

    //         if ((authData?.idUtilisateur == null || authData?.idUtilisateur == "") || (authData?.idEntreprise == null || authData?.idEntreprise == "") || (authData?.idAgence == null || authData?.idAgence == "") || (authData?.role == null || authData?.role == "")) {
    //             return;
    //         }

    //         if (authData?.role == "Caissier" && (authData?.idCaisse != null && authData?.idCaisse != "")) {
    //             queryParams.append("idCaisse", authData?.idCaisse);
    //         }


    //         queryParams.append("idUtilisateur", authData?.idUtilisateur);
    //         queryParams.append("idEntreprise", authData?.idEntreprise);
    //         queryParams.append("idAgence", authData?.idAgence);
    //         queryParams.append("role", authData?.role);


    //         if (debouncedSearchText != null) {
    //             queryParams.append("text", debouncedSearchText);
    //         }

    //         if (filters.statut !== '') {
    //             queryParams.append("statut", filters.statut);
    //         }

    //         // if (filters.demandeur !== '') {
    //         //     queryParams.append("demandeur", filters.demandeur);
    //         // }

    //         if (filters.idCategorie !== '') {
    //             queryParams.append("idCategorie", filters.idCategorie);
    //         }

    //         if (filters.idSousCategorie !== '') {
    //             queryParams.append("idSousCategorie", filters.idSousCategorie);
    //         }

    //         if (filters.dateDebut !== '') {
    //             queryParams.append("dateDebut", filters.dateDebut);
    //         }

    //         if (filters.dateFin !== '') {
    //             queryParams.append("dateFin", filters.dateFin);
    //         }


    //         const response = await axiosInstance.get(`${Depense}/GetListeDepenses?${queryParams}`);
    //         const resp = await response.data;
    //         setDepenses(resp.data);
    //         setFiltredDepenses(resp.data);
    //         setPageNumber(resp.pageNumber);
    //         setPageSize(resp.pageSize);
    //         setTotalItems(resp.totalItems);
    //         setTotalPages(resp.totalPages);
    //         setLoading(false);
    //     }
    //     catch (error) {
    //         // Handle error
    //         if (!error?.response) {
    //             CustomToast("Aucune réponse du serveur", 'error');
    //         } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé.") {
    //             CustomToast("L'utilisateur n'est pas autorisé.", 'error');
    //         } else if (error.response?.status === 401) {
    //             CustomToast("Non autorisé", 'error');
    //         } else {
    //             CustomToast("Demande échoué", 'error');
    //         }
    //         console.log(error);
    //         setLoading(false);
    //     }
    // }

    const getDepenses = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if ((authData?.idUtilisateur == null || authData?.idUtilisateur == "") || (authData?.idEntreprise == null || authData?.idEntreprise == "") || (authData?.idAgence == null || authData?.idAgence == "") || (authData?.role == null || authData?.role == "")) {
                return;
            }

            if ((authData?.role == "Caissier" || authData?.role == "ChefStation" || authData?.role == "AssistanteDirection") && (authData?.idCaisse != null && authData?.idCaisse != "")) {
                queryParams.append("idCaisse", authData?.idCaisse);
            }

            // if ((authData?.role == "ResponsableRegional" || authData?.role == "DirecteurRegional") && (authData?.idAgencesRegion != null && authData?.idCaissesRegion != null)) {
            //     queryParams.append("idAgencesRegion", authData?.idAgencesRegion);
            //     queryParams.append("idCaissesRegion", authData?.idCaissesRegion);
            // }
            if (authData?.role === "ResponsableRegional" || authData?.role === "DirecteurRegional") {
                // queryParams.append("idAgencesRegion", authData?.idAgencesRegion.join(','));
                // queryParams.append("idCaissesRegion", authData?.idCaissesRegion.join(','));
                authData?.idAgencesRegion.forEach(id => queryParams.append('idAgencesRegion', id));
                authData?.idCaissesRegion.forEach(id => queryParams.append('idCaissesRegion', id));
            }


            queryParams.append("idUtilisateur", authData?.idUtilisateur);
            queryParams.append("idEntreprise", authData?.idEntreprise);
            queryParams.append("idAgence", authData?.idAgence);
            queryParams.append("role", authData?.role);

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            // if (filters.demandeur !== '') {
            //     queryParams.append("demandeur", filters.demandeur);
            // }

            if (filters.idNature !== '') {
                queryParams.append("idNature", filters.idNature);
            }

            if (filters.idRubrique !== '') {
                queryParams.append("idRubrique", filters.idRubrique);
            }

            if (filters.dateDebut !== '') {
                queryParams.append("dateDebut", filters.dateDebut);
            }

            if (filters.dateFin !== '') {
                queryParams.append("dateFin", filters.dateFin);
            }

            const response = await axiosInstance.get(`${Depense}/GetListeDepenses?${queryParams}`, { cancelToken });
            const resp = await response.data;
            setDepenses(resp.data);
            setFiltredDepenses(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                //CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé.") {
                CustomToast("L'utilisateur n'est pas autorisé.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    // const getNaturesFiltre = async () => {
    //     try {
    //         const response = await axiosInstance.get(`${DepenseNature}`);
    //         const resp = await response.data;
    //         setNaturesFilter(resp)
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    const getNaturesFiltre = async () => {
        try {
            //console.log("getRubriquesFiltre")
            //const response = await axiosInstance.get(`${DepenseRubrique}/GetRubriquesDepenseByIdNature/${idNature}`);
            const response = await axiosInstance.get(`${DepenseRubrique}`);
            const resp = await response.data;
            setNaturesFilter(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    // const getNatures = async () => {
    //     try {
    //         const response = await axiosInstance.get(`${DepenseNature}`);
    //         const resp = await response.data;
    //         setNatures(resp)
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    const getNatures = async () => {
        try {
            //console.log("getRubriques")
            //const response = await axiosInstance.get(`${DepenseRubrique}/GetRubriquesDepenseByIdNature/${idNature}`);
            const response = await axiosInstance.get(`${DepenseRubrique}`);
            const resp = await response.data;
            setNatures(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    // const getAgences = async (agence) => {
    //     try {
    //         const queryParams = new URLSearchParams({
    //             pageNumber: 1,
    //             pageSize: 10
    //         });

    //         if (agence !== '') {
    //             queryParams.append("text", agence);
    //         }
    //         const response = await axiosInstance.get(`${Agence}/GetListeAgencesAutocomplete?${queryParams}`);
    //         const resp = await response.data;
    //         setAgences(resp.data);
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    // const getAgenceById = async (idAgence) => {
    //     try {
    //         const response = await axiosInstance.get(`${Agence}/${idAgence}`);
    //         const resp = await response.data;
    //         //setAgences(resp);
    //         formik2.setValues((prevValues) => ({
    //             ...prevValues,
    //             idAgenceUpdate: resp || '',
    //         }));
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    const getAgences = async () => {
        try {
            const queryParams = new URLSearchParams({});

            if ((authData?.idUtilisateur == null || authData?.idUtilisateur == "") || (authData?.idAgence == null || authData?.idAgence == "") || (authData?.role == null || authData?.role == "")) {
                return;
            }

            queryParams.append("idUtilisateur", authData?.idUtilisateur);
            queryParams.append("idAgence", authData?.idAgence);
            queryParams.append("role", authData?.role);

            const response = await axiosInstance.get(`${Agence}/GetListeAgencesByRole?${queryParams}`);
            const resp = await response.data;
            setAgences(resp);

            //if(authData?.role != "ResponsableRegional" && authData?.role != "DirecteurRegional") {
            if (resp.length === 1) {
                formik1.setValues((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    const getAgencesUpdate = async (idAgenceCreation) => {
        try {
            const queryParams = new URLSearchParams({});

            if ((authData?.idUtilisateur == null || authData?.idUtilisateur == "") || (authData?.idAgence == null || authData?.idAgence == "") || (authData?.role == null || authData?.role == "")) {
                return;
            }


            queryParams.append("idUtilisateur", authData?.idUtilisateur);

            // if (authData?.role == "SuperAdmin" || authData?.role == "Admin") {           
            //     queryParams.append("idAgence", idAgenceCreation);
            // }else{
            //     queryParams.append("idAgence", authData?.idAgence);
            // }

            queryParams.append("idAgence", authData?.idAgence);
            queryParams.append("role", authData?.role);

            const response = await axiosInstance.get(`${Agence}/GetListeAgencesByRole?${queryParams}`);
            const resp = await response.data;
            setAgences(resp);

            //if(authData?.role != "ResponsableRegional" && authData?.role != "DirecteurRegional") {
            if (resp.length === 1) {
                formik2.setValues((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    const getEntreprises = async () => {
        try {
            const response = await axiosInstance.get(`${Entreprise}`);
            const resp = await response.data;
            setEntreprises(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCaisses = async (caisse) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 5
            });

            if ((authData?.role == "AssistanteDirection") && (authData?.idCaisse != null && authData?.idCaisse != "")) {
                queryParams.append("idCaisse", authData?.idCaisse);
                queryParams.append("role", authData?.role);
            }

            if (caisse !== '') {
                queryParams.append("text", caisse);
            }else{
                return;
            }
            const response = await axiosInstance.get(`${Caisse}/GetListeCaissesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setCaisses(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    // const getCaisseById = async (idCaisse) => {
    //     try {
    //         const response = await axiosInstance.get(`${Caisse}/${idCaisse}`);
    //         const resp = await response.data;
    //         //setAgences(resp);
    //         // formik2.setValues((prevValues) => ({
    //         //     ...prevValues,
    //         //     idCaisseDecaissementUpdate: resp || null,
    //         // }));

    //         formik2.setFieldValue('idCaisseDecaissementUpdate', resp || null);
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }

    const getDepenseById = async (idDepence) => {
        try {
            const response = await axiosInstance.get(`${Depense}/GetDepenseById/${idDepence}`);
            const resp = await response.data;

            formik2.setValues({
                idDepence: resp.idDepence,
                idAgenceUpdate: resp.idAgence || '',
                idEntrepriseUpdate: resp.idEntreprise || '',
                idNatureUpdate: resp.idNature || '',
                idRubriqueUpdate: resp.idRubrique || '',
                montantTTCUpdate: resp.montantTTC || '',
                montantHTUpdate: resp.montantHT || '',
                montantTVAUpdate: resp.montantTVA || '',
                montantDroitsTimbreUpdate: resp.montantDroitsTimbre || '',
                modePaiementUpdate: resp.modePaiement || '',
                numeroTransactionFinanciereUpdate: resp.numeroTransactionFinanciere || '',
                typeJustificatifUpdate: resp.typeJustificatif || '',
                idCaisseDecaissementUpdate: resp.cfG_CaisseDecaissement || null,
                dateBesoinUpdate: format(parseISO(resp.dateBesoin), 'yyyy-MM-dd') || '',
                descriptionUpdate: resp.description || '',
                justificationFileUpdate: resp.justificationFile || '',
            });

            //formik2.setFieldValue('idCaisseDecaissementUpdate', resp || null);
        }
        catch (err) {
            console.log(err);
        }
    }

    // useEffect(() => {
    //     //Load communes
    //     if (filters.idNature !== "" && showFilters) {
    //         getRubriquesFiltre(filters.idNature);
    //     }
    //     else {
    //         setRubriquesFilter([]);
    //     }
    // }, [authData, filters.idNature])

    // useEffect(() => {
    //     if (formik1.values.idNature !== "" && showAddModal) {
    //         getRubriques(formik1.values.idNature);
    //     }
    //     else {
    //         setRubriques([]);
    //     }
    // }, [authData, formik1.values.idNature])

    // useEffect(() => {
    //     if (formik2.values.idNatureUpdate !== "" && showUpdateModal) {
    //         getRubriques(formik2.values.idNatureUpdate);
    //     }
    //     else {
    //         setRubriques([]);
    //     }
    // }, [authData, formik2.values.idNatureUpdate])

    useEffect(() => {
        if (showAddModal) {
            getNatures();
        }
        else {
            setNatures([]);
        }
    }, [authData])

    useEffect(() => {
        if (showUpdateModal) {
            getNatures();
        }
        else {
            setNatures([]);
        }
    }, [authData])

    // // useEffect(() => {
    // //     //if (debouncedDriverText) {
    // //     getAgences(debouncedAgenceText);
    // //     //}
    // //     return () => {
    // //         // Perform any necessary cleanup (e.g., cancel pending requests)
    // //     };
    // // }, [debouncedAgenceText]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getDepenses(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        if (showAddModal || showUpdateModal) {
            getCaisses(debouncedCaisseText);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedCaisseText]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getDepenses(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    return (
        <>
            <div className="container" >
                <div className="affectation-sec wrap-all">
                    {(authData?.role === "SuperAdmin" || authData?.role === "ChefStation" || authData?.role === "Manager" || authData?.role === "ResponsableRegional" || authData?.role === "AssistanteDirection") &&
                        <Link to="#" className="affectation-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Créer une demande de dépense
                            </div>
                        </Link>}

                    <Link to="#" className="affectation-add success decoration-none" onClick={() => handleExport()}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">upgrade</span>
                            Exporter
                        </div>
                    </Link>

                    <div className={`group-elements ${(authData?.role === "SuperAdmin" || authData?.role === "Admin" || authData?.role === "Financier" || authData?.role === "ChefStation" || authData?.role === "Manager" || authData?.role === "ResponsableRegional" || authData?.role === "AssistanteDirection") ? '' : 'without-btn'}`}>
                        <div className="voir-elements">
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <input
                                type="search"
                                value={search}
                                placeholder="Rechercher ..."
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="apps__button--wrapper">
                                <div className="filter-sw apps__button" onClick={() => showHideFiltre(!showFilters)}>
                                    <span className="material-symbols-outlined">tune</span>
                                    <p>Filter</p>
                                </div>
                                {showFilters && <div className="dropdown__wrapper dropdown__wrapper--fade-in">
                                    <form onSubmit={handleSearch}>
                                        <div className="col">
                                            <div className="row">
                                                <p>Statut</p>
                                                <select
                                                    id="statut"
                                                    name="statut"
                                                    value={filters.statut || ''}
                                                    onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                                                >
                                                    <option value="" defaultValue={true}>Séléctionnez ...</option>
                                                    <option value="CreerChefDeStation"> Créée par le chef de station</option>
                                                    <option value="CreerResponsable"> Créée par le responsable</option>
                                                    <option value="CreerResponsableRegionnal"> Créée par le responsable régionnal</option>
                                                    <option value="CreerAssistantDirection"> Créée par l'assistant(e) de direction</option>
                                                    <option value="ValiderDirecteurRegionnal"> Validée par le directeur régionnal</option>
                                                    <option value="RefuserDirecteurRegionnal"> Refusée par le directeur régionnal</option>
                                                    {/* <option value="ValiderFinancier"> Validée par le financier</option>
                                                    <option value="RefuserFinancier"> Refusée par le financier</option> */}
                                                    <option value="ValiderApprobateur"> Validée par l'approbateur</option>
                                                    <option value="RefuserApprobateur"> Refusée par l'approbateur</option>
                                                    <option value="Decaisser"> Décaissée par le caissier</option>
                                                    <option value="DemandeAnnulee"> Demande annulée</option>
                                                    <option value="DecaissementValider"> Décaissement validé</option>
                                                    <option value="DecaissementNonValider"> Décaissement non validé</option>
                                                </select>
                                            </div>
                                            <div className="row">
                                                <p>Nature</p>
                                                <select
                                                    id="idRubrique"
                                                    name="idRubrique"
                                                    value={filters.idRubrique || ''}
                                                    onChange={(e) => setFilters({ ...filters, idRubrique: e.target.value || '' })}
                                                >
                                                    <option value="">Séléctionnez ...</option>
                                                    {naturesFilter.map((nature) => (
                                                        <option key={nature.idRubrique} value={nature.idRubrique}>{nature.libelle}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <div className="row">
                                                <p>Rubrique</p>
                                                <select
                                                    id="idRubrique"
                                                    name="idRubrique"
                                                    value={filters.idRubrique || ''}
                                                    onChange={(e) => setFilters({ ...filters, idRubrique: e.target.value || '' })}
                                                >
                                                    <option value="">Séléctionnez ...</option>
                                                    {rubriquesFilter.map((rubrique) => (
                                                        <option key={rubrique.idRubrique} value={rubrique.idRubrique}>{rubrique.libelle}</option>
                                                    ))}
                                                </select>
                                            </div> */}
                                            <div className="row">
                                                <p style={{ opacity: 0 }}>Date</p>
                                                <input
                                                    type="date"
                                                    value={filters.dateDebut}
                                                    onChange={(e) => setFilters({ ...filters, dateDebut: e.target.value })}
                                                />
                                                <input
                                                    type="date"
                                                    value={filters.dateFin}
                                                    onChange={(e) => setFilters({ ...filters, dateFin: e.target.value })}
                                                />
                                            </div>
                                            <div className="row">
                                                <button className="submit-btn">Filtrer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                            </div>
                        </div>
                    </div>

                    {filtredDepenses?.length > 0 && (
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Agence</th>
                                        <th>Entreprise</th>
                                        <th>Créée par</th>
                                        <th>Rubrique</th>
                                        <th>Nature</th>
                                        <th>Caisse</th>
                                        <th>Commentaires</th>
                                        <th>Date de création</th>
                                        <th>Date de besoin</th>
                                        <th>Date de décaissement</th>
                                        <th>Montant ttc</th>
                                        <th>Docs</th>
                                        <th>Statut (Action)</th>
                                        <th>Annuler demande</th>
                                        {/* <th>Modifier</th> */}
                                        <th>Supprimer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredDepenses.map((row) => {
                                        ////const isEditable = (["CreerChefDeStation", "CreerResponsable", "CreerResponsableRegionnal", "ValiderDirecteurRegionnal"].includes(row.dernierStatut) && authData.idUtilisateur === row.idUtilisateurCreer) || ([""].includes(row.dernierStatut) && authData.idUtilisateur === row.idUtilisateurCreer);
                                        // const editableStatusesCreator = ["CreerChefDeStation", "CreerResponsable", "CreerResponsableRegionnal", "CreerAssistantDirection"];
                                        // const editableStatusesAdmin = ["CreerChefDeStation", "CreerResponsable", "CreerResponsableRegionnal", "ValiderDirecteurRegionnal", "CreerAssistantDirection"];
                                        const delitableStatusesCreator = ["CreerChefDeStation", "CreerResponsable", "CreerResponsableRegionnal", "CreerAssistantDirection"];
                                        const delitableStatusesAdmin = ["CreerChefDeStation", "CreerResponsable", "CreerResponsableRegionnal", "CreerAssistantDirection"];
                                        const isAdmin = ["SuperAdmin", "Admin"].includes(authData?.role);
                                        const isCreator = authData.idUtilisateur === row.idUtilisateurCreer;
                                        ////const isEditable = (editableStatusesCreator.includes(row.dernierStatut) && isCreator) || (editableStatusesAdmin.includes(row.dernierStatut) && isAdmin);
                                        const isDeletable = (delitableStatusesCreator.includes(row.dernierStatut) && isCreator) || (delitableStatusesAdmin.includes(row.dernierStatut) && isAdmin);
                                        const statut = statutLabels[row.dernierStatut];
                                        const statutClass = statutClasses[row.dernierStatut];

                                        const cancelStatuses = ["CreerChefDeStation", "CreerResponsable", "CreerResponsableRegionnal", "CreerAssistantDirection", "ValiderDirecteurRegionnal", "ValiderApprobateur"];
                                        const isCancel = cancelStatuses.includes(row.dernierStatut) && (isAdmin || isCreator);

                                        return (
                                            <tr key={row.idDepence}>
                                                <td>{row.libelleAgence}</td>
                                                <td>{row.libelleEntreprise}</td>
                                                <td>{row.utilisateurCreer}</td>
                                                <td>{row.libelleRubrique}</td>
                                                <td>{row.libelleNature}</td>
                                                <td>{row.libelleCaisseDecaissement}</td>
                                                <td>{row.description}</td>
                                                <td>{format(new Date(row.createdAt), "dd/MM/yyyy HH:mm:ss")}</td>
                                                <td>{format(new Date(row.dateBesoin), "dd/MM/yyyy")}</td>
                                                <td>{row.dateDecaissement ? (format(new Date(row.dateDecaissement), "dd/MM/yyyy HH:mm:ss")) : ""}</td>
                                                <td style={{ textAlign: "right" }}>{formatCurrency(row.montantTTC)}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    {row.justification ? (
                                                        <span
                                                            className="material-symbols-outlined success"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openFileNewTab(DepenseFile + row.justification)}
                                                        >
                                                            draft
                                                        </span>
                                                    ) : (
                                                        <span className="material-symbols-outlined" style={{ opacity: "0.2" }}>draft</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {authData.role === "DirecteurRegional" && ["CreerChefDeStation", "CreerResponsable", "CreerResponsableRegionnal"].includes(row.dernierStatut) && authData.idAgencesRegion.includes(row.idAgence) ? (
                                                        <div className="button-container">
                                                            <Link to="#" onClick={() => handleConfirmValiderDepense(row.idDepence)}>
                                                                <span className="material-symbols-outlined success">check</span>
                                                            </Link>
                                                            <Link to="#" onClick={() => handleConfirmRefuserDepense(row.idDepence)}>
                                                                <span className="material-symbols-outlined danger">close</span>
                                                            </Link>
                                                        </div>
                                                    ) : authData.role === "Approbateur" && (row.dernierStatut === "ValiderDirecteurRegionnal" || row.dernierStatut === "CreerAssistantDirection") ? (
                                                        <div className="button-container">
                                                            <Link to="#" onClick={() => handleConfirmValiderDepense(row.idDepence)}>
                                                                <span className="material-symbols-outlined">check</span>
                                                            </Link>
                                                            <Link to="#" onClick={() => handleConfirmRefuserDepense(row.idDepence)}>
                                                                <span className="material-symbols-outlined danger">close</span>
                                                            </Link>
                                                        </div>
                                                    ) : (authData.role === "Caissier" && row.dernierStatut === "ValiderApprobateur" && authData.idCaisse === row.idCaisseDecaissement) ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <Link to="#" onClick={() => handleConfirmValiderDepense(row.idDepence)}>
                                                                <span className="material-symbols-outlined">check</span>
                                                            </Link>
                                                        </div>
                                                    ) : (authData.role === "AssistanteDirection" && row.dernierStatut === "ValiderApprobateur" && authData.idCaisse === row.idCaisseDecaissement) ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <Link to="#" onClick={() => handleConfirmValiderDepense(row.idDepence)}>
                                                                <span className="material-symbols-outlined">check</span>
                                                            </Link>
                                                        </div>
                                                    ) : ((authData.role === "ChefStation") && row.dernierStatut === "ValiderApprobateur" && authData.idCaisse === row.idCaisseDecaissement) ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <Link to="#" onClick={() => handleConfirmValiderDepense(row.idDepence)}>
                                                                <span className="material-symbols-outlined">check</span>
                                                            </Link>
                                                        </div>
                                                    ) : authData.role === "Financier" && row.dernierStatut === "Decaisser" ? (
                                                        <div className="button-container">
                                                            <Link to="#" onClick={() => handleConfirmValiderDepenseDecaissementValiderNonValider(row.idDepence)}>
                                                                <span className="material-symbols-outlined">check</span>
                                                            </Link>
                                                            <Link to="#" onClick={() => handleConfirmRefuserDepenseDecaissementValiderNonValider(row.idDepence)}>
                                                                <span className="material-symbols-outlined danger">close</span>
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        <div className={`statut ${statutClass}`}>{statut}</div>
                                                    )}
                                                </td>
                                                <td
                                                    className="txt-center"
                                                    onClick={isCancel ? () => handleConfirmAnnulerDemande(row.idDepence) : null}
                                                    title={isCancel ? '' : 'Vous ne pouvez pas annuler cette dépense'}
                                                >
                                                    <span className="material-symbols-outlined" style={{ opacity: isCancel ? '1' : '0.2', cursor: isCancel ? 'pointer' : 'default' }}>close</span>
                                                </td>
                                                {/* <td
                                                    className="txt-center"
                                                    //onClick={isEditable ? () => showHideUpdateModal(true, row.idDepence, row.idAgence, row.idEntreprise, row.idNature, row.idRubrique, row.montantTTC, row.montantHT, row.montantTVA, row.montantDroitsTimbre, row.modePaiement, row.numeroTransactionFinanciere, row.typeJustificatif, row.idCaisseDecaissement, row.dateBesoin, row.description, row.justification) : null}
                                                    onClick={isEditable ? () => showHideUpdateModal(true, row.idDepence, row.idNature, row.justification, row.idAgence) : null}
                                                    title={isEditable ? '' : 'Vous ne pouvez pas modifier cette dépense'}
                                                >
                                                    <span className="material-symbols-outlined" style={{ opacity: isEditable ? '1' : '0.2', cursor: isEditable ? 'pointer' : 'default' }}>edit</span>
                                                </td> */}
                                                <td
                                                    className="txt-center"
                                                    onClick={isDeletable ? () => handleConfirmDelete(row.idDepence) : null}
                                                    title={isDeletable ? '' : 'Vous ne pouvez pas supprimer cette dépense'}
                                                >
                                                    <span className="material-symbols-outlined" style={{ opacity: isDeletable ? '1' : '0.2', cursor: isDeletable ? 'pointer' : 'default' }}>delete</span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {filtredDepenses?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>
            </div>

            {showAddModal && <div className="popcard-add">
                <div className="popcard-add_inside depense-w">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Entreprise<span className='requiredField'>*</span></label>
                                        <select
                                            id="idEntreprise"
                                            name="idEntreprise"
                                            value={formik1.values.idEntreprise}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idEntreprise && formik1.errors.idEntreprise) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {entreprises.map((entreprise) => (
                                                <option key={entreprise.idEntreprise} value={entreprise.idEntreprise}>{entreprise.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idEntreprise && formik1.errors.idEntreprise ? (
                                            <div className="invalid-feedback">{formik1.errors.idEntreprise}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgence"
                                            name="idAgence"
                                            value={formik1.values.idAgence}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idAgence && formik1.errors.idAgence) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idAgence && formik1.errors.idAgence ? (
                                            <div className="invalid-feedback">{formik1.errors.idAgence}</div>
                                        ) : null}
                                    </div>
                                    {/* <div className="wrap-one-half">
                                        <label>Agence<span className='requiredField'>*</span></label>
                                        <Select
                                            id="idAgence"
                                            name="idAgence"
                                            value={formik1.values.idAgence}
                                            onChange={(option) => { formik1.setFieldValue('idAgence', option); }}
                                            options={agences}
                                            getOptionLabel={(option) => option.libelle}
                                            getOptionValue={(option) => option.idAgence}
                                            onInputChange={(inputValue) => {
                                                // Update the entered text
                                                setEnteredAgenceText(inputValue);
                                            }}
                                            isClearable
                                            placeholder=""
                                            //components={{IndicatorsContainer:() => null}}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: '19px',
                                                    outline: '0!important',
                                                    borderRadius: '0.5rem',
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem',
                                                    margin: '0',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    alignItems: 'start',
                                                    fontSize: '1rem'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '0.5rem',
                                                    zIndex: 99990,
                                                    marginTop: 0,
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                }),
                                            }}
                                        />
                                        {formik1.touched.idAgence && formik1.errors.idAgence ? (
                                            <div className="invalid-feedback">{formik1.errors.idAgence}</div>
                                        ) : null}
                                    </div> */}
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Caisse<span className='requiredField'>*</span></label>
                                        <Select
                                            id="idCaisseDecaissement"
                                            name="idCaisseDecaissement"
                                            value={formik1.values.idCaisseDecaissement}
                                            onChange={(option) => { formik1.setFieldValue('idCaisseDecaissement', option); setCaisses([])}}
                                            options={caisses}
                                            getOptionLabel={(option) => option.libelle}
                                            getOptionValue={(option) => option.idCaisse}
                                            onInputChange={(inputValue) => {
                                                // Update the entered text
                                                setEnteredCaisseText(inputValue);
                                            }}
                                            isClearable
                                            placeholder=""
                                            //components={{IndicatorsContainer:() => null}}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: '19px',
                                                    outline: '0!important',
                                                    borderRadius: '0.5rem',
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem',
                                                    margin: '0',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    alignItems: 'start',
                                                    fontSize: '1rem'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '0.5rem',
                                                    zIndex: 99990,
                                                    marginTop: 0,
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                }),
                                            }}
                                            menuPlacement='auto'
                                        />
                                        {formik1.touched.idCaisseDecaissement && formik1.errors.idCaisseDecaissement ? (
                                            <div className="invalid-feedback">{formik1.errors.idCaisseDecaissement}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Nature<span className='requiredField'>*</span></label>
                                        <select
                                            id="idRubrique"
                                            name="idRubrique"
                                            value={formik1.values.idRubrique}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idRubrique && formik1.errors.idRubrique) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {natures.map((nature) => (
                                                <option key={nature.idRubrique} value={nature.idRubrique}>{nature.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idRubrique && formik1.errors.idRubrique ? (
                                            <div className="invalid-feedback">{formik1.errors.idRubrique}</div>
                                        ) : null}
                                    </div>
                                    {/* <div className="wrap-one-half">
                                        <label>Rubrique<span className='requiredField'>*</span></label>
                                        <select
                                            id="idRubrique"
                                            name="idRubrique"
                                            value={formik1.values.idRubrique}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idRubrique && formik1.errors.idRubrique) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {rubriques.map((rubrique) => (
                                                <option key={rubrique.idRubrique} value={rubrique.idRubrique}>{rubrique.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idRubrique && formik1.errors.idRubrique ? (
                                            <div className="invalid-feedback">{formik1.errors.idRubrique}</div>
                                        ) : null}
                                    </div> */}
                                    <div className="wrap-one-half">
                                        <label>Date de besoin<span className='requiredField'>*</span></label>
                                        <input
                                            type="date"
                                            id="dateBesoin"
                                            name="dateBesoin"
                                            style={(formik1.touched.dateBesoin && formik1.errors.dateBesoin) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.dateBesoin}
                                        />
                                        {formik1.touched.dateBesoin && formik1.errors.dateBesoin ? (
                                            <div className="invalid-feedback">{formik1.errors.dateBesoin}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Mode de paiement<span className='requiredField'>*</span></label>
                                        <select
                                            id="modePaiement"
                                            name="modePaiement"
                                            value={formik1.values.modePaiement}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            <option value="espece">Espèces</option>
                                            <option value="cheque">Chèque </option>
                                            <option value="virement">Virement </option>
                                        </select>
                                        {formik1.touched.modePaiement && formik1.errors.modePaiement ? (
                                            <div className="invalid-feedback">{formik1.errors.modePaiement}</div>
                                        ) : null}
                                    </div>
                                    {formik1.values.modePaiement === "cheque" && <div className="wrap-one-half">
                                        <label>N° chèque</label>
                                        <input
                                            type="text"
                                            id="numeroTransactionFinanciere"
                                            name="numeroTransactionFinanciere"
                                            placeholder=""
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.numeroTransactionFinanciere}
                                        />

                                    </div>}
                                    <div className="wrap-one-half">
                                        <label>Type de justificatif<span className='requiredField'>*</span></label>
                                        <select
                                            id="typeJustificatif"
                                            name="typeJustificatif"
                                            value={formik1.values.typeJustificatif}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            <option value="bon">Bon</option>
                                            <option value="facture">Facture </option>
                                        </select>
                                        {formik1.touched.typeJustificatif && formik1.errors.typeJustificatif ? (
                                            <div className="invalid-feedback">{formik1.errors.typeJustificatif}</div>
                                        ) : null}
                                    </div>
                                    {formik1.values.typeJustificatif !== "" && <div className="wrap-one-half">
                                        <label>Montant TTC<span className='requiredField'>*</span></label>
                                        {/* <input
                                            type="number"
                                            id="montantTTC"
                                            name="montantTTC"
                                            style={(formik1.touched.montantTTC && formik1.errors.montantTTC) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montantTTC}
                                        /> */}
                                        <NumericFormat
                                            id="montantTTC"
                                            name="montantTTC"
                                            style={(formik1.touched.montantTTC && formik1.errors.montantTTC) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            allowNegative={false}
                                            allowedDecimalSeparators={[',']}
                                            // thousandSeparator={true}
                                            // prefix={'€'}
                                            thousandSeparator=" "
                                            onValueChange={(values) => {
                                                const { formattedValue, value } = values;
                                                formik1.setFieldValue('montantTTC', value);
                                            }}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montantTTC}
                                        />
                                        {formik1.touched.montantTTC && formik1.errors.montantTTC ? (
                                            <div className="invalid-feedback">{formik1.errors.montantTTC}</div>
                                        ) : null}
                                    </div>}
                                </div>
                                {formik1.values.typeJustificatif === "facture" && <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Montant HT</label>
                                        {/* <input
                                            type="number"
                                            id="montantHT"
                                            name="montantHT"
                                            style={(formik1.touched.montantHT && formik1.errors.montantHT) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montantHT}
                                        /> */}
                                        <NumericFormat
                                            id="montantHT"
                                            name="montantHT"
                                            style={(formik1.touched.montantHT && formik1.errors.montantHT) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            allowNegative={false}
                                            allowedDecimalSeparators={[',']}
                                            // thousandSeparator={true}
                                            // prefix={'€'}
                                            thousandSeparator=" "
                                            onValueChange={(values) => {
                                                const { formattedValue, value } = values;
                                                formik1.setFieldValue('montantHT', value);
                                            }}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montantHT}
                                        />
                                        {formik1.touched.montantHT && formik1.errors.montantHT ? (
                                            <div className="invalid-feedback">{formik1.errors.montantHT}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Montant TVA</label>
                                        {/* <input
                                            type="number"
                                            id="montantTVA"
                                            name="montantTVA"
                                            style={(formik1.touched.montantTVA && formik1.errors.montantTVA) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montantTVA}
                                        /> */}
                                        <NumericFormat
                                            id="montantTVA"
                                            name="montantTVA"
                                            style={(formik1.touched.montantTVA && formik1.errors.montantTVA) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            allowNegative={false}
                                            allowedDecimalSeparators={[',']}
                                            // thousandSeparator={true}
                                            // prefix={'€'}
                                            thousandSeparator=" "
                                            onValueChange={(values) => {
                                                const { formattedValue, value } = values;
                                                formik1.setFieldValue('montantTVA', value);
                                            }}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montantTVA}
                                        />
                                        {formik1.touched.montantTVA && formik1.errors.montantTVA ? (
                                            <div className="invalid-feedback">{formik1.errors.montantTVA}</div>
                                        ) : null}
                                    </div>
                                    {formik1.values.typeJustificatif === "facture" && formik1.values.modePaiement === "espece" && <div className="wrap-one-half">
                                        <label>Timbre</label>
                                        {/* <input
                                            type="number"
                                            id="montantDroitsTimbre"
                                            name="montantDroitsTimbre"
                                            style={(formik1.touched.montantDroitsTimbre && formik1.errors.montantDroitsTimbre) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montantDroitsTimbre}
                                        /> */}
                                        <NumericFormat
                                            id="montantDroitsTimbre"
                                            name="montantDroitsTimbre"
                                            style={(formik1.touched.montantDroitsTimbre && formik1.errors.montantDroitsTimbre) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            allowNegative={false}
                                            allowedDecimalSeparators={[',']}
                                            // thousandSeparator={true}
                                            // prefix={'€'}
                                            thousandSeparator=" "
                                            onValueChange={(values) => {
                                                const { formattedValue, value } = values;
                                                formik1.setFieldValue('montantDroitsTimbre', value);
                                            }}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.montantDroitsTimbre}
                                        />
                                        {formik1.touched.montantDroitsTimbre && formik1.errors.montantDroitsTimbre ? (
                                            <div className="invalid-feedback">{formik1.errors.montantDroitsTimbre}</div>
                                        ) : null}
                                    </div>}
                                </div>}
                                {formik1.values.typeJustificatif !== "" && <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Justificatif </label>
                                        <label className="upload-file">
                                            &nbsp;
                                            <input
                                                ref={pathImageDepenseRef}
                                                id="pathImageDepense"
                                                name="pathImageDepense"
                                                type="file"
                                                onChange={handlePathImageDepenseChange}
                                            />
                                            Upload
                                        </label>
                                        {pathImageDepense && <span style={{ fontSize: "1rem" }}>{getImageFileName(pathImageDepense)}
                                            <span className="material-symbols-outlined" style={{ fontSize: "1rem", cursor: 'pointer' }} onClick={(e) => { setPathImageDepense(null); if (pathImageDepenseRef.current) { pathImageDepenseRef.current.value = ''; } }}>delete</span>
                                        </span>}
                                    </div>
                                </div>}
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Commentaires </label>
                                        <textarea
                                            type="text"
                                            rows={2}
                                            id="description"
                                            name="description"
                                            placeholder=""
                                            defaultValue={formik1.values.description}
                                            onChange={formik1.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                {authData?.role !== "SuperAdmin" && <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {showUpdateModal && <div className="popcard-add">
                <div className="popcard-add_inside depense-w">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        {loadingDepense ? (
                            <div>Loading...</div>
                        ) : (
                            <form onSubmit={formik2.handleSubmit}>
                                <div className="wrap-info">
                                    <div className="wrap-row">
                                        <div className="wrap-one-half">
                                            <label>Entreprise<span className='requiredField'>*</span></label>
                                            <select
                                                id="idEntrepriseUpdate"
                                                name="idEntrepriseUpdate"
                                                value={formik2.values.idEntrepriseUpdate}
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                style={(formik2.touched.idEntrepriseUpdate && formik2.errors.idEntrepriseUpdate) ? { borderColor: "red" } : null}
                                            >
                                                <option value="">Veuillez sélectionner</option>
                                                {entreprises.map((entreprise) => (
                                                    <option key={entreprise.idEntreprise} value={entreprise.idEntreprise}>{entreprise.libelle}</option>
                                                ))}
                                            </select>
                                            {formik2.touched.idEntrepriseUpdate && formik2.errors.idEntrepriseUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.idEntrepriseUpdate}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one-half">
                                            <label>Agence<span className='requiredField'>*</span></label>
                                            <select
                                                id="idAgenceUpdate"
                                                name="idAgenceUpdate"
                                                value={formik2.values.idAgenceUpdate}
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                style={(formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate) ? { borderColor: "red" } : null}
                                            >
                                                <option value="">Veuillez sélectionner</option>
                                                {agences.map((agence) => (
                                                    <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                                ))}
                                            </select>
                                            {formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.idAgenceUpdate}</div>
                                            ) : null}
                                        </div>
                                        {/* <div className="wrap-one-half">
                                        <label>Agence<span className='requiredField'>*</span></label>
                                        <Select
                                            id="idAgenceUpdate"
                                            name="idAgenceUpdate"
                                            value={formik2.values.idAgenceUpdate}
                                            onChange={(option) => { formik2.setFieldValue('idAgenceUpdate', option); }}
                                            options={agences}
                                            getOptionLabel={(option) => option.libelle}
                                            getOptionValue={(option) => option.idAgence}
                                            onInputChange={(inputValue) => {
                                                // Update the entered text
                                                setEnteredAgenceText(inputValue);
                                            }}
                                            isClearable
                                            placeholder=""
                                            //components={{IndicatorsContainer:() => null}}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: '19px',
                                                    outline: '0!important',
                                                    borderRadius: '0.5rem',
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem',
                                                    margin: '0',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    alignItems: 'start',
                                                    fontSize: '1rem'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '0.5rem',
                                                    zIndex: 99990,
                                                    marginTop: 0,
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                }),
                                            }}
                                        />
                                        {formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idAgenceUpdate}</div>
                                        ) : null}
                                    </div> */}
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Caisse<span className='requiredField'>*</span></label>
                                            <Select
                                                id="idCaisseDecaissementUpdate"
                                                name="idCaisseDecaissementUpdate"
                                                value={formik2.values.idCaisseDecaissementUpdate}
                                                onChange={(option) => { formik2.setFieldValue('idCaisseDecaissementUpdate', option); }}
                                                options={caisses}
                                                getOptionLabel={(option) => option.libelle}
                                                getOptionValue={(option) => option.idCaisse}
                                                onInputChange={(inputValue) => {
                                                    // Update the entered text
                                                    setEnteredCaisseText(inputValue);
                                                }}
                                                isClearable
                                                placeholder=""
                                                //components={{IndicatorsContainer:() => null}}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        minHeight: '19px',
                                                        outline: '0!important',
                                                        borderRadius: '0.5rem',
                                                        borderWidth: '1px',
                                                        fontSize: '1rem',
                                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '1rem'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '1rem',
                                                        margin: '0',
                                                    }),
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        alignItems: 'start',
                                                        fontSize: '1rem'
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        margin: '0',
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: '0.5rem',
                                                        zIndex: 99990,
                                                        marginTop: 0,
                                                        borderWidth: '1px',
                                                        fontSize: '1rem',
                                                    }),
                                                }}
                                            />
                                            {formik2.touched.idCaisseDecaissementUpdate && formik2.errors.idCaisseDecaissementUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.idCaisseDecaissementUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-half">
                                            <label>Nature<span className='requiredField'>*</span></label>
                                            <select
                                                id="idRubriqueUpdate"
                                                name="idRubriqueUpdate"
                                                value={formik2.values.idRubriqueUpdate}
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                style={(formik2.touched.idRubriqueUpdate && formik2.errors.idRubriqueUpdate) ? { borderColor: "red" } : null}
                                            >
                                                <option value="">Veuillez sélectionner</option>
                                                {natures.map((nature) => (
                                                    <option key={nature.idRubrique} value={nature.idRubrique}>{nature.libelle}</option>
                                                ))}
                                            </select>
                                            {formik2.touched.idRubriqueUpdate && formik2.errors.idRubriqueUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.idRubriqueUpdate}</div>
                                            ) : null}
                                        </div>
                                        {/* <div className="wrap-one-half">
                                            <label>Rubrique<span className='requiredField'>*</span></label>
                                            <select
                                                id="idRubriqueUpdate"
                                                name="idRubriqueUpdate"
                                                value={formik2.values.idRubriqueUpdate}
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                style={(formik2.touched.idRubriqueUpdate && formik2.errors.idRubriqueUpdate) ? { borderColor: "red" } : null}
                                            >
                                                <option value="">Veuillez sélectionner</option>
                                                {rubriques.map((rubrique) => (
                                                    <option key={rubrique.idRubrique} value={rubrique.idRubrique}>{rubrique.libelle}</option>
                                                ))}
                                            </select>
                                            {formik2.touched.idRubriqueUpdate && formik2.errors.idRubriqueUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.idRubriqueUpdate}</div>
                                            ) : null}
                                        </div> */}
                                        <div className="wrap-one-half">
                                            <label>Date de besoin<span className='requiredField'>*</span></label>
                                            <input
                                                type="date"
                                                id="dateBesoinUpdate"
                                                name="dateBesoinUpdate"
                                                style={(formik2.touched.dateBesoinUpdate && formik2.errors.dateBesoinUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.dateBesoinUpdate}
                                            />
                                            {formik2.touched.dateBesoinUpdate && formik2.errors.dateBesoinUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.dateBesoinUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-half">
                                            <label>Mode de paiement<span className='requiredField'>*</span></label>
                                            <select
                                                id="modePaiementUpdate"
                                                name="modePaiementUpdate"
                                                value={formik2.values.modePaiementUpdate}
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                            >
                                                <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                <option value="espece">Espèces</option>
                                                <option value="cheque">Chèque </option>
                                                <option value="virement">Virement </option>
                                            </select>
                                            {formik2.touched.modePaiementUpdate && formik2.errors.modePaiementUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.modePaiementUpdate}</div>
                                            ) : null}
                                        </div>
                                        {formik2.values.modePaiementUpdate === "cheque" && <div className="wrap-one-half">
                                            <label>N° chèque</label>
                                            <input
                                                type="text"
                                                id="numeroTransactionFinanciereUpdate"
                                                name="numeroTransactionFinanciereUpdate"
                                                placeholder=""
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.numeroTransactionFinanciereUpdate}
                                            />
                                        </div>}
                                        <div className="wrap-one-half">
                                            <label>Type de justificatif<span className='requiredField'>*</span></label>
                                            <select
                                                id="typeJustificatifUpdate"
                                                name="typeJustificatifUpdate"
                                                value={formik2.values.typeJustificatifUpdate}
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                            >
                                                <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                <option value="bon">Bon</option>
                                                <option value="facture">Facture </option>
                                            </select>
                                            {formik2.touched.typeJustificatifUpdate && formik2.errors.typeJustificatifUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.typeJustificatifUpdate}</div>
                                            ) : null}
                                        </div>
                                        {formik2.values.typeJustificatifUpdate !== "" && <div className="wrap-one-half">
                                            <label>Montant TTC<span className='requiredField'>*</span></label>
                                            {/* <input
                                            type="number"
                                            id="montantTTCUpdate"
                                            name="montantTTCUpdate"
                                            style={(formik2.touched.montantTTCUpdate && formik2.errors.montantTTCUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={formik2.values.montantTTCUpdate}
                                        /> */}
                                            <NumericFormat
                                                id="montantTTCUpdate"
                                                name="montantTTCUpdate"
                                                style={(formik2.touched.montantTTCUpdate && formik2.errors.montantTTCUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                allowedDecimalSeparators={[',']}
                                                // thousandSeparator={true}
                                                // prefix={'€'}
                                                thousandSeparator=" "
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    formik2.setFieldValue('montantTTCUpdate', value);
                                                }}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.montantTTCUpdate}
                                            />
                                            {formik2.touched.montantTTCUpdate && formik2.errors.montantTTCUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.montantTTCUpdate}</div>
                                            ) : null}
                                        </div>}
                                    </div>
                                    {formik2.values.typeJustificatifUpdate === "facture" && <div className="wrap-row">
                                        <div className="wrap-one-half">
                                            <label>Montant HT</label>
                                            {/* <input
                                            type="number"
                                            id="montantHTUpdate"
                                            name="montantHTUpdate"
                                            style={(formik2.touched.montantHTUpdate && formik2.errors.montantHTUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={formik2.values.montantHTUpdate}
                                        /> */}
                                            <NumericFormat
                                                id="montantHTUpdate"
                                                name="montantHTUpdate"
                                                style={(formik2.touched.montantHTUpdate && formik2.errors.montantHTUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                allowedDecimalSeparators={[',']}
                                                // thousandSeparator={true}
                                                // prefix={'€'}
                                                thousandSeparator=" "
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    formik2.setFieldValue('montantHTUpdate', value);
                                                }}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.montantHTUpdate}
                                            />
                                            {formik2.touched.montantHTUpdate && formik2.errors.montantHTUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.montantHTUpdate}</div>
                                            ) : null}
                                        </div>
                                        <div className="wrap-one-half">
                                            <label>Montant TVA</label>
                                            {/* <input
                                            type="number"
                                            id="montantTVAUpdate"
                                            name="montantTVAUpdate"
                                            style={(formik2.touched.montantTVAUpdate && formik2.errors.montantTVAUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={formik2.values.montantTVAUpdate}
                                        /> */}
                                            <NumericFormat
                                                id="montantTVAUpdate"
                                                name="montantTVAUpdate"
                                                style={(formik2.touched.montantTVAUpdate && formik2.errors.montantTVAUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                allowedDecimalSeparators={[',']}
                                                // thousandSeparator={true}
                                                // prefix={'€'}
                                                thousandSeparator=" "
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    formik2.setFieldValue('montantTVAUpdate', value);
                                                }}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.montantTVAUpdate}
                                            />
                                            {formik2.touched.montantTVAUpdate && formik2.errors.montantTVAUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.montantTVAUpdate}</div>
                                            ) : null}
                                        </div>
                                        {formik2.values.typeJustificatifUpdate === "facture" && formik2.values.modePaiementUpdate === "espece" && <div className="wrap-one-half">
                                            <label>Timbre</label>
                                            {/* <input
                                            type="number"
                                            id="montantDroitsTimbreUpdate"
                                            name="montantDroitsTimbreUpdate"
                                            style={(formik2.touched.montantDroitsTimbreUpdate && formik2.errors.montantDroitsTimbreUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            step="any"
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={formik2.values.montantDroitsTimbreUpdate}
                                        /> */}
                                            <NumericFormat
                                                id="montantDroitsTimbreUpdate"
                                                name="montantDroitsTimbreUpdate"
                                                style={(formik2.touched.montantDroitsTimbreUpdate && formik2.errors.montantDroitsTimbreUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                allowedDecimalSeparators={[',']}
                                                // thousandSeparator={true}
                                                // prefix={'€'}
                                                thousandSeparator=" "
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    formik2.setFieldValue('montantDroitsTimbreUpdate', value);
                                                }}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.montantDroitsTimbreUpdate}
                                            />
                                            {formik2.touched.montantDroitsTimbreUpdate && formik2.errors.montantDroitsTimbreUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.montantDroitsTimbreUpdate}</div>
                                            ) : null}
                                        </div>}
                                    </div>}
                                    {formik2.values.typeJustificatifUpdate !== "" && <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Justificatif </label>
                                            <label className="upload-file">
                                                &nbsp;
                                                <input
                                                    ref={pathImageDepenseRefUpdate}
                                                    id="pathImageDepenseUpdate"
                                                    name="pathImageDepenseUpdate"
                                                    type="file"
                                                    onChange={handlePathImageDepenseChangeUpdate}
                                                />
                                            </label>
                                            {pathImageDepenseUpdate && <span style={{ fontSize: "1rem" }}>{getImageFileName(pathImageDepenseUpdate)}
                                                <span className="material-symbols-outlined" style={{ fontSize: "1rem", cursor: 'pointer' }} onClick={(e) => { setPathImageDepenseUpdate(null); if (pathImageDepenseRefUpdate.current) { pathImageDepenseRefUpdate.current.value = ''; } }}>delete</span>
                                            </span>}
                                            {pathImageDepenseName && <span style={{ fontSize: "10px" }}>{pathImageDepenseName}
                                                <span className="material-symbols-outlined" style={{ fontSize: "1rem", cursor: 'pointer' }} onClick={(e) => { setPathImageDepenseUpdate(null); setPathImageDepenseName(''); if (pathImageDepenseUpdate?.current) { pathImageDepenseUpdate.current.value = ''; } }}>delete</span>
                                            </span>}
                                        </div>
                                    </div>}
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Commentaires </label>
                                            <textarea
                                                type="text"
                                                rows={2}
                                                id="descriptionUpdate"
                                                name="descriptionUpdate"
                                                placeholder=""
                                                defaultValue={formik2.values.descriptionUpdate}
                                                onChange={formik2.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="popcard-operation-pickup-details_inside-zone">
                                    {/* {authData?.role !== "SuperAdmin" &&  */}
                                    <div className="buttons">
                                        <input type="submit" value="Enregistrer" className="submit-btn" />
                                    </div>
                                    {/* } */}
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer cette dépense ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer la dépense"
            />

            <ConfirmationDialog
                open={confirmDialogOpenValiderDepense}
                onClose={handleCancelValiderDepense}
                onConfirm={handleValiderDepense}
                titleMessage={(authData?.role === "Caissier" || authData?.role === "ChefStation") ? "Décaisser la dépense" : "Valider la dépense"}
                bodyMessage={(authData?.role === "Caissier" || authData?.role === "ChefStation") ? "Voulez vous décaisser cette dépense ?" : "Voulez vous valider cette dépense ?"}
                btnCancelText="Annuler"
                btnConfirmText={(authData?.role === "Caissier" || authData?.role === "ChefStation") ? "Décaisser" : "Valider"}
            />

            {confirmDialogOpenRefuserDepense && <div className="popcard-add">
                <div className="popcard-add_inside annuler-banner">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => handleCancelRefuserDepense()}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Êtes-vous sûr de vouloir rejeter cette demande de dépense ?</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik3.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Motif <span className='requiredField'>*</span></label>
                                        <textarea
                                            type="text"
                                            rows={2}
                                            cols={50}
                                            id="motifRefus"
                                            name="motifRefus"
                                            placeholder=""
                                            defaultValue={formik3.values.motifRefus}
                                            style={(formik3.touched.motifRefus && formik3.errors.motifRefus) ? { borderColor: "red" } : null}
                                            onChange={formik3.handleChange}
                                        />
                                        {formik3.touched.motifRefus && formik3.errors.motifRefus ? (
                                            <div className="invalid-feedback">{formik3.errors.motifRefus}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Annuler" className="submit-btn" onClick={() => handleCancelRefuserDepense()} />
                                    <input type="submit" value="Refuser" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpenImprimerDecharge}
                onClose={handleCancelImprimerDecharge}
                onConfirm={handleImprimerDecharge}
                titleMessage="Imprimer la décharge"
                bodyMessage=""
                btnCancelText="Annuler"
                btnConfirmText="Imprimer"
            />

            {confirmDialogOpenAnnuler && <div className="popcard-add">
                <div className="popcard-add_inside annuler-banner">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => handleCancelAnnulerDemande()}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Voulez vous annuler cette dépense ?</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik5.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Motif <span className='requiredField'>*</span></label>
                                        <textarea
                                            type="text"
                                            rows={2}
                                            cols={50}
                                            id="motifAnnulation"
                                            name="motifAnnulation"
                                            placeholder=""
                                            defaultValue={formik5.values.motifAnnulation}
                                            style={(formik5.touched.motifAnnulation && formik5.errors.motifAnnulation) ? { borderColor: "red" } : null}
                                            onChange={formik5.handleChange}
                                        />
                                        {formik5.touched.motifAnnulation && formik5.errors.motifAnnulation ? (
                                            <div className="invalid-feedback">{formik5.errors.motifAnnulation}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Non" className="submit-btn" onClick={() => handleCancelAnnulerDemande()} />
                                    <input type="submit" value="Oui" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpenValiderDepenseDecaissementValiderNonValider}
                onClose={handleCancelValiderDepenseDecaissementValiderNonValider}
                onConfirm={handleValiderDepenseDecaissementValiderNonValider}
                titleMessage="Valider le décaissement"
                bodyMessage= "Voulez vous valider ce décaissement ?"
                btnCancelText="Annuler"
                btnConfirmText="Valider"
            />

            {confirmDialogOpenRefuserDepenseDecaissementValiderNonValider && <div className="popcard-add">
                <div className="popcard-add_inside annuler-banner">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => handleCancelRefuserDepenseDecaissementValiderNonValider()}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Voulez-vous refuser ce décaissement ?</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik4.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Motif <span className='requiredField'>*</span></label>
                                        <textarea
                                            type="text"
                                            rows={2}
                                            cols={50}
                                            id="motifRefus"
                                            name="motifRefus"
                                            placeholder=""
                                            defaultValue={formik4.values.motifRefus}
                                            style={(formik4.touched.motifRefus && formik4.errors.motifRefus) ? { borderColor: "red" } : null}
                                            onChange={formik4.handleChange}
                                        />
                                        {formik4.touched.motifRefus && formik4.errors.motifRefus ? (
                                            <div className="invalid-feedback">{formik4.errors.motifRefus}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Annuler" className="submit-btn" onClick={() => handleCancelRefuserDepenseDecaissementValiderNonValider()} />
                                    <input type="submit" value="Refuser" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}


        </>
    );
}

export default Depenses;
