import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { CustomToast } from '../../../components/Toast/CustomToast';
import Pagination from '../../../components/pagination/Pagination';
import ConfirmationDialog from '../../../components/confirmation/ConfirmationDialog';
import { axiosInstance, createCancelTokenSource, Caisse, CaisseType, Agence, Wilaya, Commune } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
import useDebounce from '../../../hooks/useDebounce';

const Caisses = () => {
    const { authData } = useContext(AuthContext);
    const [caisses, setCaisses] = useState([]);
    const [typeCaisses, setTypeCaisses] = useState([]);
    const [wilayas, setWilayas] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [agences, setAgences] = useState([]);
    const [filtredCaisses, setFiltredCaisses] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [idCaisseDelete, setIdCaisseDelete] = useState('');
    const [idCaisseSelect, setIdCaisseSelect] = useState('');


    const [search, setSearch] = useState('');
    const [enteredAgenceText, setEnteredAgenceText] = useState('');
    const debouncedSearchText = useDebounce(search, 300);
    const debouncedAgenceText = useDebounce(enteredAgenceText, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredCaisses].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredCaisses(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = caisses.slice(startIndex, endIndex);

        setFiltredCaisses(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };


    //Ajouter une caisse
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        getTypesCaisses();
        getWilayas();
    };

    const formik1 = useFormik({
        initialValues: {
            idWilaya: '',
            idCommune: '',
            libelle: '',
            statut: 'actif',
            adresse: '',
            latitude: '',
            longitude: '',
            idAgences: []
        },
        validationSchema: Yup.object({
            libelle: Yup.string()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire'),
            idWilaya: Yup.string()
                .required('Champ obligatoire'),
            idCommune: Yup.string()
                .required('Champ obligatoire'),
            idAgences: Yup.array()
                .min(1, "Au moins une agence doit être sélectionnée")
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idTypeCaisse: values.idTypeCaisse,
                    idWilaya: values.idWilaya,
                    idCommune: values.idCommune,
                    libelle: values.libelle.toUpperCase(),
                    statut: values.statut,
                    adresse: values.adresse,
                    latitude: values.latitude,
                    longitude: values.longitude,
                    idAgences: values.idAgences.map(agence => agence.idAgence), // idAgence
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(postData);
                const response = await axiosInstance.post(Caisse, postData);
                CustomToast("La caisse a été créée avec succès", 'success');
                getCaisses(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une caisse avec le même nom existe déjà.") {
                    CustomToast("Une caisse avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier une caisse
    // const showHideUpdateModal = (val, idTypeCaisse, idWilaya, idCommune, libelle, statut, adresse, latitude, longitude, idAgences) => {
    //     setShowUpdateModal(val);
    //     formik2.resetForm();
    //     if (val) {
    //         getTypesCaisses();
    //         getWilayas();
    //         getCommunes(idWilaya);
    //         formik2.setValues({
    //             idTypeCaisseUpdate: idTypeCaisse,
    //             idWilayaUpdate: idWilaya || '',
    //             idCommuneUpdate: idCommune || '',
    //             libelleUpdate: libelle || '',
    //             statutUpdate: statut || 'actif',
    //             adresseUpdate: adresse || '',
    //             latitudeUpdate: latitude || '',
    //             longitudeUpdate: longitude || '',
    //             idAgencesUpdate: idAgences || '',
    //         });
    //     }
    // };

    const showHideUpdateModal = (val, idCaisse, idTypeCaisse, idWilaya, idCommune, libelle, statut, adresse, latitude, longitude, idAgences) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        if (val) {
            setIdCaisseSelect(idCaisse);
            getTypesCaisses();
            getWilayas();
            getCommunes(idWilaya);
            getCaisse(idCaisse, idTypeCaisse, idWilaya, idCommune, adresse, latitude, longitude);
        }
    };

    const getCaisse = async (idCaisse, idTypeCaisse, idWilaya, idCommune, adresse, latitude, longitude) => {
        try {
            const response = await axiosInstance.get(`${Caisse}/GetCaisseById/${idCaisse}`);
            const resp = await response.data;
            formik2.setValues({
                idCaisse: resp.caisse.idCaisse,
                idTypeCaisseUpdate: idTypeCaisse,
                idWilayaUpdate: idWilaya || '',
                idCommuneUpdate: idCommune || '',
                libelleUpdate: resp.caisse.libelle || '',
                statutUpdate: resp.caisse.statut || 'actif',
                adresseUpdate: adresse || '',
                latitudeUpdate: latitude || '',
                longitudeUpdate: longitude || '',
                idAgencesUpdate: resp.agences || [],
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    const formik2 = useFormik({
        initialValues: {
            idCaisse: '',
            idTypeCaisseUpdate: '',
            idWilayaUpdate: '',
            idCommuneUpdate: '',
            libelleUpdate: '',
            statutUpdate: 'actif',
            adresseUpdate: '',
            latitudeUpdate: '',
            longitudeUpdate: '',
            idAgencesUpdate: [],
        },
        validationSchema: Yup.object({
            libelleUpdate: Yup.string()
                .required('Champ obligatoire'),
            statutUpdate: Yup.string()
                .required('Champ obligatoire'),
            idWilayaUpdate: Yup.string()
                .required('Champ obligatoire'),
            idCommuneUpdate: Yup.string()
                .required('Champ obligatoire'),
            idAgencesUpdate: Yup.array()
                .min(1, "Au moins une agence doit être sélectionnée")
                .required("Champ obligatoire"),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idCaisse: values.idCaisse,
                    idTypeCaisse: values.idTypeCaisseUpdate,
                    idWilaya: values.idWilayaUpdate,
                    idCommune: values.idCommuneUpdate,
                    libelle: values.libelleUpdate.toUpperCase(),
                    statut: values.statutUpdate,
                    adresse: values.adresseUpdate,
                    latitude: values.latitudeUpdate,
                    longitude: values.longitudeUpdate,
                    idAgences: values.idAgencesUpdate?.map(agence => agence.idAgence) || [], // idAgence
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(postData);
                const response = await axiosInstance.put(Caisse, postData);
                CustomToast("La caisse a été modifiée avec succès", 'success');
                getCaisses(pageNumber, pageSize);
                setShowUpdateModal(false);
                setIdCaisseSelect("");
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une caisse avec le même nom existe déjà.") {
                    CustomToast("Une caisse avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "La caisse à mettre à jour n'existe pas.") {
                    CustomToast("La caisse à mettre à jour n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer une caisse
    const handleConfirmDelete = (idCaisse) => {
        setIdCaisseDelete(idCaisse);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Caisse}/${idCaisseDelete}`);
            setIdCaisseDelete('');
            CustomToast("La caisse a été supprimée avec succès", 'success');
            getCaisses(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 409 && error.response?.data === "Caisse utilisée") {
                CustomToast("Caisse en cours d'utilisation.", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Caisse introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getCaisses = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${Caisse}/GetListeCaisses?${queryParams}`, { cancelToken });
            const resp = await response.data;
            setCaisses(resp.data);
            setFiltredCaisses(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getTypesCaisses = async () => {
        try {
            const response = await axiosInstance.get(`${CaisseType}`);
            const resp = await response.data;
            setTypeCaisses(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    const getWilayas = async () => {
        try {
            const response = await axiosInstance.get(`${Wilaya}`);
            const resp = await response.data;
            setWilayas(resp)
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCommunes = async (idWilaya) => {
        try {
            const response = await axiosInstance.get(`${Commune}/GetCommuneByIdWilaya/${idWilaya}`);
            const resp = await response.data;
            setCommunes(resp);
        }
        catch (err) {
            console.log(err);
            setCommunes([]);
        }
    }

    const getAgences = async (agence) => {
        try {
            const queryParams = new URLSearchParams({});

            if (agence !== '') {
                queryParams.append("text", agence);
            }
            //const response = await axiosInstance.get(`${Agence}/GetListeAgencesAutocomplete?${queryParams}`);
            const response = await axiosInstance.get(`${Agence}/GetListeAgencesAutocompleteByCaisse?${queryParams}`);
            const resp = await response.data;
            setAgences(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getAgencesUpdate = async (agence) => {
        try {
            const queryParams = new URLSearchParams({});

            if (agence !== '') {
                queryParams.append("text", agence);
            }

            if (idCaisseSelect !== '') {
                queryParams.append("idCaisseFilter", idCaisseSelect);
            } else {
                return;
            }
            //const response = await axiosInstance.get(`${Agence}/GetListeAgencesAutocomplete?${queryParams}`);
            const response = await axiosInstance.get(`${Agence}/GetListeAgencesAutocompleteByCaisse?${queryParams}`);
            const resp = await response.data;
            setAgences(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getCaisses(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getCaisses(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getCaisses(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);

    useEffect(() => {
        if (showAddModal) {
            getAgences(debouncedAgenceText);
        }
        if (showUpdateModal && idCaisseSelect !== "") {
            getAgencesUpdate(debouncedAgenceText);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedAgenceText]);

    useEffect(() => {
        if (formik1.values.libelle === '') {
            const selectedWilaya = wilayas.find(w => w.idWilaya == formik1.values.idWilaya);
            const selectedCommune = communes.find(c => c.idCommune == formik1.values.idCommune);
            const selectedTypeCaisse = typeCaisses.find(tc => tc.idTypeCaisse == formik1.values.idTypeCaisse);

            // console.log("selectedWilaya", formik1.values.idWilaya);
            // console.log("selectedCommune", formik1.values.idCommune);
            // console.log("selectedTypeCaisse", formik1.values.idTypeCaisse);

            if (selectedWilaya && selectedCommune && selectedTypeCaisse) {
                const wilayaName = selectedWilaya.nomLatin;
                const communeName = selectedCommune.nomLatin;
                const typeCaisseName = selectedTypeCaisse.libelle;

                const newLibelle = `${typeCaisseName} ${wilayaName} ${communeName}`.trim().toUpperCase();
                formik1.setFieldValue('libelle', newLibelle);
            }
        }
    }, [formik1.values.idWilaya, formik1.values.idCommune, formik1.values.idTypeCaisse, wilayas, communes, typeCaisses]);

    useEffect(() => {
        if (formik2.values.libelleUpdate === '') {
            const selectedWilaya = wilayas.find(w => w.idWilaya == formik2.values.idWilayaUpdate);
            const selectedCommune = communes.find(c => c.idCommune == formik2.values.idCommuneUpdate);
            const selectedTypeCaisse = typeCaisses.find(tc => tc.idTypeCaisse == formik2.values.idTypeCaisseUpdate);


            if (selectedWilaya && selectedCommune && selectedTypeCaisse) {
                const wilayaName = selectedWilaya.nomLatin;
                const communeName = selectedCommune.nomLatin;
                const typeCaisseName = selectedTypeCaisse.libelle;

                const newLibelle = `${typeCaisseName} ${wilayaName} ${communeName}`.trim().toUpperCase();
                formik2.setFieldValue('libelleUpdate', newLibelle);
            }
        }
    }, [formik2.values.idWilayaUpdate, formik2.values.idCommuneUpdate, formik2.values.idTypeCaisseUpdate, wilayas, communes, typeCaisses]);

    useEffect(() => {
        //Load communes
        if (formik1.values.idWilaya !== "") {
            getCommunes(formik1.values.idWilaya);
        }
        else {
            setCommunes([]);
        }
        formik1.values.idCommune = "";
    }, [formik1.values.idWilaya])

    useEffect(() => {
        //Load communes update parc
        if (formik2.values.idWilayaUpdate !== "") {
            getCommunes(formik2.values.idWilayaUpdate);
        }
        else {
            setCommunes([]);
            formik2.values.idCommuneUpdate = "";
        }
    }, [formik2.values.idWilayaUpdate])

    return (
        <>
            <div className="container user-section">
                <div>
                    <div className="affectation-sec wrap-all">
                        <div>
                            <Link to="#" className="utilisateur-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                                <div className="add-new" id="add-vehc">
                                    <span className="material-symbols-outlined">add</span>
                                    Ajouter un bureau de caisse
                                </div>
                            </Link>
                        </div>
                        <div className="group-elements">
                            <div className="voir-elements">
                                {/* <p>Voir</p> */}
                                <select
                                    name="pageSize"
                                    id="pageSize"
                                    onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                                >
                                    <option value="10" defaultValue={true}>10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </div>

                            <div className="search-element">
                                <input
                                    type="search"
                                    value={search}
                                    placeholder="Rechercher ..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        {filtredCaisses?.length > 0 && <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Type</th>
                                        <th>Wilaya</th>
                                        <th>Commune</th>
                                        <th>Statut</th>
                                        <th>Agence(s)</th>
                                        <th>Modifier</th>
                                        <th>Supprimer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredCaisses?.map((row) => (
                                        <tr key={row.idCaisse}>
                                            <td>{row.libelleCaisse}</td>
                                            <td>{row.libelleTypeCaisse}</td>
                                            <td>{row.libelleWilaya}</td>
                                            <td>{row.libelleCommune}</td>
                                            <td>{
                                                row.statut === "actif" ? <>Actif</> :
                                                    row.statut === "inactif" ? <>Inactif</> :
                                                        null
                                            }</td>
                                            {/* <td>{row.listeAgences?.map(agence => agence.cfG_Agence.libelle).join('<br></br>')}</td> */}
                                            <td>
                                                {row.listeAgences?.map((agence, index) => (
                                                    <React.Fragment key={agence.idCaisse_Agences}>
                                                        {agence.cfG_Agence.libelle}
                                                        {index < row.listeAgences.length - 1 && <br />}
                                                    </React.Fragment>
                                                ))}
                                            </td>
                                            <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idCaisse, row.idTypeCaisse, row.idWilaya, row.idCommune, row.libelle, row.statut, row.adresse, row.latitude, row.longitude)}>
                                                <span className="material-symbols-outlined">edit
                                                </span>
                                            </td>
                                            <td className="txt-center danger" onClick={() => handleConfirmDelete(row.idCaisse)}>
                                                <span className="material-symbols-outlined">delete
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        {filtredCaisses?.length > 0 && <Pagination
                            pageNumber={pageNumber}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                            pageSize={pageSize}
                            totalItems={totalItems}
                        />}
                    </div>
                </div>
            </div>

            {showAddModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Type de caisse<span className='requiredField'>*</span></label>
                                        <select
                                            id="idTypeCaisse"
                                            name="idTypeCaisse"
                                            value={formik1.values.idTypeCaisse}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idTypeCaisse && formik1.errors.idTypeCaisse) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {typeCaisses.map((type) => (
                                                <option key={type.idTypeCaisse} value={type.idTypeCaisse}>{type.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idTypeCaisse && formik1.errors.idTypeCaisse ? (
                                            <div className="invalid-feedback">{formik1.errors.idTypeCaisse}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Wilaya<span className='requiredField'>*</span></label>
                                        <select
                                            id="idWilaya"
                                            name="idWilaya"
                                            value={formik1.values.idWilaya}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idWilaya && formik1.errors.idWilaya) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {wilayas.map((wilaya) => (
                                                <option key={wilaya.idWilaya} value={wilaya.idWilaya}>{wilaya.nomLatin}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idWilaya && formik1.errors.idWilaya ? (
                                            <div className="invalid-feedback">{formik1.errors.idWilaya}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label >Commune<span className='requiredField'>*</span></label>
                                        <select
                                            id="idCommune"
                                            name="idCommune"
                                            value={formik1.values.idCommune}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            style={(formik1.touched.idCommune && formik1.errors.idCommune) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {communes.map((commune) => (
                                                <option key={commune.idCommune} value={commune.idCommune}>{commune.nomLatin}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idCommune && formik1.errors.idCommune ? (
                                            <div className="invalid-feedback">{formik1.errors.idCommune}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Adresse</label>
                                        <textarea type="text"
                                            id="adresse"
                                            name="adresse"
                                            placeholder=""
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.adresse}
                                            rows={2}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Latitude</label>
                                        <input type="text"
                                            id="latitude"
                                            name="latitude"
                                            placeholder=""
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.latitude}
                                        />
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Longitude</label>
                                        <input type="text"
                                            id="longitude"
                                            name="longitude"
                                            placeholder=""
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.longitude}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelle"
                                            name="libelle"
                                            style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                            value={formik1.values.libelle}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Agences<span className='requiredField'>*</span></label>
                                        <Select
                                            id="idAgences"
                                            name="idAgences"
                                            value={formik1.values.idAgences}
                                            onChange={(option) => { formik1.setFieldValue('idAgences', option); }}
                                            options={agences}
                                            getOptionLabel={(option) => option.libelle}
                                            getOptionValue={(option) => option.idAgence}
                                            onInputChange={(inputValue) => {
                                                // Update the entered text
                                                setEnteredAgenceText(inputValue);
                                            }}
                                            isClearable
                                            isMulti
                                            placeholder=""
                                            components={{ IndicatorsContainer: () => null }}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: '19px',
                                                    outline: '0!important',
                                                    borderRadius: '0.5rem',
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                    width: '25rem',
                                                    height: '6rem',
                                                    overflow: 'hidden',
                                                    overflowY: 'scroll'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem',
                                                    margin: '0',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    alignItems: 'start',
                                                    fontSize: '1rem'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '0.5rem',
                                                    zIndex: 99990,
                                                    marginTop: 0,
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                }),
                                            }}
                                        />
                                        {formik1.touched.idAgences && formik1.errors.idAgences ? (
                                            <div className="invalid-feedback">{formik1.errors.idAgences}</div>
                                        ) : null}
                                    </div>
                                </div>

                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {showUpdateModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Type de caisse<span className='requiredField'>*</span></label>
                                        <select
                                            id="idTypeCaisseUpdate"
                                            name="idTypeCaisseUpdate"
                                            value={formik2.values.idTypeCaisseUpdate}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            style={(formik2.touched.idTypeCaisseUpdate && formik2.errors.idTypeCaisseUpdate) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {typeCaisses.map((type) => (
                                                <option key={type.idTypeCaisse} value={type.idTypeCaisse}>{type.libelle}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idTypeCaisseUpdate && formik2.errors.idTypeCaisseUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idTypeCaisseUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Wilaya<span className='requiredField'>*</span></label>
                                        <select
                                            id="idWilayaUpdate"
                                            name="idWilayaUpdate"
                                            value={formik2.values.idWilayaUpdate}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            style={(formik2.touched.idWilayaUpdate && formik2.errors.idWilayaUpdate) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {wilayas.map((wilaya) => (
                                                <option key={wilaya.idWilaya} value={wilaya.idWilaya}>{wilaya.nomLatin}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idWilayaUpdate && formik2.errors.idWilayaUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idWilayaUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label >Commune<span className='requiredField'>*</span></label>
                                        <select
                                            id="idCommuneUpdate"
                                            name="idCommuneUpdate"
                                            value={formik2.values.idCommuneUpdate}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            style={(formik2.touched.idCommuneUpdate && formik2.errors.idCommuneUpdate) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {communes.map((commune) => (
                                                <option key={commune.idCommune} value={commune.idCommune}>{commune.nomLatin}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idCommuneUpdate && formik2.errors.idCommuneUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idCommuneUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Adresse</label>
                                        <textarea type="text"
                                            id="adresseUpdate"
                                            name="adresseUpdate"
                                            placeholder=""
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={formik2.values.adresseUpdate}
                                            rows={2}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Latitude</label>
                                        <input type="text"
                                            id="latitudeUpdate"
                                            name="latitudeUpdate"
                                            placeholder=""
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={formik2.values.latitudeUpdate}
                                        />
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Longitude</label>
                                        <input type="text"
                                            id="longitudeUpdate"
                                            name="longitudeUpdate"
                                            placeholder=""
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={formik2.values.longitudeUpdate}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelleUpdate"
                                            name="libelleUpdate"
                                            style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                            value={formik2.values.libelleUpdate}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Agences<span className='requiredField'>*</span></label>
                                        <Select
                                            id="idAgencesUpdate"
                                            name="idAgencesUpdate"
                                            value={formik2.values.idAgencesUpdate}
                                            onChange={(option) => { formik2.setFieldValue('idAgencesUpdate', option); }}
                                            options={agences}
                                            getOptionLabel={(option) => option.libelle}
                                            getOptionValue={(option) => option.idAgence}
                                            onInputChange={(inputValue) => {
                                                // Update the entered text
                                                setEnteredAgenceText(inputValue);
                                            }}
                                            isClearable
                                            isMulti
                                            placeholder=""
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: '19px',
                                                    outline: '0!important',
                                                    borderRadius: '0.5rem',
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                    borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                    width: '25rem',
                                                    height: '6rem',
                                                    overflow: 'hidden',
                                                    overflowY: 'scroll'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '1rem',
                                                    margin: '0',
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    alignItems: 'start',
                                                    fontSize: '1rem'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    margin: '0',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '0.5rem',
                                                    zIndex: 99990,
                                                    marginTop: 0,
                                                    borderWidth: '1px',
                                                    fontSize: '1rem',
                                                }),
                                            }}
                                        />
                                        {formik2.touched.idAgencesUpdate && formik2.errors.idAgencesUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idAgencesUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>

                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer cette caisse ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer la caisse"
            />

        </>
    );
}

export default Caisses;
