import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToast } from '../../../components/Toast/CustomToast';
import Pagination from '../../../components/pagination/Pagination';
import { axiosInstance, createCancelTokenSource, Entreprise } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
import useDebounce from '../../../hooks/useDebounce';
const Entreprises = () => {
    const { authData } = useContext(AuthContext);
    const [entreprises, setEntreprises] = useState([]);
    const [filtredEntreprises, setFiltredEntreprises] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredEntreprises].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredEntreprises(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = entreprises.slice(startIndex, endIndex);

        setFiltredEntreprises(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Recuperer les entreprises depuis api RH
    const getEntreprisesFromRHapi = async () => {
        try {//authData.idUtilisateur
            const response = await axiosInstance.get(`${Entreprise}/GetEntreprisesFromApi/${authData.idUtilisateur}`);
            CustomToast("Entreprises enregistrés avec succès", 'success');
            getEntreprises(pageNumber, pageSize);
        } catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    };


    //Load data

    const getEntreprises = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${Entreprise}/GetEntreprisesListe?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setEntreprises(resp.data);
            setFiltredEntreprises(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getEntreprises(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getEntreprises(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getEntreprises(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);


    return (
        <div className="container inter-affichage-section">
            <div className="affectation-sec wrap-all">
                <Link to="#" className="affectation-add color-primary-2 decoration-none" onClick={() => getEntreprisesFromRHapi()}>
                    <div className="add-new-2" id="add-vehc">
                        <span class="material-symbols-outlined">cloud_download</span> Récupérer données depuis RH
                    </div>
                </Link>
                <div className="group-elements">
                    <div className="voir-elements">
                        {/* <p>Voir</p> */}
                        <select
                            name="pageSize"
                            id="pageSize"
                            onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                        >
                            <option value="10" defaultValue={true}>10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                    <div className="search-element">
                        <input
                            type="search"
                            value={search}
                            placeholder="Rechercher ..."
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                {filtredEntreprises?.length > 0 && <div className="table-wrap">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Nom commercial</th>
                                <th>Manager</th>
                                <th>Adresse</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtredEntreprises?.map((row) => (
                                <tr key={row.idEntreprise}>
                                    <td>{row.libelle}</td>
                                    <td>{row.tradeName}</td>
                                    <td>{row.manager}</td>
                                    <td>{row.adress}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}

                {filtredEntreprises?.length > 0 && <Pagination
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageSize={pageSize}
                    totalItems={totalItems}
                />}
            </div>

        </div>
    );
}

export default Entreprises;
