import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../../components/Toast/CustomToast';
import Pagination from '../../../components/pagination/Pagination';
import ConfirmationDialog from '../../../components/confirmation/ConfirmationDialog';
import { axiosInstance, createCancelTokenSource, DepenseNature } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
import useDebounce from '../../../hooks/useDebounce';

const Rubriques = () => {
    const { authData } = useContext(AuthContext);
    const [natures, setNatures] = useState([]);
    const [filtredNatures, setFiltredNatures] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [idNatureDelete, setIdNatureDelete] = useState('');


    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredNatures].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredNatures(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = natures.slice(startIndex, endIndex);

        setFiltredNatures(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };


    //Ajouter une catégorie
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
    };

    const formik1 = useFormik({
        initialValues: {
            libelle: '',
            description: '',

            statut: 'actif'
        },
        validationSchema: Yup.object({
            libelle: Yup.string()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelle: values.libelle,
                    description: values.description,
                    statut: values.statut,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(values)
                const response = await axiosInstance.post(DepenseNature, postData);
                CustomToast("La rubrique de dépense a été créée avec succès", 'success');
                getNatures(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une nature avec le même nom existe déjà.") {
                    CustomToast("Une rubrique avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier une catégorie
    const showHideUpdateModal = (val, idNature, libelle, description, statut) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        if (val) {
            formik2.setValues({
                idNature: idNature,
                libelleUpdate: libelle,
                descriptionUpdate: description,
                statutUpdate: statut || 'actif',
            });
        }
    };

    const formik2 = useFormik({
        initialValues: {
            idNature: '',
            libelleUpdate: '',
            descriptionUpdate: '',

            statutUpdate: 'actif'
        },
        validationSchema: Yup.object({
            libelleUpdate: Yup.string()
                .required('Champ obligatoire'),

            statutUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idNature: values.idNature,
                    libelle: values.libelleUpdate,

                    description: values.descriptionUpdate,
                    statut: values.statutUpdate,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(postData);
                const response = await axiosInstance.put(DepenseNature, postData);
                CustomToast("La rubrique de dépense a été modifiée avec succès", 'success');
                getNatures(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une nature avec le même nom existe déjà.") {
                    CustomToast("Une rubrique avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "La nature à mettre à jour n'existe pas.") {
                    CustomToast("La rubrique de dépenses à mettre à jour n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer une catégorie
    const handleConfirmDelete = (idNature) => {
        setIdNatureDelete(idNature);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${DepenseNature}/${idNatureDelete}`);
            setIdNatureDelete('');
            CustomToast("La rubrique de dépenses a été supprimée avec succès", 'success');
            getNatures(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 409 && error.response?.data === "Nature utilisée") {
                CustomToast("rubrique de dépense en cours d'utilisation.", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("rubrique de dépense introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getNatures = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${DepenseNature}/GetListeNaturesDepenses?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setNatures(resp.data);
            setFiltredNatures(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getNatures(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getNatures(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getCategories(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);
    return (
        <>
            <div className="container Paramétrage-stock-section">
                <div className="affectation-sec wrap-all">
                    <Link href="#" className="affectation-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>Ajouter une rubrique
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>Voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <input
                                type="search"
                                value={search}
                                placeholder="Rechercher ..."
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredNatures?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>Rubrique</th>

                                    <th>Description</th>
                                    <th>Statut</th>
                                    <th>Modifier</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredNatures?.map((row) => (
                                    <tr key={row.idNature}>
                                        <td>{row.libelle}</td>

                                        <td>{row.description}</td>
                                        <td>{
                                            row.statut === "actif" ? <>Actif</> :
                                                row.statut === "inactif" ? <>Inactif</> :
                                                    null
                                        }</td>
                                        <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idNature, row.libelle, row.description, row.statut, row.codeComptable)}>
                                            <span className="material-symbols-outlined">edit
                                            </span></td>
                                        <td className="txt-center danger" onClick={() => handleConfirmDelete(row.idNature)}>
                                            <span className="material-symbols-outlined">delete
                                            </span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {filtredNatures?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>

            {showAddModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelle"
                                            name="libelle"
                                            style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.libelle}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Description</label>
                                        <textarea type="text"
                                            id="description"
                                            name="description"
                                            placeholder=""
                                            defaultValue={formik1.values.description}
                                            onChange={formik1.handleChange}
                                            cols={30}
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {showUpdateModal && <div className="popcard-add">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelleUpdate"
                                            name="libelleUpdate"
                                            style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.libelleUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Description</label>
                                        <textarea type="text"
                                            id="descriptionUpdate"
                                            name="descriptionUpdate"
                                            placeholder=""
                                            defaultValue={formik2.values.descriptionUpdate}
                                            onChange={formik2.handleChange}
                                            cols={30}
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer cette rubrique de dépenses ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer la rubrique de dépense"
            />
        </>
    );
}

export default Rubriques;
