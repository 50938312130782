import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { formatCurrency } from '../../utils/Utils';
import { addDays, format, parseISO, isValid } from 'date-fns';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import bwipjs from 'bwip-js';
import { CustomToast } from '../../components/Toast/CustomToast';
import Pagination from '../../components/pagination/Pagination';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import { ExportToExcel } from '../../components/ExportToExcel/ExportToExcel';
import { axiosInstance, createCancelTokenSource, Transfert, Caisse } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';
import { NumericFormat } from "react-number-format";

const Transferts = () => {
    const { authData } = useContext(AuthContext);
    const [transferts, setTransferts] = useState([]);
    const [caisses, setCaisses] = useState([]);

    const [filtredTransferts, setFiltredTransferts] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogOpenValiderTransfert, setConfirmDialogOpenValiderTransfert] = useState(false);
    const [confirmDialogOpenRefuserTransfert, setConfirmDialogOpenRefuserTransfert] = useState(false);
    const [idTransfertFondCaissesDelete, setIdTransfertFondCaissesDelete] = useState('');
    const [idTransfertFondCaissesValider, setIdTransfertFondCaissesValider] = useState('');

    const [search, setSearch] = useState('');
    const [enteredCaisseText, setEnteredCaisseText] = useState('');
    const debouncedSearchText = useDebounce(search, 300);
    const debouncedCaisseText = useDebounce(enteredCaisseText, 300);

    // const [filters, setFilters] = useState({
    //     statut: '',
    //     dateDebut: format(addDays(new Date(), -7), 'yyyy-MM-dd'),
    //     dateFin: format(addDays(new Date(), 1), 'yyyy-MM-dd')
    // });

    const [filters, setFilters] = useState({
        statut: '',
        dateDebut: '',
        dateFin: ''
    });

    const statutLabels = {
        NonValidee: 'Non validée',
        Validee: 'Validée',
        Rejetee: 'Rejetée',
        Recu: 'Reçu',
        NonRecu: 'Non reçu',
    };

    const statutClasses = {
        NonValidee: 'statut statut-chef',
        Validee: 'statut statut-v-drc-gnr',
        Rejetee: 'statut statut-rf-drc-gnr',
        Recu: 'statut statut-dc-caissier',
        NonRecu: 'statut statut-rf-drc-gnr',
    };


    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredTransferts].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredTransferts(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = transferts.slice(startIndex, endIndex);

        setFiltredTransferts(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Afficher filtres

    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getTransferts(1, pageSize); // Fetch inspections with updated filters
        console.log("pageSize=",pageSize)
        showHideFiltre(false);
    };

    const showHideFiltre = (val) => {
        setShowFilters(val);
    };

    //Ajouter une demande
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        //getCaisses('');
        setCaisses([]);
    };

    const formik1 = useFormik({
        initialValues: {
            idCaisseDepart: null,
            montant: '',
            dateBesoin: '',
            modePaiement: '',
            numeroTransactionFinanciere: '',
            typeTransfert: 'habituel',
            idUtilisateurDemandeur: '',
        },
        validationSchema: Yup.object({
            // idCaisseDepart: Yup.string()
            //     .required('Champ obligatoire'),
            idCaisseDepart: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            montant: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le montant ne peut pas être négative"),
            dateBesoin: Yup.string()
                .required('Champ obligatoire'),
            modePaiement: Yup.string()
                .required('Champ obligatoire'),
            typeTransfert: Yup.string()
                .required('Champ obligatoire'),
            numeroTransactionFinanciere: Yup.string().test('numeroTransactionFinanciere-validation', 'Invalid numeroTransactionFinanciere', function (value) {
                const modePaiement = this.resolve(Yup.ref('modePaiement'));

                if (modePaiement && modePaiement === "cheque") {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idCaisseDestination: authData.idCaisse,
                    idCaisseDepart: values.idCaisseDepart.idCaisse,
                    montant: values.montant.toString().replace('.', ','),
                    dateBesoin: values.dateBesoin,
                    modePaiement: values.modePaiement,
                    numeroTransactionFinanciere: values.numeroTransactionFinanciere,
                    typeTransfert: values.typeTransfert,
                    idUtilisateurDemandeur: authData.idUtilisateur
                };
                //console.log(postData);
                const response = await axiosInstance.post(Transfert, postData);
                CustomToast("La demande a été créée avec succès", 'success');
                getTransferts(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier une demande
    const showHideUpdateModal = (val, idTransfertFondCaisses, idCaisseDepart, idCaisseDestination, montant, dateBesoin, modePaiement, numeroTransactionFinanciere, typeTransfert) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        if (val) {
            let idCaisse;
            if (typeTransfert === "habituel") {
                idCaisse = idCaisseDestination;
            } else {
                idCaisse = idCaisseDepart;
            }
            getCaisseById(idCaisse);//idCaisseDepart
            formik2.setValues({
                idTransfertFondCaisses: idTransfertFondCaisses,
                idCaisseDepartUpdate: idCaisse || null, //idCaisseDepart
                montantUpdate: montant || '',
                modePaiementUpdate: modePaiement || '',
                numeroTransactionFinanciereUpdate: numeroTransactionFinanciere || '',
                typeTransfertUpdate: typeTransfert || 'habituel',
                dateBesoinUpdate: format(parseISO(dateBesoin), 'yyyy-MM-dd') || ''
            });
        }
    };

    const formik2 = useFormik({
        initialValues: {
            idTransfertFondCaisses: null,
            idCaisseDepartUpdate: '',
            montantUpdate: '',
            dateBesoinUpdate: '',
            modePaiementUpdate: '',
            numeroTransactionFinanciereUpdate: '',
            typeTransfertUpdate: 'habituel',
        },
        validationSchema: Yup.object({
            idCaisseDepartUpdate: Yup.object()
                .nullable()
                .required('Champ obligatoire'),
            // idCaisseDepartUpdate: Yup.string()
            //     .required('Champ obligatoire'),
            montantUpdate: Yup.number()
                .required("Champ obligatoire")
                .min(0, "Le montant ne peut pas être négative"),
            dateBesoinUpdate: Yup.string()
                .required('Champ obligatoire'),
            modePaiementUpdate: Yup.string()
                .required('Champ obligatoire'),
            typeTransfertUpdate: Yup.string()
                .required('Champ obligatoire'),
            numeroTransactionFinanciereUpdate: Yup.string().test('numeroTransactionFinanciereUpdate-validation', 'Invalid numeroTransactionFinanciereUpdate', function (value) {
                const modePaiement = this.resolve(Yup.ref('modePaiementUpdate'));

                if (modePaiement && modePaiement === "cheque") {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idTransfertFondCaisses: values.idTransfertFondCaisses,
                    idCaisseDepart: values.idCaisseDepartUpdate.idCaisse,
                    idCaisseDestination: authData.idCaisse,
                    montant: values.montantUpdate.toString().replace('.', ','),
                    dateBesoin: values.dateBesoinUpdate,
                    modePaiement: values.modePaiementUpdate,
                    numeroTransactionFinanciere: values.numeroTransactionFinanciereUpdate,
                    typeTransfert: values.typeTransfertUpdate,
                    idUtilisateurCrud: authData.idUtilisateur
                };
                //console.log(postData);
                const response = await axiosInstance.put(Transfert, postData);
                CustomToast("La demande a été modifiée avec succès", 'success');
                getTransferts(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Demande n'existe pas.") {
                    CustomToast("Demande n'existe pas.", 'error');
                } else if (error.response?.status === 401 && error.response?.data === "Mise a jour impossible.") {
                    CustomToast("L'utilisateur n'est pas autorisé à modifier cette demande.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer une demande
    const handleConfirmDelete = (idTransfertFondCaisses) => {
        setIdTransfertFondCaissesDelete(idTransfertFondCaisses);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Transfert}/${idTransfertFondCaissesDelete}`);
            setIdTransfertFondCaissesDelete('');
            CustomToast("La demande a été supprimée avec succès", 'success');
            getTransferts(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Demande n'existe pas.") {
                CustomToast("Demande n'existe pas.", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "Suppression impossible.") {
                CustomToast("L'utilisateur n'est pas autorisé à supprimer cette demande.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Valider une demande
    const handleConfirmValiderTransfert = (idTransfertFondCaisses) => {
        setIdTransfertFondCaissesValider(idTransfertFondCaisses);
        setConfirmDialogOpenValiderTransfert(true);
    };

    const handleValiderTransfert = async () => {
        try {
            let statut;
            if (authData.role === "ResponsableRegional" || authData.role === "DirecteurRegional") {
                statut = "Validee";
            }else if (authData.role === "Caissier" || authData.role === "ChefStation") {
                statut = "Recu";
            } else {
                CustomToast("L'utilisateur n'est pas autorisé à valider ou refuser la demande.", 'info');
                return;
            }
            const postData = {
                idTransfertFondCaisses: idTransfertFondCaissesValider,
                dernierStatut: statut,
                idUtilisateur: authData.idUtilisateur
            };
            //console.log(postData)
            const response = await axiosInstance.put(`${Transfert}/UpdateStatutTransfert`, postData);
            setConfirmDialogOpenValiderTransfert(false);
            setIdTransfertFondCaissesValider("");
            CustomToast("La demande a été validée avec succès", 'success');
            getTransferts(1, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Demande n'existe pas.") {
                CustomToast("Demande n'existe pas.", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "Mise a jour impossible.") {
                CustomToast("L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelValiderTransfert = () => {
        setConfirmDialogOpenValiderTransfert(false);
    };

    //Refuser une demande
    const formik3 = useFormik({
        initialValues: {
            motifRefus: ''
        },
        validationSchema: Yup.object({
            motifRefus: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                let statut;
                if (authData.role === "DirecteurRegional" || authData.role === "ResponsableRegional") {
                    statut = "Rejetee";
                } else if (authData.role === "Caissier" || authData.role === "ChefStation") {
                    statut = "NonRecu";
                } else {
                    CustomToast("L'utilisateur n'est pas autorisé à valider ou refuser la demande.", 'info');
                    return;
                }
                const postData = {
                    idTransfertFondCaisses: idTransfertFondCaissesValider,
                    dernierStatut: statut,
                    motifRefus: values.motifRefus,
                    idUtilisateur: authData.idUtilisateur
                };

                const response = await axiosInstance.put(`${Transfert}/UpdateStatutTransfert`, postData);
                setIdTransfertFondCaissesValider('');
                setConfirmDialogOpenRefuserTransfert(false);
                CustomToast("La demande a été refusée avec succès", 'success');
                getTransferts(1, pageSize);
            } catch (error) {
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Demande n'existe pas.") {
                    CustomToast("Demande n'existe pas.", 'error');
                } else if (error.response?.status === 401 && error.response?.data === "Mise a jour impossible.") {
                    CustomToast("L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }

                console.log(error);
            }
        }
    });

    const handleConfirmRefuserTransfert = (idTransfertFondCaisses) => {
        setIdTransfertFondCaissesValider(idTransfertFondCaisses);
        setConfirmDialogOpenRefuserTransfert(true);
    };

    const handleRefuserTransfert = async () => {
        try {
            let statut;
            if (authData.role === "ResponsableRegional") {
                statut = "Rejetee";
            } else if (authData.role === "ResponsableRegional") {
                statut = "DirecteurRegional";
            } else if (authData.role === "Caissier") {
                statut = "NonRecu";
            } else if (authData.role === "ChefStation") {
                statut = "NonRecu";
            } else {
                CustomToast("L'utilisateur n'est pas autorisé à valider ou refuser la demande.", 'info');
                return;
            }
            const postData = {
                idTransfertFondCaisses: idTransfertFondCaissesValider,
                dernierStatut: statut,
                idUtilisateur: authData.idUtilisateur
            };
            //console.log(postData)
            const response = await axiosInstance.put(`${Transfert}/UpdateStatutTransfert`, postData);
            setIdTransfertFondCaissesValider('');
            setConfirmDialogOpenRefuserTransfert(false);
            CustomToast("La demande a été refusée avec succès", 'success');
            getTransferts(1, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Demande n'existe pas.") {
                CustomToast("Demande n'existe pas.", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "Mise a jour impossible.") {
                CustomToast("L'utilisateur n'est pas autorisé à modifier une demande validée ou refusée.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelRefuserTRansfert = () => {
        setConfirmDialogOpenRefuserTransfert(false);
    };

    //Load data
    const getTransferts = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if ((authData?.idUtilisateur == null || authData?.idUtilisateur == "") || (authData?.idAgence == null || authData?.idAgence == "") || (authData?.role == null || authData?.role == "")) {
                return;
            }

            if ((authData?.role == "Caissier" || authData?.role == "ChefStation") && (authData?.idCaisse != null && authData?.idCaisse != "")) {
                queryParams.append("idCaisse", authData?.idCaisse);
            }

            if (authData?.role === "ResponsableRegional" || authData?.role === "DirecteurRegional") {
                authData?.idAgencesRegion.forEach(id => queryParams.append('idAgencesRegion', id));
                authData?.idCaissesRegion.forEach(id => queryParams.append('idCaissesRegion', id));
            }

            queryParams.append("idUtilisateur", authData?.idUtilisateur);
            queryParams.append("idAgence", authData?.idAgence);
            queryParams.append("role", authData?.role);


            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            if (filters.dateDebut !== '') {
                queryParams.append("dateDebut", filters.dateDebut);
            }

            if (filters.dateFin !== '') {
                queryParams.append("dateFin", filters.dateFin);
            }

            const response = await axiosInstance.get(`${Transfert}/GetListeDemandesTransferts?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setTransferts(resp.data);
            setFiltredTransferts(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                //CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401 && error.response?.data === "L'utilisateur n'est pas autorisé.") {
                CustomToast("L'utilisateur n'est pas autorisé.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            //setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getCaisses = async (caisse) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 5
            });

            if (authData?.role == "Caissier" && (authData?.idCaisse != null && authData?.idCaisse != "")) {
                queryParams.append("idCaisse", authData?.idCaisse);
            }
            // else {
            //     return;
            // }

            if (caisse !== '') {
                queryParams.append("text", caisse);
            }else{
                return;
            }
            const response = await axiosInstance.get(`${Caisse}/GetListeCaissesAutocompleteTransfert?${queryParams}`);
            const resp = await response.data;
            setCaisses(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCaisseById = async (idCaisse) => {
        try {
            const response = await axiosInstance.get(`${Caisse}/${idCaisse}`);
            const resp = await response.data;
            //setAgences(resp);
            formik2.setValues((prevValues) => ({
                ...prevValues,
                idCaisseDepartUpdate: resp || null,
            }));
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getTransferts(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getTransferts(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    useEffect(() => {
        if (showAddModal || showUpdateModal) {
            getCaisses(debouncedCaisseText);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedCaisseText]);

    return (
        <>
            <div className="container appeler-section" >
                <div className="affectation-sec wrap-all">
                    {((authData?.role === "SuperAdmin") || ((authData?.role === "Caissier" || authData?.role === "ChefStation") && (authData?.idCaisse != null && authData?.idCaisse != ""))) &&
                        <Link to="#" className="affectation-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                            <div className="add-new" id="add-vehc">
                                <span className="material-symbols-outlined">add</span>
                                Créer une demande de transfert de cash
                            </div>
                        </Link>}
                    <div className={`group-elements ${(authData?.role === "SuperAdmin" || authData?.role === "Caissier" || authData?.role === "ChefStation") ? '' : 'without-btn'}`}>
                        <div className="voir-elements">
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            <input
                                type="search"
                                value={search}
                                placeholder="Rechercher ..."
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="apps__button--wrapper">
                                <div className="filter-sw apps__button" onClick={() => showHideFiltre(!showFilters)}>
                                    <span className="material-symbols-outlined">tune</span>
                                    <p>Filter</p>
                                </div>
                                {showFilters && <div className="dropdown__wrapper dropdown__wrapper--fade-in">
                                    <form onSubmit={handleSearch}>
                                        <div className="col">
                                            <div className="row">
                                                <p>Statut</p>
                                                <select
                                                    id="statut"
                                                    name="statut"
                                                    value={filters.statut || ''}
                                                    onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                                                >
                                                    <option value="" defaultValue={true}>Séléctionnez ...</option>
                                                    <option value="NonValidee"> Non validée</option>
                                                    <option value="Validee"> Validée</option>
                                                    <option value="Rejetee"> Rejetée</option>
                                                    <option value="Recu"> Reçu</option>
                                                    <option value="NonRecu"> Non reçu</option>
                                                </select>
                                            </div>
                                            <div className="row">
                                                <p style={{ opacity: 0 }}>Date</p>
                                                <input
                                                    type="date"
                                                    value={filters.dateDebut}
                                                    onChange={(e) => setFilters({ ...filters, dateDebut: e.target.value })}
                                                />
                                                <input
                                                    type="date"
                                                    value={filters.dateFin}
                                                    onChange={(e) => setFilters({ ...filters, dateFin: e.target.value })}
                                                />
                                            </div>
                                            <div className="row">
                                                <button className="submit-btn">Filtrer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                            </div>
                        </div>
                    </div>

                    {filtredTransferts?.length > 0 && (
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Type transfert</th>
                                        <th>Caisse départ</th>
                                        <th>Caissier demandeur</th>
                                        <th>Caisse destination</th>
                                        <th>Date demande</th>
                                        <th>Date de besoin</th>
                                        <th>Date validation</th>
                                        <th>Date réception</th>
                                        <th>Montant</th>
                                        <th>Statut (Action)</th>
                                        <th>Modifier</th>
                                        <th>Supprimer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredTransferts.map((row) => {
                                        const isEditable = (authData.role === "Caissier" || authData?.role === "ChefStation") && ["NonValidee"].includes(row.dernierStatut) && authData.idUtilisateur === row.idUtilisateurDemandeur;
                                        const statut = statutLabels[row.dernierStatut];
                                        const statutClass = statutClasses[row.dernierStatut];

                                        return (
                                            <tr key={row.idTransfertFondCaisses}>
                                                <td>{row.numTransfert}</td>
                                                <td>{(row.typeTransfert === "habituel") ? "Vers une autre caisse" : (row.typeTransfert === "urgence") ? "Alimentation - ma caisse" : ""}</td>
                                                <td>{row.libelleCaisseDepart}</td>
                                                <td>{row.utilisateurDemandeur}</td>
                                                <td>{row.libelleCaisseDestination}</td>
                                                <td>{format(new Date(row.createdAt), "dd/MM/yyyy HH:mm:ss")}</td>
                                                <td>{format(new Date(row.dateBesoin), "dd/MM/yyyy")}</td>
                                                <td>{row.dateApprobation ? (format(new Date(row.dateApprobation), "dd/MM/yyyy HH:mm:ss")) : ""}</td>
                                                <td>{row.dateRecuNonRecu ? (format(new Date(row.dateRecuNonRecu), "dd/MM/yyyy HH:mm:ss")) : ""}</td>
                                                <td style={{ textAlign: "right" }}>{formatCurrency(row.montant)}</td>

                                                <td>
                                                    {(authData.role === "DirecteurRegional" || authData.role === "ResponsableRegional") && ["NonValidee"].includes(row.dernierStatut) && authData.idCaissesRegion.includes(row.idCaisseDestination) ? (
                                                        <div className="button-container">
                                                            <Link to="#" onClick={() => handleConfirmValiderTransfert(row.idTransfertFondCaisses)} title='Valider transfert'>
                                                                <span className="material-symbols-outlined success">check</span>
                                                            </Link>
                                                            <Link to="#" onClick={() => handleConfirmRefuserTransfert(row.idTransfertFondCaisses)} title='Refuser transfert'>
                                                                <span className="material-symbols-outlined danger">close</span>
                                                            </Link>
                                                        </div>
                                                    ) : (authData.role === "Caissier" || authData.role==="ChefStation") && ["Validee"].includes(row.dernierStatut) && authData.idCaisse === row.idCaisseDestination ? (
                                                        <div className="button-container">
                                                            <Link to="#" onClick={() => handleConfirmValiderTransfert(row.idTransfertFondCaisses)} title='Transfert reçu'>
                                                                <span className="material-symbols-outlined">check</span>
                                                            </Link>
                                                            <Link to="#" onClick={() => handleConfirmRefuserTransfert(row.idTransfertFondCaisses)} title='Transfert non reçu'>
                                                                <span className="material-symbols-outlined danger">close</span>
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        <div className={`statut ${statutClass}`}>{statut}</div>
                                                    )}
                                                </td>

                                                <td
                                                    className="txt-center"
                                                    onClick={isEditable ? () => showHideUpdateModal(true, row.idTransfertFondCaisses, row.idCaisseDepart, row.idCaisseDestination, row.montant, row.dateBesoin, row.modePaiement, row.numeroTransactionFinanciere, row.typeTransfert) : null}
                                                    title={isEditable ? '' : 'Vous ne pouvez pas modifier cette demande'}
                                                >
                                                    <span className="material-symbols-outlined" style={{ opacity: isEditable ? '1' : '0.2', cursor: isEditable ? 'pointer' : 'default' }}>edit</span>
                                                </td>
                                                <td
                                                    className="txt-center"
                                                    onClick={isEditable ? () => handleConfirmDelete(row.idTransfertFondCaisses) : null}
                                                    title={isEditable ? '' : 'Vous ne pouvez pas supprimer cette demande'}
                                                >
                                                    <span className="material-symbols-outlined" style={{ opacity: isEditable ? '1' : '0.2', cursor: isEditable ? 'pointer' : 'default' }}>delete</span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {filtredTransferts?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>
            </div>

            {showAddModal && <div className="popcard-add">
                <div className="popcard-add_inside" style={{width:"30rem"}}>
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Type de transfert<span className='requiredField'>*</span></label>
                                        <select
                                            id="typeTransfert"
                                            name="typeTransfert"
                                            value={formik1.values.typeTransfert}
                                            onChange={formik1.handleChange}
                                            onBlur={formik1.handleBlur}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            <option value="habituel">Vers une autre caisse</option>
                                            <option value="urgence">Alimentation - ma caisse</option>
                                        </select>
                                        {formik1.touched.typeTransfert && formik1.errors.typeTransfert ? (
                                            <div className="invalid-feedback">{formik1.errors.typeTransfert}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {formik1.values.typeTransfert && <>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Caisse<span className='requiredField'>*</span></label>
                                            <Select
                                                id="idCaisseDepart"
                                                name="idCaisseDepart"
                                                value={formik1.values.idCaisseDepart}
                                                onChange={(option) => { formik1.setFieldValue('idCaisseDepart', option); setCaisses([])}}
                                                options={caisses}
                                                getOptionLabel={(option) => option.libelle}
                                                getOptionValue={(option) => option.idCaisse}
                                                onInputChange={(inputValue) => {
                                                    // Update the entered text
                                                    setEnteredCaisseText(inputValue);
                                                }}
                                                isClearable
                                                placeholder=""
                                                //components={{IndicatorsContainer:() => null}}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        minHeight: '19px',
                                                        outline: '0!important',
                                                        borderRadius: '0.5rem',
                                                        borderWidth: '1px',
                                                        fontSize: '1rem',
                                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '1rem'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '1rem',
                                                        margin: '0',
                                                    }),
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        alignItems: 'start',
                                                        fontSize: '1rem'
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        margin: '0',
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: '0.5rem',
                                                        zIndex: 99990,
                                                        marginTop: 0,
                                                        borderWidth: '1px',
                                                        fontSize: '1rem',
                                                    }),
                                                }}
                                                menuPlacement='auto'
                                            />
                                            {formik1.touched.idCaisseDepart && formik1.errors.idCaisseDepart ? (
                                                <div className="invalid-feedback">{formik1.errors.idCaisseDepart}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Date de besoin<span className='requiredField'>*</span></label>
                                            <input
                                                type="date"
                                                id="dateBesoin"
                                                name="dateBesoin"
                                                style={(formik1.touched.dateBesoin && formik1.errors.dateBesoin) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                onChange={formik1.handleChange}
                                                onBlur={formik1.handleBlur}
                                                value={formik1.values.dateBesoin}
                                            />
                                            {formik1.touched.dateBesoin && formik1.errors.dateBesoin ? (
                                                <div className="invalid-feedback">{formik1.errors.dateBesoin}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Montant<span className='requiredField'>*</span></label>
                                            {/* <input
                                                type="number"
                                                id="montant"
                                                name="montant"
                                                style={(formik1.touched.montant && formik1.errors.montant) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                step="any"
                                                onChange={formik1.handleChange}
                                                onBlur={formik1.handleBlur}
                                                value={formik1.values.montant}
                                            /> */}
                                            <NumericFormat
                                                id="montant"
                                                name="montant"
                                                style={(formik1.touched.montant && formik1.errors.montant) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                allowedDecimalSeparators={[',']}
                                                // thousandSeparator={true}
                                                // prefix={'€'}
                                                thousandSeparator=" "
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    formik1.setFieldValue('montant', value);
                                                }}
                                                onBlur={formik1.handleBlur}
                                                value={formik1.values.montant}
                                            />
                                            {formik1.touched.montant && formik1.errors.montant ? (
                                                <div className="invalid-feedback">{formik1.errors.montant}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Mode de transfert<span className='requiredField'>*</span></label>
                                            <select
                                                id="modePaiement"
                                                name="modePaiement"
                                                value={formik1.values.modePaiement}
                                                onChange={formik1.handleChange}
                                                onBlur={formik1.handleBlur}
                                            >
                                                <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                <option value="espece">Espèces</option>
                                                <option value="cheque">Chèque </option>
                                                <option value="virement">Virement </option>
                                            </select>
                                            {formik1.touched.modePaiement && formik1.errors.modePaiement ? (
                                                <div className="invalid-feedback">{formik1.errors.modePaiement}</div>
                                            ) : null}
                                        </div>
                                        {formik1.values.modePaiement === "cheque" && <div className="wrap-one-full">
                                            <label>N° chèque<span className='requiredField'>*</span></label>
                                            <input
                                                type="text"
                                                id="numeroTransactionFinanciere"
                                                name="numeroTransactionFinanciere"
                                                style={(formik1.touched.numeroTransactionFinanciere && formik1.errors.numeroTransactionFinanciere) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                onChange={formik1.handleChange}
                                                onBlur={formik1.handleBlur}
                                                value={formik1.values.numeroTransactionFinanciere}
                                            />
                                            {formik1.touched.numeroTransactionFinanciere && formik1.errors.numeroTransactionFinanciere ? (
                                                <div className="invalid-feedback">{formik1.errors.numeroTransactionFinanciere}</div>
                                            ) : null}
                                        </div>}
                                    </div>
                                </>}
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                {authData?.role !== "SuperAdmin" && <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {showUpdateModal && <div className="popcard-add">
                <div className="popcard-add_inside" style={{width:"30rem"}}>
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Type de transfert<span className='requiredField'>*</span></label>
                                        <select
                                            id="typeTransfertUpdate"
                                            name="typeTransfertUpdate"
                                            value={formik2.values.typeTransfertUpdate}
                                            onChange={formik2.handleChange}
                                            onBlur={formik2.handleBlur}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            <option value="habituel">Vers une autre caisse</option>
                                            <option value="urgence">Alimentation - ma caisse</option>
                                        </select>
                                        {formik2.touched.typeTransfertUpdate && formik2.errors.typeTransfertUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.typeTransfertUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {formik2.values.typeTransfertUpdate && <>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Caisse<span className='requiredField'>*</span></label>
                                            <Select
                                                id="idCaisseDepartUpdate"
                                                name="idCaisseDepartUpdate"
                                                value={formik2.values.idCaisseDepartUpdate}
                                                onChange={(option) => { formik2.setFieldValue('idCaisseDepartUpdate', option); setCaisses([]);}}
                                                options={caisses}
                                                getOptionLabel={(option) => option.libelle}
                                                getOptionValue={(option) => option.idCaisse}
                                                onInputChange={(inputValue) => {
                                                    // Update the entered text
                                                    setEnteredCaisseText(inputValue);
                                                }}
                                                isClearable
                                                placeholder=""
                                                //components={{IndicatorsContainer:() => null}}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        minHeight: '19px',
                                                        outline: '0!important',
                                                        borderRadius: '0.5rem',
                                                        borderWidth: '1px',
                                                        fontSize: '1rem',
                                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '1rem'
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '1rem',
                                                        margin: '0',
                                                    }),
                                                    valueContainer: (provided, state) => ({
                                                        ...provided,
                                                        alignItems: 'start',
                                                        fontSize: '1rem'
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        margin: '0',
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        borderRadius: '0.5rem',
                                                        zIndex: 99990,
                                                        marginTop: 0,
                                                        borderWidth: '1px',
                                                        fontSize: '1rem',
                                                    }),
                                                }}
                                                menuPlacement='auto'
                                            />
                                            {formik2.touched.idCaisseDepartUpdate && formik2.errors.idCaisseDepartUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.idCaisseDepartUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Date de besoin<span className='requiredField'>*</span></label>
                                            <input
                                                type="date"
                                                id="dateBesoinUpdate"
                                                name="dateBesoinUpdate"
                                                style={(formik2.touched.dateBesoinUpdate && formik2.errors.dateBesoinUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.dateBesoinUpdate}
                                            />
                                            {formik2.touched.dateBesoinUpdate && formik2.errors.dateBesoinUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.dateBesoinUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Montant<span className='requiredField'>*</span></label>
                                            {/* <input
                                                type="number"
                                                id="montantUpdate"
                                                name="montantUpdate"
                                                style={(formik2.touched.montantUpdate && formik2.errors.montantUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                step="any"
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.montantUpdate}
                                            /> */}
                                            <NumericFormat
                                                id="montantUpdate"
                                                name="montantUpdate"
                                                style={(formik2.touched.montantUpdate && formik2.errors.montantUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                allowedDecimalSeparators={[',']}
                                                // thousandSeparator={true}
                                                // prefix={'€'}
                                                thousandSeparator=" "
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    formik2.setFieldValue('montantUpdate', value);
                                                }}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.montantUpdate}
                                            />
                                            {formik2.touched.montantUpdate && formik2.errors.montantUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.montantUpdate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label>Mode de transfert<span className='requiredField'>*</span></label>
                                            <select
                                                id="modePaiementUpdate"
                                                name="modePaiementUpdate"
                                                value={formik2.values.modePaiementUpdate}
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                            >
                                                <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                                <option value="espece">Espèces</option>
                                                <option value="cheque">Chèque </option>
                                                <option value="virement">Virement </option>
                                            </select>
                                            {formik2.touched.modePaiementUpdate && formik2.errors.modePaiementUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.modePaiementUpdate}</div>
                                            ) : null}
                                        </div>
                                        {formik2.values.modePaiementUpdate === "cheque" && <div className="wrap-one-full">
                                            <label>N° chèque<span className='requiredField'>*</span></label>
                                            <input
                                                type="text"
                                                id="numeroTransactionFinanciereUpdate"
                                                name="numeroTransactionFinanciereUpdate"
                                                style={(formik2.touched.numeroTransactionFinanciereUpdate && formik2.errors.numeroTransactionFinanciereUpdate) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                onChange={formik2.handleChange}
                                                onBlur={formik2.handleBlur}
                                                value={formik2.values.numeroTransactionFinanciereUpdate}
                                            />
                                            {formik2.touched.numeroTransactionFinanciereUpdate && formik2.errors.numeroTransactionFinanciereUpdate ? (
                                                <div className="invalid-feedback">{formik2.errors.numeroTransactionFinanciereUpdate}</div>
                                            ) : null}
                                        </div>}
                                    </div>
                                </>}
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                {authData?.role !== "SuperAdmin" && <div className="buttons">
                                    <input type="submit" value="Enregistrer" className="submit-btn" />
                                </div>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer cette demande ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer la demande"
            />

            <ConfirmationDialog
                open={confirmDialogOpenValiderTransfert}
                onClose={handleCancelValiderTransfert}
                onConfirm={handleValiderTransfert}
                titleMessage="Valider la demande"
                bodyMessage="Voulez vous valider cette demande !"
                btnCancelText="Annuler"
                btnConfirmText="Valider"
            />

            {/* <ConfirmationDialog
                open={confirmDialogOpenRefuserTransfert}
                onClose={handleCancelRefuserTRansfert}
                onConfirm={handleRefuserTransfert}
                titleMessage="Rejeter la demande"
                bodyMessage="Voulez vous Rejeter cette demande !"
                btnCancelText="Annuler"
                btnConfirmText="Rejeter"
            /> */}

            {confirmDialogOpenRefuserTransfert && <div className="popcard-add">
                <div className="popcard-add_inside annuler-banner">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => handleCancelRefuserTRansfert()}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Voulez vous Rejeter cette demande !</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik3.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Motif </label>
                                        <textarea
                                            type="text"
                                            rows={2}
                                            cols={50}
                                            id="motifRefus"
                                            name="motifRefus"
                                            placeholder=""
                                            defaultValue={formik3.values.motifRefus}
                                            onChange={formik3.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Annuler" className="submit-btn" onClick={() => handleCancelRefuserTRansfert()} />
                                    <input type="submit" value="Rejeter" className="submit-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default Transferts;
