import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToast } from '../../../components/Toast/CustomToast';
import Pagination from '../../../components/pagination/Pagination';
import { axiosInstance, createCancelTokenSource, Agence } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
import useDebounce from '../../../hooks/useDebounce';

const Agences = () => {
    const { authData } = useContext(AuthContext);
    const [agences, setAgences] = useState([]);
    const [filtredAgences, setFiltredAgences] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredAgences].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredAgences(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = agences.slice(startIndex, endIndex);

        setFiltredAgences(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Recuperer les agence depuis api RH
    const getAgencesFromRHapi = async () => {
        try {
            const response = await axiosInstance.get(`${Agence}/GetAgencesFromApi/${authData.idUtilisateur}`);
            CustomToast("Agences enregistrées avec succès", 'success');
            getAgences(pageNumber, pageSize);
        } catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    };


    //Load data

    const getAgences = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            //setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${Agence}/GetAgencesListe?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setAgences(resp.data);
            setFiltredAgences(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getAgences(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getAgences(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getAgences(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);
    
    return (
        <div className="container liste-colis-section">
            <div className="affectation-sec wrap-all">
                <Link to="#" className="affectation-add color-primary-2 decoration-none" onClick={() => getAgencesFromRHapi()}>
                    <div className="add-new-2" id="add-vehc">
                        <span className="material-symbols-outlined">cloud_download</span> Récupérer données depuis RH
                    </div>
                </Link>
                <div className="group-elements">
                    <div className="voir-elements">
                        {/* <p>Voir</p> */}
                        <select
                            name="pageSize"
                            id="pageSize"
                            onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                        >
                            <option value="10" defaultValue={true}>10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                    <div className="search-element">
                        <input
                            type="search"
                            value={search}
                            placeholder="Rechercher ..."
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                {filtredAgences?.length > 0 && <div className="table-wrap">
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Code</th>
                                <th>Type</th>
                                <th>Entreprise</th>
                                <th>Adresse</th>
                                <th>Wilaya</th>
                                <th>Commune</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtredAgences?.map((row) => (
                                <tr key={row.idAgence}>
                                    <td>{row.libelle}</td>
                                    <td>{row.code}</td>
                                    <td>{row.type}</td>
                                    <td>{row.entreprise}</td>
                                    <td>{row.adresse}</td>
                                    <td>{row.libelleWilaya}</td>
                                    <td>{row.libelleCommune}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}

                {filtredAgences?.length > 0 && <Pagination
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageSize={pageSize}
                    totalItems={totalItems}
                />}
            </div>

        </div>

    );
}

export default Agences;
