import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ pageNumber, totalPages, handlePageChange, pageSize, totalItems }) => {
    const renderPageLink = (page) => (
        <Link
            key={page}
            to="#"
            className={pageNumber === page ? 'pgn active' : 'pgn'}
            onClick={() => handlePageChange(page)}
        >
            {page}
        </Link>
    );

    const renderPreviousLink = () => (
        <Link
            to="#"
            onClick={() => handlePageChange(pageNumber - 1)}
            className={pageNumber === 1 ? 'pgn disabled' : 'pgn'}
            disabled={pageNumber === 1}
        >
            &laquo;
        </Link>
    );

    const renderNextLink = () => (
        <Link
            to="#"
            onClick={() => handlePageChange(pageNumber + 1)}
            className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
            disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
        >
            &raquo;
        </Link>
    );

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPages = Math.min(5, totalPages);

        let startPage = Math.max(1, pageNumber - 2);
        let endPage = Math.min(startPage + 4, totalPages);

        while (startPage <= endPage) {
            pageNumbers.push(renderPageLink(startPage));
            startPage++;
        }

        return pageNumbers;
    };

    return (
        <div className="pagination">
            {renderPreviousLink()}
            {renderPageNumbers()}
            {renderNextLink()}
            {/* <span> Affichage de {(pageNumber === 1) ? 1 : ((pageNumber - 1) * pageSize + 1)} à {pageNumber * pageSize} sur {totalItems} entrées</span> */}
            <span>
                Affichage de {pageNumber === 1 ? 1 : (pageNumber - 1) * pageSize + 1} à {Math.min(pageNumber * pageSize, totalItems)} sur {totalItems} entrées
            </span>
        </div>
    );
};

export default Pagination;
